// Home.js
import logo from "./logo.svg";
import { FaPlus } from "react-icons/fa";
import {
  motion,
  useTransform,
  useScroll,
  AnimatePresence,
} from "framer-motion";
import "./App.css";
import "./HomeStyles.css";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import cw1 from "./asset/coldWallet1.png";
import cw2 from "./asset/coldWalletBg.png";
import h1 from "./asset/avatar1.png";
import h2 from "./asset/avatar2.png";
import h3 from "./asset/avatar3.png";
import c1 from "./asset/card1.png";
import c2 from "./asset/card2.png";
import c3 from "./asset/card3.png";
import c4 from "./asset/card4.png";
import sg from "./asset/singapore.png";
import mask from "./asset/bgMask.svg";
import screen from "./asset/screen.svg";
import bluetooth from "./asset/bluetooth.svg";
import chip from "./asset/chip.svg";
import tag from "./asset/tag.svg";
import plantform from "./asset/plantform.svg";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Footer from "./Footer";
// 引入 BrowserRouter、Routes 和 Route
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ios from "./asset/ios.png";
import hoverIos from "./asset/hoverios.png";
import apk from "./asset/apk.png";
import hoverApk from "./asset/hoverapk.png";
import googlePlay from "./asset/googleplay.png";
import hoverGooglePlay from "./asset/hovergoogleplay.png";

//rgba(29, 29, 29, 0.5);
function Home() {
  const { t } = useTranslation(); // 获取翻译函数
  const [faqStates, setFaqStates] = useState({
    faq1: false,
    faq2: false,
    faq3: false,
    faq4: false,
    faq5: false,
  });

  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5, ease: "easeInOut" } },
  };

  // 创建引用
  const [isOpen, setIsOpen] = useState(false); // State to track toggle

  const toggleFAQ = (faqKey) => {
    setFaqStates((prevStates) => ({
      ...prevStates,
      [faqKey]: !prevStates[faqKey],
    }));
  };

  const productRef = useRef(null);
  const featureRef = useRef(null);
  const aboutUsRef = useRef(null);
  const faqRef = useRef(null);

  const location = useLocation();
  const { hash } = location;

  // 滚动到指定的位置
  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // 使用 useLayoutEffect 确保在页面渲染完成后滚动
  useLayoutEffect(() => {
    if (hash) {
      // 根据 hash 值滚动到对应位置
      switch (hash) {
        case "#product":
          scrollToRef(productRef);
          break;
        case "#feature":
          scrollToRef(featureRef);
          break;
        case "#aboutUs":
          scrollToRef(aboutUsRef);
          break;
        case "#faq":
          scrollToRef(faqRef);
          break;
        default:
          break;
      }
    }
  }, [hash]); // 监听 hash 的变化
  const { scrollY } = useScroll();
  const [position, setPosition] = useState("relative");
  const [xPosition, setXPosition] = useState(0);

  const [currentCard, setCurrentCard] = useState(1);
  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = scrollY.get(); // 获取当前的滚动位置
      console.log("Current Scroll Y:", currentScrollY);

      // 调整卡片显示
      if (currentScrollY >= 1900 && currentScrollY < 2000) {
        setCurrentCard(1);
      } else if (currentScrollY >= 2000 && currentScrollY < 2100) {
        setCurrentCard(2);
      } else if (currentScrollY >= 2100 && currentScrollY <= 2200) {
        setCurrentCard(3);
      }

      // 设置元素位置
      if (currentScrollY > 1900 && currentScrollY < 2200) {
        setPosition("sticky");
        setXPosition(currentScrollY - 1900); // 根据滚动位置调整x轴位置
        ref.current.style.position = "sticky";
        ref.current.style.top = "64px"; // 这将元素固定在顶部
      } else {
        setPosition("relative");
        setXPosition(0); // 重置x轴位置
        ref.current.style.position = "relative";
        ref.current.style.top = "auto";
      }
    };

    const unsubscribe = scrollY.onChange(handleScroll);

    return () => {
      unsubscribe();
    };
  }, [scrollY]);

  const scale = useTransform(scrollY, [0, 400], [1, 1.15]);

  // 由于原本的 CSS 使用 translateX(-50%) 来居中，我们需要在这里也加上相同的设置
  const x = useTransform(scrollY, () => "-50%");

  const numRows = 15; // 定义网格的行数
  const numCols = 100; // 定义网格的列数
  let cells = [];

  for (let row = 0; row < numRows; row++) {
    for (let col = 0; col < numCols; col++) {
      cells.push(
        <motion.div
          whileHover={{ backgroundColor: "#333" }} //
          key={`${row}-${col}`}
          className="grid-cell bg-[#1d1d1d] z-[0]"
        ></motion.div>
      );
    }
  }

  return (
    <div>
      <Header
        scrollToRef={scrollToRef}
        productRef={productRef}
        featureRef={featureRef}
        aboutUsRef={aboutUsRef}
        faqRef={faqRef}
      />
      <div ref={ref} className="z-[0] mt-[64px] ">
        <div className="w-full bg-[#222] text-center overflow-hidden relative h-[1000px]">
          <motion.img
            src={cw1}
            alt="LIKKIM Cold Wallet"
            className="w-[800px] sm:w-[80%] top-[410px] xxs:top-[710px]  xxxs:top-[700px]   sm:top-[640px] left-1/2 absolute z-[1]"
            style={{ scale, x }} // 应用缩放和水平位移
          />
          <motion.div
            className="w-[20px] h-[20px] bg-[#F0D665] rounded-full shadow-lg filter blur-[15px] z-[0] absolute"
            initial={{ x: 80, y: -50 }}
            animate={{ y: [null, window.innerHeight + 50] }}
            transition={{ duration: 6, repeat: Infinity, repeatType: "loop" }}
          />
          <motion.div
            className="w-[20px] h-[20px] bg-[#F0D665] rounded-full shadow-lg filter blur-[15px] z-[0] absolute"
            initial={{ x: 240, y: 160 }}
            animate={{
              x: [null, window.innerWidth - 160],
            }}
            transition={{
              duration: 6,
              repeat: Infinity,
              repeatType: "loop",
              delay: 2,
            }}
          />
          <div className="grid-container z-[0] absolute ">{cells}</div>
          <div className="pointer-events-none relative">
            <div className="gradient-title sm:text-[10vw] sm:leading-normal text-[5vw] font-[500] leading-none pt-[60px] 2xl:text-[68px] mb-[20px]">
              {t("brandName")} {/* 使用 t 函数显示品牌名称 */}
              <br />
              {t("tagline")} {/* 使用 t 函数显示副标题 */}
            </div>
            <div className="w-full sm:px-[30px] px-[80px] text-center flex justify-center flex-col items-center">
              <p className="text-[18px] font-bold text-[#A5A5A5] font-[400] max-w-[1024px] mb-[50px]">
                {t("description")} {/* 使用 t 函数显示描述文本 */}
              </p>
              <div className="relative flex flex-col items-center justify-center">
                <button
                  onClick={() => scrollToRef(productRef)}
                  className="shadow-[0_0_0_5px_#ffffff50] w-[250px] h-[60px] bg-white mb-[200px] rounded-full flex items-center justify-center font-bold text-[22px] pointer-events-auto relative "
                >
                  {t("learnMore")}
                </button>

                <motion.div
                  className="w-[30px] h-[30px] bg-[#fff] rounded-full shadow-lg filter blur-[15px] z-[0] absolute"
                  initial={{ x: 80, y: -50 }}
                  animate={{
                    rotate: 360,
                    x: [95, 115, 95, -95, -115, -95, 95],
                    y: [-115, -100, -85, -85, -100, -115, -115],
                  }}
                  transition={{
                    duration: 6,
                    repeat: Infinity,
                    repeatType: "loop",
                    ease: "linear",
                  }}
                  style={{
                    top: "50%",
                    left: "50%",
                    translateX: "-50%",
                    translateY: "-50%",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <div
                style={{
                  background: "white",
                  borderRadius: "50%",
                  padding: "80px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  filter: "blur(8px)",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full z-[2] ">
        <div
          ref={productRef}
          id="Product"
          className="w-full h-[950px] sm:h-auto  pt-[88px] sm:px-[20px] px-[32px] bg-gradient-to-t from-[#1D1D1D] to-[#202020] text-center z-[2] relative"
        >
          <p className="gradient-title sm:text-[10vw]  text-white text-[5vw] pb-[40px]">
            {t("Lock Your Crypto, Securely")}
          </p>

          <div className="flex justify-center items-center mb-20">
            <div className="flex flex-wrap justify-center items-center space-x-4 sm:space-x-8 md:space-x-20">
              {/* iOS Section */}
              <div className="group cursor-pointer flex flex-col items-center text-center m-4">
                <a
                  href="https://testflight.apple.com/join/CF4ABvtu"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-center"
                >
                  <img
                    src={ios}
                    alt={t("App Store Icon")}
                    className="block group-hover:hidden w-16 h-16 object-contain mx-auto"
                  />
                  <img
                    src={hoverIos}
                    alt={t("Hover App Store Icon")}
                    className="hidden group-hover:block w-16 h-16 object-contain mx-auto"
                  />
                  <p className="mt-2 text-neutral-500 text-sm font-bold">
                    {t("Download on the")}
                    <br />
                    <span className="text-white">{t("App Store")}</span>
                  </p>
                </a>
              </div>

              {/* Google Play Section */}
              <div className="group cursor-pointer flex flex-col items-center text-center m-4">
                <a
                  href="https://play.google.com/store/apps/details?id=com.app.likkim"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-center"
                >
                  <img
                    src={googlePlay}
                    alt={t("Google Play Icon")}
                    className="block group-hover:hidden w-16 h-16 object-contain mx-auto"
                  />
                  <img
                    src={hoverGooglePlay}
                    alt={t("Hover Google Play Icon")}
                    className="hidden group-hover:block w-16 h-16 object-contain mx-auto"
                  />
                  <p className="mt-2 text-neutral-500 text-sm font-bold">
                    {t("Get it on")}
                    <br />
                    <span className="text-white">{t("Google Play")}</span>
                  </p>
                </a>
              </div>

              {/* APK Section */}
              <div className="group cursor-pointer flex flex-col items-center text-center m-4">
                <a href="/app/LIKKIM.apk" download className="text-center">
                  <img
                    src={apk}
                    alt="APK Icon"
                    className="block group-hover:hidden w-16 h-16 object-contain mx-auto"
                  />
                  <img
                    src={hoverApk}
                    alt="Hover APK Icon"
                    className="hidden group-hover:block w-16 h-16 object-contain mx-auto"
                  />
                  <p className="mt-2 text-neutral-500 text-sm font-bold">
                    {t("Download the")}
                    <br />
                    <span className="text-white">{t("APK")}</span>
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-center">
            <div
              className="h-[650px]  rounded-[48px] max-w-[1280px] w-full"
              style={{
                backgroundImage: `url(${cw2})`,
                backgroundSize: "cover", // 确保背景图像覆盖整个元素
                backgroundPosition: "48% 78%",
                backgroundRepeat: "no-repeat", // 背景不重复
              }}
            ></div>
          </div>
        </div>

        <div
          style={{
            position: position,
            top: position === "sticky" ? "0px" : "auto",
          }}
          className="w-full flex justify-center items-center h-[1000px] bg-[#1D1D1D] relative overflow-hidden"
        >
          <motion.div
            className="w-auto object-cover custom-shadow absolute top-1/2 transform -translate-y-1/2 z-[2]"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {currentCard === 1 && (
              <motion.img
                src={c1}
                alt="Card 1"
                className="h-[300px]"
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
              />
            )}
            {currentCard === 2 && (
              <motion.img
                src={c2}
                alt="Card 2"
                className="h-[300px]"
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
              />
            )}
            {currentCard === 3 && (
              <motion.img
                src={c3}
                alt="Card 3"
                className="h-[300px]"
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
              />
            )}
            {currentCard === 4 && (
              <motion.img
                src={c4}
                alt="Card 4"
                className="h-[300px]"
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
              />
            )}
          </motion.div>
          <motion.div
            className="flex gap-[60px] absolute  transform "
            style={{ translateX: xPosition + "px" }} // 应用计算出的x轴位置
          >
            <p className="text-white font-bold sm:text-[10vw] text-[5vw]">
              {t("Security Features")}
            </p>
            <p className="text-white font-bold sm:text-[10vw] text-[5vw]">
              {t("Cross-Platform")}
            </p>
            <p className="text-white font-bold sm:text-[10vw] text-[5vw]">
              {t("Offline storage")}
            </p>
            <p className="text-white font-bold sm:text-[10vw] text-[5vw]">
              {t("Against hacking")}
            </p>
            <p className="text-white font-bold sm:text-[10vw] text-[5vw]">
              {t("Unbreachable Security")}
            </p>
          </motion.div>
        </div>
        <div
          ref={featureRef}
          id="Feature"
          className="w-full h-[1100px] sm:h-auto bg-[#1D1D1D] z-[2] relative"
        >
          <p className="sm:text-[10vw] gradient-title text-white text-[4vw] pb-[40px] text-center font-[500]">
            {t("Feature")} {/* 使用 t 函数 */}
          </p>
          <div className="sm:h-auto w-full flex justify-center flex-col items-center">
            <div className="flex sm:flex-col gap-[2vw] max-w-[1280px] justify-center pb-[40px]">
              {/* Touchscreen Interface */}
              <div className="gradient-1 sm:w-[90vw] sm:mb-[20px] w-[68vw] h-[400px] rounded-3xl relative overflow-hidden">
                <motion.img
                  src={screen}
                  alt="screenIcon"
                  className="h-auto w-[180px] z-[2] absolute ml-20 mt-10"
                  initial={{ rotate: 135, scale: 1.6 }}
                  whileHover={{ rotate: 90, scale: 1 }}
                  style={{
                    originX: 0.5,
                    originY: 0.4,
                    filter: "drop-shadow(0 4px 20px rgba(255, 99, 71, 0.5))",
                  }}
                />
                <div className="bg-GGrayL w-full h-[120px] rounded-bl-[22px] rounded-br-[22px] p-[22px] flex flex-col justify-between absolute bottom-[0px]">
                  <p className="text-white font-bold text-[20px]">
                    {t("Touchscreen Interface")}
                  </p>
                  <p className="text-[#aaa] text-[16px]">
                    {t(
                      "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips."
                    )}
                  </p>
                </div>
              </div>

              {/* Bluetooth Connectivity */}
              <div className="gradient-2 w-[20vw] sm:w-[90vw] h-[400px] rounded-3xl relative overflow-hidden">
                <motion.img
                  src={bluetooth}
                  alt="screenIcon"
                  className="h-auto w-[180px] z-[2] absolute ml-10 mt-10"
                  initial={{ rotate: 135, scale: 2 }}
                  whileHover={{ rotate: 180, scale: 1 }}
                  style={{
                    originX: 0.5,
                    originY: 0.5,
                    filter: "drop-shadow(0 4px 20px rgba(128, 0, 128, 0.5))",
                  }}
                />
                <div className="bg-GGrayL w-full h-[120px] rounded-bl-[22px] rounded-br-[22px] p-[22px] flex flex-col justify-between absolute bottom-[0px]">
                  <p className="text-white font-bold text-[20px]">
                    {t("Bluetooth Connectivity")}
                  </p>
                  <p className="text-[#aaa] text-[16px]">
                    {t(
                      "Wireless syncing with your devices for real-time updates."
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex sm:flex-col gap-[2vw] max-w-[1280px] justify-center">
              {/* Airtag Tracking */}
              <div className="gradient-3 sm:w-[90vw] sm:mb-[20px] w-[20vw] h-[400px] rounded-3xl relative overflow-hidden">
                <motion.img
                  src={tag}
                  alt="screenIcon"
                  className="h-auto w-[180px] z-[2] absolute ml-20 mt-10"
                  initial={{ rotate: 135, scale: 2 }}
                  whileHover={{ rotate: 180, scale: 1 }}
                  style={{
                    originX: 0.5,
                    originY: 0.5,
                    filter: "drop-shadow(0 4px 20px rgba(255, 192, 203, 0.5))",
                  }}
                />
                <div className="bg-GGrayL w-full h-[120px] rounded-bl-[22px] rounded-br-[22px] p-[22px] flex flex-col justify-between absolute bottom-[0px]">
                  <p className="text-white font-bold text-[20px]">
                    {t("Airtag Tracking")}
                  </p>
                  <p className="text-[#aaa] text-[16px]">
                    {t("Track your wallet easily with built-in Airtag.")}
                  </p>
                </div>
              </div>

              {/* Secure Chip Technology */}
              <div className="gradient-4 sm:w-[90vw] sm:mb-[20px] w-[46vw] h-[400px] rounded-3xl relative overflow-hidden">
                <motion.img
                  src={chip}
                  alt="screenIcon"
                  className="h-auto w-[100px] z-[2] absolute ml-20 mt-10"
                  initial={{ rotate: 135, scale: 2 }}
                  whileHover={{ rotate: 90, scale: 1.5 }}
                  style={{
                    originX: 0.5,
                    originY: 0.5,
                    filter: "drop-shadow(0 4px 20px rgba(144, 238, 144, 0.5))",
                  }}
                />
                <div className="bg-GGrayL w-full h-[120px] rounded-bl-[22px] rounded-br-[22px] p-[22px] flex flex-col justify-between absolute bottom-[0px]">
                  <p className="text-white font-bold text-[20px]">
                    {t("Secure Chip Technology")}
                  </p>
                  <p className="text-[#aaa] text-[16px]">
                    {t(
                      "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats."
                    )}
                  </p>
                </div>
              </div>

              {/* Cross-Platform Support */}
              <div className="sm:w-[90vw] sm:mb-[20px] gradient-5 w-[20vw] h-[400px] rounded-3xl relative overflow-hidden">
                <motion.img
                  src={plantform}
                  alt="screenIcon"
                  className="h-auto w-[180px] z-[2] absolute ml-20 mt-10"
                  initial={{ rotate: 15, scale: 1.5 }}
                  whileHover={{ rotate: 0, scale: 1 }}
                  style={{
                    originX: 0.5,
                    originY: 0.5,
                    filter: "drop-shadow(0 4px 20px rgba(0, 0, 255, 0.5))",
                  }}
                />
                <div className="bg-GGrayL w-full h-[120px] rounded-bl-[22px] rounded-br-[22px] p-[22px] flex flex-col justify-between absolute bottom-[0px]">
                  <p className="text-white font-bold text-[20px]">
                    {t("Cross-Platform Support")}
                  </p>
                  <p className="text-[#aaa] text-[16px]">
                    {t("Works with various platforms for easy accessibility.")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="Testimonials"
          className="w-full h-[800px] sm:h-auto bg-[#1D1D1D] z-[2] relative"
        >
          <p className="gradient-title text-white sm:text-[10vw] text-[4vw] pb-[40px] text-center font-[500]">
            {t("Testimonials")}
          </p>
          <div className="w-full flex justify-center">
            <div className="sm:flex-col flex gap-[2vw] max-w-[1280px] justify-center">
              {/* Testimonial 1 */}
              <div className="bg-GGray sm:w-[90vw] w-[28vw] h-[400px] md:h-[500px] rounded-3xl p-[40px] flex flex-col justify-between">
                <p className="text-[#AFAFAF] text-[18px]">
                  "{t("Testimonial1")}"
                </p>
                <div className="flex items-center">
                  <img
                    src={h1}
                    alt="avatar1"
                    className="w-[48px] h-[48px] bg-[#666] rounded-full"
                  />
                  <div>
                    <p className="text-white pl-[20px]">{t("John Doe")}</p>{" "}
                    <p className="text-[#909090] pl-[20px]">
                      {t("Cryptocurrency Enthusiast")}
                    </p>
                  </div>
                </div>
              </div>

              {/* Testimonial 2 */}
              <div className="bg-GGray w-[27vw] sm:w-[90vw] h-[400px] md:h-[500px] rounded-3xl p-[40px] flex flex-col justify-between">
                <p className="text-[#AFAFAF] text-[18px]">
                  "{t("Testimonial2")}"
                </p>
                <div className="flex items-center">
                  <img
                    src={h2}
                    alt="avatar2"
                    className="w-[48px] h-[48px] bg-[#666] rounded-full"
                  />
                  <div>
                    <p className="text-white pl-[20px]">{t("Jane Smith")}</p>{" "}
                    <p className="text-[#909090] pl-[20px]">
                      {t("Crypto Security Advocate")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-GGray w-[28vw] sm:w-[90vw] h-[400px] md:h-[500px] rounded-3xl p-[40px] flex flex-col justify-between">
                <p className="text-[#AFAFAF] text-[18px]">
                  "{t("Testimonial3")}"
                </p>
                <div className="flex items-center">
                  <img
                    src={h3}
                    alt="avatar3"
                    className="w-[48px] h-[48px] bg-[#666] rounded-full"
                  />
                  <div>
                    <p className="text-white pl-[20px]">{t("Alex Johnson")}</p>
                    <p className="text-[#909090] pl-[20px]">
                      {t("Investment Analyst")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          ref={aboutUsRef}
          id="About us"
          className="w-full h-[800px] sm:h-auto md:h-auto bg-[#1D1D1D] z-[2] relative"
        >
          <p className="gradient-title sm:text-[10vw] text-white text-[4vw] pb-[40px] text-center font-[500]">
            {t("About us")} {/* 使用 t 函数进行翻译 */}
          </p>

          <div className="w-full flex justify-center flex-col items-center">
            <div className="sm:flex-col md:flex-col flex gap-[2vw] max-w-[1280px] justify-center pb-[40px]">
              <div className="bg-GGray sm:w-[90vw] md:w-[90vw] sm:mb-[20px] w-[68vw] sm:h-auto md:h-auto h-[300px] rounded-3xl p-[40px]">
                <p className="text-[#eee] text-[18px]">
                  {t("AboutUsDescription1")}
                  <br />
                  <br />
                  {t("AboutUsDescription2")}
                  <br />
                  <br />
                  {t("AboutUsDescription3")}
                </p>
              </div>
              <div className="bg-GGray sm:w-[90vw] md:w-[90vw] sm:mb-[20px] w-[20vw] h-[300px] rounded-3xl p-[40px] flex justify-center items-center">
                <img
                  src={sg}
                  alt="Switzerland"
                  className="w-[160px] h-[160px] bg-white rounded-full"
                />
              </div>
            </div>
            <div className="sm:flex-col md:flex-col flex gap-[2vw] max-w-[1280px] justify-center">
              <div className="bg-GGray sm:w-[90vw] md:w-[90vw] sm:mb-[20px] w-[20vw] h-[300px] rounded-3xl p-[40px] flex text-center flex-col justify-center">
                <p className="text-[#eee] text-[52px] font-bold">100+</p>
                <p className="text-[#eee] text-[22px]">
                  {t("BlockchainNetworksSupported")}
                </p>
              </div>
              <div className="bg-GGray w-[46vw] sm:w-[90vw] md:w-[90vw] sm:mb-[20px] h-[300px] rounded-3xl p-[40px] flex text-center flex-col">
                <p className="text-[#eee] text-[22px]">
                  {t("CryptocurrenciesSupported")}
                </p>
                <div className="h-full flex items-center justify-center overflow-hidden relative">
                  <div className="carousel-outer-container">
                    <div className="carousel-container">
                      {Array.from({ length: 40 }, (_, i) => (
                        <div key={i} className="carousel-item">
                          <img
                            src={`./Icon/Icon${(i % 20) + 1}.png`}
                            alt={`Icon ${(i % 20) + 1}`}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-GGray w-[20vw] sm:w-[90vw] md:w-[90vw] sm:mb-[20px] h-[300px] rounded-3xl p-[40px] flex text-center flex-col justify-center">
                <p className="text-[#eee] text-[52px] font-bold">
                  20+ {t("Years")}
                </p>
                <p className="text-[#eee] text-[22px]">
                  {t("ExpertiseInSecurity")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          ref={faqRef}
          id="FAQ"
          className="w-full h-auto pb-[60px] bg-[#1D1D1D] z-[2] relative"
        >
          <p className="pt-[60px] gradient-title sm:text-[10vw] text-white text-[4vw] pb-[40px] text-center font-[500]">
            {t("FrequentlyAskedQuestions")}
          </p>
          <div className="w-full flex items-center justify-center flex-col">
            <div className="flex flex-col w-[60vw] md:w-[90vw] sm:w-[90vw] max-w-[1280px] mb-[16px] h-auto bg-[#111] rounded-xl text-white px-[30px] py-[20px] flex items-center">
              <div
                className="flex w-full items-center cursor-pointer"
                onClick={() => toggleFAQ("faq1")}
              >
                <FaPlus
                  size="12px"
                  color="white"
                  className={`mr-[10px] ${
                    faqStates.faq1 ? "rotated-icon" : ""
                  }`}
                />
                {t("WhatIsColdWallet")}
              </div>

              <AnimatePresence>
                {faqStates.faq1 && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="FAQAnswer"
                  >
                    {t("ColdWalletDescription")}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <div className="flex flex-col w-[60vw] md:w-[90vw] sm:w-[90vw] max-w-[1280px] mb-[16px] h-auto bg-[#111] rounded-xl text-white px-[30px] py-[20px] flex items-center">
              <div
                className="flex w-full items-center cursor-pointer"
                onClick={() => toggleFAQ("faq2")}
              >
                <FaPlus
                  size="12px"
                  color="white"
                  className={`mr-[10px] ${
                    faqStates.faq2 ? "rotated-icon" : ""
                  }`}
                />
                {t("HowToSetUpColdWallet")}
              </div>

              <AnimatePresence>
                {faqStates.faq2 && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="FAQAnswer"
                  >
                    {t("ColdWalletSetupDescription")}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <div className="flex flex-col w-[60vw] md:w-[90vw] sm:w-[90vw] max-w-[1280px] mb-[16px] h-auto bg-[#111] rounded-xl text-white px-[30px] py-[20px] flex items-center">
              <div
                className="flex w-full items-center cursor-pointer"
                onClick={() => toggleFAQ("faq3")}
              >
                <FaPlus
                  size="12px"
                  color="white"
                  className={`mr-[10px] ${
                    faqStates.faq3 ? "rotated-icon" : ""
                  }`}
                />
                {t("CryptocurrenciesCompatibleWithColdWallet")}
              </div>

              <AnimatePresence>
                {faqStates.faq3 && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="FAQAnswer"
                  >
                    {t("CryptocurrenciesCompatibilityDescription")}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <div className="flex flex-col w-[60vw] md:w-[90vw] sm:w-[90vw] max-w-[1280px] mb-[16px] h-auto bg-[#111] rounded-xl text-white px-[30px] py-[20px] flex items-center">
              <div
                className="flex w-full items-center cursor-pointer"
                onClick={() => toggleFAQ("faq4")}
              >
                <FaPlus
                  size="12px"
                  color="white"
                  className={`mr-[10px] ${
                    faqStates.faq4 ? "rotated-icon" : ""
                  }`}
                />
                {t("HowToSecureColdWallet")}
              </div>

              <AnimatePresence>
                {faqStates.faq4 && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="FAQAnswer"
                  >
                    {t("ColdWalletSecurityDescription")}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <div className="flex flex-col w-[60vw] md:w-[90vw] sm:w-[90vw] max-w-[1280px] mb-[16px] h-auto bg-[#111] rounded-xl text-white px-[30px] py-[20px] flex items-center">
              <div
                className="flex w-full items-center cursor-pointer"
                onClick={() => toggleFAQ("faq5")}
              >
                <FaPlus
                  size="12px"
                  color="white"
                  className={`mr-[10px] ${
                    faqStates.faq5 ? "rotated-icon" : ""
                  }`}
                />
                {t("ColdWalletNotWorking")}
              </div>

              <AnimatePresence>
                {faqStates.faq5 && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="FAQAnswer"
                  >
                    {t("ColdWalletErrorDescription")}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
        <Footer
          productRef={productRef}
          featureRef={featureRef}
          aboutUsRef={aboutUsRef}
          faqRef={faqRef}
        />
      </div>
    </div>
  );
}

export default Home;
