import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//en zh zh-TW fr es ar ja ru ko pt pt-BR it de hi mn th uk vi id tl bn
i18n
  .use(initReactI18next) // 绑定到 react-i18next
  .init({
    resources: {
      en: {
        translation: {
          Product: "Product",
          Feature: "Feature",
          "About Us": "About Us",
          FAQ: "FAQ",
          "Privacy Policy": "Privacy Policy",
          "Help Center": "Help Center",
          brandName: "LIKKIM", // 新增字段
          tagline: "Manage Your Crypto like never before", // 新增字段
          description:
            "Begin your journey to financial freedom with our app. Secure your assets with our advanced cold wallet technology, and manage your finances more smartly and effectively. Your financial wellbeing is our priority.", // 新增字段
          learnMore: "Learn more",
          "Lock Your Crypto, Securely": "Lock Your Crypto, Securely",
          "Security Features": "Security Features",
          "Cross-Platform": "Cross-Platform",
          "Offline storage": "Offline storage",
          "Against hacking": "Against hacking",
          "Unbreachable Security": "Unbreachable Security",
          Testimonials: "Testimonials",
          Testimonial1:
            "I've been using this cold wallet for months now, and I'm impressed by its reliability and security features. Highly recommended!",
          Testimonial2:
            "This cold wallet exceeded my expectations! Its user-friendly interface and top-notch security make it a must-have for anyone serious about crypto.",
          Testimonial3:
            "I've tried several cold wallets before, but this one stands out. Its sleek design and advanced features make managing my crypto assets a breeze.",
          "John Doe": "John Doe",
          "Cryptocurrency Enthusiast": "Cryptocurrency Enthusiast",
          "Jane Smith": "Jane Smith",
          "Crypto Security Advocate": "Crypto Security Advocate",
          "Alex Johnson": "Alex Johnson",
          "Investment Analyst": "Investment Analyst",
          "Touchscreen Interface": "Touchscreen Interface",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.",
          "Bluetooth Connectivity": "Bluetooth Connectivity",
          "Wireless syncing with your devices for real-time updates.":
            "Wireless syncing with your devices for real-time updates.",
          "Airtag Tracking": "Airtag Tracking",
          "Track your wallet easily with built-in Airtag.":
            "Track your wallet easily with built-in Airtag.",
          "Secure Chip Technology": "Secure Chip Technology",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.",
          "Cross-Platform Support": "Cross-Platform Support",
          "Works with various platforms for easy accessibility.":
            "Works with various platforms for easy accessibility.",
          AboutUsDescription1:
            "At LIKKIM, we are dedicated to providing top-quality solutions for managing your cryptocurrency assets securely.",
          AboutUsDescription2:
            "Our headquarters are in Zug, Switzerland. With a team of experienced professionals, we strive to innovate and adapt to the ever-changing landscape of the digital currency market.",
          AboutUsDescription3:
            "We support over 10,000 cryptocurrencies, ensuring compatibility and flexibility for all users.",
          BlockchainNetworksSupported: "Blockchain Networks Supported",
          CryptocurrenciesSupported: "Supporting Over 10,000 Cryptocurrencies",
          Years: "Years",
          ExpertiseInSecurity: "of Expertise in Security",
          FrequentlyAskedQuestions: "Frequently Asked Questions",
          WhatIsColdWallet: "What is a Cold Wallet?",
          ColdWalletDescription:
            "A cold wallet is a type of cryptocurrency wallet that stores your digital assets offline, making it less vulnerable to hacks and other online threats. Unlike hot wallets, which are connected to the internet and convenient for frequent transactions, cold wallets are best suited for long-term storage as they provide enhanced security.",
          HowToSetUpColdWallet: "How to Set Up Your Cold Wallet?",
          ColdWalletSetupDescription:
            "Setting up your cold wallet typically involves initializing the device on a secure, offline machine. You'll need to generate and securely store a recovery phrase, which is essential for accessing your assets in case of device loss or failure. Detailed instructions are provided with your purchase, ensuring a smooth setup process.",
          CryptocurrenciesCompatibleWithColdWallet:
            "Which cryptocurrencies are compatible with your cold wallets?",
          CryptocurrenciesCompatibilityDescription:
            "Our cold wallets support a wide range of cryptocurrencies, including major ones like Bitcoin, Ethereum, and Litecoin, as well as various altcoins. For a complete list of supported currencies, please visit our website or refer to the product documentation.",
          HowToSecureColdWallet: "How do I ensure my cold wallet is secure?",
          ColdWalletSecurityDescription:
            "To secure your cold wallet, always keep the recovery phrase in a safe and private location, never share it with anyone, and consider using additional security measures such as biometric locks or secure enclosures. Regularly updating the wallet's firmware from the official website also helps protect against vulnerabilities.",
          ColdWalletNotWorking:
            "What should I do if my cold wallet is not working or if I encounter an error?",
          ColdWalletErrorDescription:
            "If you experience any issues with your cold wallet, refer to the troubleshooting section of our user guide. For more specific problems, contact our support team through our website. Ensure you have your device information handy to expedite the support process.",
          Community: "Community",
          "Create a New Wallet Guide": "Create a New Wallet Guide",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.",
          "Import a Wallet Guide": "Import a Wallet Guide",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.",
          "Receive Cryptocurrency Guide": "Receive Cryptocurrency Guide",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "Step 3: Give the sender the verified address or QR code from the LIKKIM.",
          "Send Cryptocurrency Guide": "Send Cryptocurrency Guide",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.",
          Community: "Community",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.",
        },
      },
      zh: {
        translation: {
          Product: "产品",
          Feature: "特性",
          "About Us": "关于我们",
          FAQ: "常见问题",
          "Privacy Policy": "隐私政策",
          "Help Center": "帮助中心",
          brandName: "LIKKIM", // 新增字段
          tagline: "以前所未有的方式管理您的加密货币", // 新增字段
          description:
            "通过我们的应用程序开始您的财务自由之旅。利用我们先进的冷钱包技术保护您的资产，更智能、更有效地管理您的财务。您的财务健康是我们的首要任务。", // 新增字段
          learnMore: "了解更多", // 新增字段
          "Lock Your Crypto, Securely": "安全地锁定您的加密货币",
          "Security Features": "安全特性",
          "Cross-Platform": "跨平台",
          "Offline storage": "离线存储",
          "Against hacking": "防止黑客攻击",
          "Unbreachable Security": "无法突破的安全性",
          Testimonials: "用户评价",
          Testimonial1:
            "我已经使用这个冷钱包好几个月了，印象深刻的是它的可靠性和安全特性。强烈推荐！",
          Testimonial2:
            "这个冷钱包超出了我的预期！它的用户友好界面和一流的安全性让它成为任何严肃的加密货币用户必备的工具。",
          Testimonial3:
            "我之前试过几个冷钱包，但这个脱颖而出。它的流线型设计和先进功能让管理我的加密资产变得轻松。",
          "John Doe": "约翰·多伊",
          "Cryptocurrency Enthusiast": "加密货币爱好者",
          "Jane Smith": "简·史密斯",
          "Crypto Security Advocate": "加密安全倡导者",
          "Alex Johnson": "亚历克斯·约翰逊",
          "Investment Analyst": "投资分析师",
          "Touchscreen Interface": "触摸屏界面",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "通过我们直观的触摸屏界面，轻松管理您的加密资产，界面设计简洁，能够快速、便捷地浏览您的数字资产，实现快速交易和安全管理。",
          "Bluetooth Connectivity": "蓝牙连接",
          "Wireless syncing with your devices for real-time updates.":
            "与您的设备无线同步，实时更新。",
          "Airtag Tracking": "Airtag 跟踪",
          "Track your wallet easily with built-in Airtag.":
            "通过内置的 Airtag，轻松追踪您的钱包。",
          "Secure Chip Technology": "安全芯片技术",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "配备先进的安全芯片，确保您的宝贵数字资产免受未经授权的访问和网络威胁，提供强大的保护。",
          "Cross-Platform Support": "跨平台支持",
          "Works with various platforms for easy accessibility.":
            "与多种平台兼容，轻松访问。",
          AboutUsDescription1:
            "在LIKKIM，我们致力于提供高质量的解决方案，安全地管理您的加密资产。",
          AboutUsDescription2:
            "我们的总部位于瑞士楚格。凭借一支经验丰富的团队，我们努力创新并适应数字货币市场不断变化的格局。",
          AboutUsDescription3:
            "我们支持超过10,000种加密货币，确保为所有用户提供兼容性和灵活性。",
          BlockchainNetworksSupported: "支持的区块链网络",
          CryptocurrenciesSupported: "支持超过10,000种加密货币",
          Years: "年",
          ExpertiseInSecurity: "在安全领域的专业经验",
          FrequentlyAskedQuestions: "常见问题",
          WhatIsColdWallet: "什么是冷钱包？",
          ColdWalletDescription:
            "冷钱包是一种加密货币钱包，它将您的数字资产离线存储，从而使其不容易受到黑客攻击和其他在线威胁。与在线连接且适合频繁交易的热钱包不同，冷钱包最适合长期存储，因为它们提供增强的安全性。",
          HowToSetUpColdWallet: "如何设置冷钱包？",
          ColdWalletSetupDescription:
            "设置冷钱包通常涉及在安全的离线机器上初始化设备。您需要生成并安全地存储恢复短语，这对于在设备丢失或故障时访问您的资产至关重要。购买时会提供详细的说明，确保顺利完成设置过程。",
          CryptocurrenciesCompatibleWithColdWallet:
            "哪些加密货币可以与冷钱包兼容？",
          CryptocurrenciesCompatibilityDescription:
            "我们的冷钱包支持多种加密货币，包括比特币、以太坊、莱特币等主要货币，以及各种其他山寨币。有关支持的完整货币列表，请访问我们的网站或参考产品文档。",
          HowToSecureColdWallet: "如何确保冷钱包安全？",
          ColdWalletSecurityDescription:
            "为了确保冷钱包的安全，始终将恢复短语保存在安全和私密的位置，切勿与任何人分享，并考虑使用额外的安全措施，例如生物识别锁或安全外壳。定期从官方网站更新钱包的固件也有助于防范漏洞。",
          ColdWalletNotWorking:
            "如果我的冷钱包无法正常工作或遇到错误该怎么办？",
          ColdWalletErrorDescription:
            "如果您的冷钱包出现问题，请参考我们的用户指南中的故障排除部分。对于更具体的问题，请通过我们的网站联系支持团队。确保提供设备信息以加快支持过程。",
          Community: "社区",
          "Create a New Wallet Guide": "创建新钱包指南",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "步骤 1：打开 LIKKIM 硬件钱包并选择“创建钱包”。",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "步骤 2：写下 LIKKIM 硬件钱包显示的恢复短语并确认。",
          "Import a Wallet Guide": "导入钱包指南",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "步骤 1：打开 LIKKIM 硬件钱包并选择“导入钱包”。",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "步骤 2：使用 LIKKIM 硬件钱包输入恢复短语并确认。",
          "Receive Cryptocurrency Guide": "接收加密货币指南",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "步骤 1：打开 LIKKIM 应用，点击“接收”以显示地址和二维码。",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "步骤 2：在 LIKKIM 硬件钱包上验证地址和二维码。",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "步骤 3：将经过验证的地址或二维码提供给发送方。",
          "Send Cryptocurrency Guide": "发送加密货币指南",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "步骤 1：打开 LIKKIM 应用，点击“发送”，输入收款地址和金额。",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "步骤 2：在 LIKKIM 钱包上验证交易详情并按“确认”完成交易。",
          Community: "社区",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "加入我们的社区，在你喜爱的平台上保持最新状态，提出问题，并与其他用户建立联系。",
        },
      },
      "zh-TW": {
        translation: {
          Product: "產品",
          Feature: "特色",
          "About Us": "關於我們",
          FAQ: "常見問題",
          "Privacy Policy": "隱私政策",
          "Help Center": "幫助中心",
          brandName: "LIKKIM", // 新增字段
          tagline: "以前所未有的方式管理您的加密貨幣", // 新增字段
          description:
            "通過我們的應用程式開始您的財務自由之旅。利用我們先進的冷錢包技術保護您的資產，更智能、更有效地管理您的財務。您的財務健康是我們的首要任務。", // 新增字段
          learnMore: "了解更多", // 新增字段
          "Lock Your Crypto, Securely": "安全鎖定您的加密貨幣",
          "Security Features": "安全特性",
          "Cross-Platform": "跨平台",
          "Offline storage": "離線存儲",
          "Against hacking": "防止駭客攻擊",
          "Unbreachable Security": "無法突破的安全性",
          Testimonials: "使用者評價",
          Testimonial1:
            "我已經使用這個冷錢包好幾個月了，我對它的可靠性和安全功能印象深刻。強烈推薦！",
          Testimonial2:
            "這個冷錢包超出了我的預期！它的用戶友好介面和頂級安全性讓它成為任何認真對待加密貨幣的人必備的工具。",
          Testimonial3:
            "我以前嘗試過幾個冷錢包，但這個脫穎而出。它的流線型設計和先進功能讓管理我的加密資產變得輕而易舉。",
          "John Doe": "約翰·多伊",
          "Cryptocurrency Enthusiast": "加密貨幣愛好者",
          "Jane Smith": "簡·史密斯",
          "Crypto Security Advocate": "加密安全倡導者",
          "Alex Johnson": "亞歷克斯·約翰遜",
          "Investment Analyst": "投資分析師",
          "Touchscreen Interface": "觸控螢幕介面",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "透過我們直觀的觸控螢幕介面，輕鬆管理您的加密資產，設計簡潔，讓您快速且輕鬆地瀏覽數位資產，實現迅速交易並安全管理。",
          "Bluetooth Connectivity": "藍牙連接",
          "Wireless syncing with your devices for real-time updates.":
            "與您的裝置無線同步，實時更新。",
          "Airtag Tracking": "Airtag 跟蹤",
          "Track your wallet easily with built-in Airtag.":
            "輕鬆透過內建的 Airtag 追蹤您的錢包。",
          "Secure Chip Technology": "安全晶片技術",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "配備先進的安全晶片，確保您的珍貴數位資產免受未經授權的訪問和網絡威脅，提供強大保護。",
          "Cross-Platform Support": "跨平台支援",
          "Works with various platforms for easy accessibility.":
            "與多個平台兼容，方便訪問。",
          AboutUsDescription1:
            "在LIKKIM，我们致力于提供高品质的解决方案，安全地管理您的加密资产。",
          AboutUsDescription2:
            "我们的总部位于瑞士楚格。凭借一支经验丰富的团队，我们努力创新并适应数字货币市场不断变化的格局。",
          AboutUsDescription3:
            "我们支持超过10,000种加密货币，确保为所有用户提供兼容性和灵活性。",
          BlockchainNetworksSupported: "支持的区块链网络",
          CryptocurrenciesSupported: "支持超过10,000种加密货币",
          Years: "年",
          ExpertiseInSecurity: "在安全领域的专业经验",
          FrequentlyAskedQuestions: "常見問題",
          WhatIsColdWallet: "什麼是冷錢包？",
          ColdWalletDescription:
            "冷錢包是一種加密貨幣錢包，它將您的數位資產離線存儲，從而使其不容易受到駭客攻擊和其他線上威脅。與熱錢包不同，熱錢包是連接到網絡並適合頻繁交易的，而冷錢包則更適合長期儲存，因為它們提供了更強的安全性。",
          HowToSetUpColdWallet: "如何設置冷錢包？",
          ColdWalletSetupDescription:
            "設置冷錢包通常涉及在安全的離線設備上初始化設備。您需要生成並安全地儲存恢復短語，這對於在設備丟失或故障時訪問您的資產至關重要。購買時會提供詳細的說明，確保順利完成設置過程。",
          CryptocurrenciesCompatibleWithColdWallet:
            "哪些加密貨幣可以與冷錢包兼容？",
          CryptocurrenciesCompatibilityDescription:
            "我們的冷錢包支持多種加密貨幣，包括比特幣、以太坊、萊特幣等主要貨幣，以及各種其他山寨幣。詳情請訪問我們的網站或參閱產品文檔。",
          HowToSecureColdWallet: "如何確保冷錢包安全？",
          ColdWalletSecurityDescription:
            "為了確保冷錢包的安全，始終將恢復短語保存在安全且私密的地方，切勿與任何人分享，並考慮使用額外的安全措施，例如生物識別鎖或安全外殼。定期從官方網站更新錢包的固件也有助於防止漏洞。",
          ColdWalletNotWorking:
            "如果冷錢包無法正常工作或遇到錯誤，我該怎麼辦？",
          ColdWalletErrorDescription:
            "如果您的冷錢包出現問題，請參考我們用戶指南中的故障排除部分。對於更具體的問題，請通過我們的網站聯繫支持團隊。確保提供設備信息以加快支持過程。",
          Community: "社群",
          "Create a New Wallet Guide": "創建新錢包指南",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "步驟 1：打開 LIKKIM 硬體錢包並選擇「創建錢包」。",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "步驟 2：記下 LIKKIM 硬體錢包上顯示的恢復短語並確認。",
          "Import a Wallet Guide": "導入錢包指南",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "步驟 1：打開 LIKKIM 硬體錢包並選擇「導入錢包」。",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "步驟 2：使用 LIKKIM 硬體錢包輸入恢復短語並確認。",
          "Receive Cryptocurrency Guide": "接收加密貨幣指南",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "步驟 1：打開 LIKKIM 應用程式，點擊「接收」以顯示地址和二維碼。",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "步驟 2：在 LIKKIM 硬體錢包上驗證地址和二維碼。",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "步驟 3：將驗證過的地址或二維碼提供給發送者。",
          "Send Cryptocurrency Guide": "發送加密貨幣指南",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "步驟 1：打開 LIKKIM 應用程式，點擊「發送」，輸入收款地址和金額。",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "步驟 2：在 LIKKIM 硬體錢包上驗證交易詳情並按「確認」完成交易。",
          Community: "社群",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "加入我們的社群，在您喜愛的平台上保持最新狀態，提出問題，並與其他用戶建立聯繫。",
        },
      },
      fr: {
        translation: {
          Product: "Produit",
          Feature: "Caractéristique",
          "About Us": "À propos de nous",
          FAQ: "FAQ",
          "Privacy Policy": "Politique de confidentialité",
          "Help Center": "Centre d'aide",
          brandName: "LIKKIM", // 新增字段
          tagline: "Gérez votre crypto-monnaie comme jamais auparavant", // 新增字段
          description:
            "Commencez votre voyage vers la liberté financière avec notre application. Sécurisez vos actifs avec notre technologie de portefeuille froid avancée, et gérez vos finances plus intelligemment et plus efficacement. Votre bien-être financier est notre priorité.", // 新增字段
          learnMore: "En savoir plus",
          "Lock Your Crypto, Securely":
            "Verrouillez votre crypto-monnaie, en toute sécurité",
          "Security Features": "Caractéristiques de sécurité",
          "Cross-Platform": "Multi-plateforme",
          "Offline storage": "Stockage hors ligne",
          "Against hacking": "Contre le piratage",
          "Unbreachable Security": "Sécurité inviolable",
          Testimonials: "Témoignages",
          Testimonial1:
            "J'utilise ce portefeuille froid depuis des mois maintenant, et je suis impressionné par sa fiabilité et ses fonctionnalités de sécurité. Hautement recommandé !",
          Testimonial2:
            "Ce portefeuille froid a dépassé mes attentes ! Son interface conviviale et sa sécurité de premier ordre en font un incontournable pour toute personne sérieuse dans le domaine de la crypto.",
          Testimonial3:
            "J'ai essayé plusieurs portefeuilles froids auparavant, mais celui-ci se distingue. Son design élégant et ses fonctionnalités avancées facilitent la gestion de mes actifs crypto.",
          "John Doe": "John Doe",
          "Cryptocurrency Enthusiast": "Passionné de cryptomonnaies",
          "Jane Smith": "Jane Smith",
          "Crypto Security Advocate":
            "Avocate de la sécurité des crypto-monnaies",
          "Alex Johnson": "Alex Johnson",
          "Investment Analyst": "Analyste financier",
          "Touchscreen Interface": "Interface tactile",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "Découvrez une gestion facile des crypto-monnaies avec notre interface tactile intuitive, conçue pour une navigation rapide et simple dans vos actifs numériques, permettant des transactions rapides et une gestion sécurisée à portée de main.",
          "Bluetooth Connectivity": "Connectivité Bluetooth",
          "Wireless syncing with your devices for real-time updates.":
            "Synchronisation sans fil avec vos appareils pour des mises à jour en temps réel.",
          "Airtag Tracking": "Suivi Airtag",
          "Track your wallet easily with built-in Airtag.":
            "Suivez facilement votre portefeuille avec l'Airtag intégré.",
          "Secure Chip Technology": "Technologie de puce sécurisée",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "Équipé d'une puce de sécurité avancée, garantissant une protection robuste pour vos précieux actifs numériques contre les accès non autorisés et les cybermenaces.",
          "Cross-Platform Support": "Prise en charge multiplateforme",
          "Works with various platforms for easy accessibility.":
            "Compatible avec plusieurs plateformes pour un accès facile.",
          AboutUsDescription1:
            "Chez LIKKIM, nous nous consacrons à fournir des solutions de haute qualité pour la gestion sécurisée de vos actifs cryptographiques.",
          AboutUsDescription2:
            "Notre siège est à Zug, en Suisse. Avec une équipe de professionnels expérimentés, nous nous efforçons d'innover et de nous adapter au paysage en constante évolution du marché des monnaies numériques.",
          AboutUsDescription3:
            "Nous prenons en charge plus de 10 000 cryptomonnaies, garantissant la compatibilité et la flexibilité pour tous les utilisateurs.",
          BlockchainNetworksSupported: "Réseaux Blockchain supportés",
          CryptocurrenciesSupported:
            "Prise en charge de plus de 10 000 cryptomonnaies",
          Years: "Années",
          ExpertiseInSecurity: "d'expertise en sécurité",
          FrequentlyAskedQuestions: "Questions fréquemment posées",
          WhatIsColdWallet: "Qu'est-ce qu'un Cold Wallet ?",
          ColdWalletDescription:
            "Un cold wallet est un type de portefeuille de cryptomonnaie qui stocke vos actifs numériques hors ligne, les rendant moins vulnérables aux piratages et autres menaces en ligne. Contrairement aux hot wallets, qui sont connectés à Internet et pratiques pour les transactions fréquentes, les cold wallets sont mieux adaptés au stockage à long terme car ils offrent une sécurité renforcée.",
          HowToSetUpColdWallet: "Comment configurer votre Cold Wallet ?",
          ColdWalletSetupDescription:
            "Configurer votre cold wallet implique généralement d'initialiser l'appareil sur une machine sécurisée et hors ligne. Vous devrez générer et stocker en toute sécurité une phrase de récupération, essentielle pour accéder à vos actifs en cas de perte ou de défaillance de l'appareil. Des instructions détaillées sont fournies lors de votre achat, assurant une installation fluide.",
          CryptocurrenciesCompatibleWithColdWallet:
            "Quelles cryptomonnaies sont compatibles avec vos cold wallets ?",
          CryptocurrenciesCompatibilityDescription:
            "Nos cold wallets prennent en charge une large gamme de cryptomonnaies, y compris les principales comme Bitcoin, Ethereum et Litecoin, ainsi que diverses altcoins. Pour une liste complète des cryptomonnaies prises en charge, veuillez consulter notre site Web ou vous référer à la documentation du produit.",
          HowToSecureColdWallet:
            "Comment garantir la sécurité de votre Cold Wallet ?",
          ColdWalletSecurityDescription:
            "Pour sécuriser votre cold wallet, conservez toujours la phrase de récupération dans un endroit sûr et privé, ne la partagez jamais avec quiconque, et envisagez d'utiliser des mesures de sécurité supplémentaires telles que des verrous biométriques ou des enceintes sécurisées. Mettre régulièrement à jour le firmware du portefeuille depuis le site officiel aide également à protéger contre les vulnérabilités.",
          ColdWalletNotWorking:
            "Que faire si mon Cold Wallet ne fonctionne pas ou si je rencontre une erreur ?",
          ColdWalletErrorDescription:
            "Si vous rencontrez des problèmes avec votre cold wallet, consultez la section de dépannage de notre guide utilisateur. Pour des problèmes plus spécifiques, contactez notre équipe d'assistance via notre site Web. Assurez-vous d'avoir les informations sur votre appareil à portée de main pour accélérer le processus d'assistance.",
          Community: "Comunidad",
          "Create a New Wallet Guide":
            "Guide pour créer un nouveau portefeuille",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "Étape 1 : Allumez le portefeuille matériel LIKKIM et sélectionnez « Créer un portefeuille ».",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "Étape 2 : Notez la phrase de récupération affichée sur le portefeuille matériel LIKKIM et confirmez.",
          "Import a Wallet Guide": "Guide pour importer un portefeuille",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "Étape 1 : Allumez le portefeuille matériel LIKKIM et sélectionnez « Importer un portefeuille ».",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "Étape 2 : Entrez la phrase de récupération à l'aide du portefeuille matériel LIKKIM et confirmez.",
          "Receive Cryptocurrency Guide":
            "Guide pour recevoir des cryptomonnaies",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "Étape 1 : Ouvrez l'application LIKKIM, appuyez sur « Recevoir » pour afficher l'adresse et le code QR.",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "Étape 2 : Vérifiez l'adresse et le code QR sur le portefeuille matériel LIKKIM.",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "Étape 3 : Fournissez à l'expéditeur l'adresse ou le code QR vérifié du LIKKIM.",
          "Send Cryptocurrency Guide": "Guide pour envoyer des cryptomonnaies",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "Étape 1 : Ouvrez l'application LIKKIM, appuyez sur « Envoyer » et saisissez l'adresse du destinataire et le montant.",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "Étape 2 : Vérifiez les détails de la transaction sur le portefeuille LIKKIM et appuyez sur « Confirmer » pour terminer.",
          Community: "Communauté",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "Rejoignez notre communauté sur votre plateforme préférée pour rester à jour, poser des questions et vous connecter avec d'autres utilisateurs.",
        },
      },
      es: {
        translation: {
          Product: "Producto",
          Feature: "Características",
          "About Us": "Sobre nosotros",
          FAQ: "Preguntas frecuentes",
          "Privacy Policy": "Política de privacidad",
          "Help Center": "Centro de ayuda",
          brandName: "LIKKIM", // 新增字段
          tagline: "Administra tu criptomoneda como nunca antes", // 新增字段
          description:
            "Comienza tu viaje hacia la libertad financiera con nuestra aplicación. Asegura tus activos con nuestra avanzada tecnología de billetera fría y gestiona tus finanzas de manera más inteligente y efectiva. Tu bienestar financiero es nuestra prioridad.", // 新增字段
          learnMore: "Saber más",
          "Lock Your Crypto, Securely":
            "Bloquea tu criptomoneda de manera segura",
          "Security Features": "Características de seguridad",
          "Cross-Platform": "Multiplataforma",
          "Offline storage": "Almacenamiento sin conexión",
          "Against hacking": "Contra el hackeo",
          "Unbreachable Security": "Seguridad inquebrantable",
          Testimonials: "Testimonios",
          Testimonial1:
            "He estado usando esta billetera fría durante meses y estoy impresionado por su confiabilidad y características de seguridad. ¡Altamente recomendada!",
          Testimonial2:
            "¡Esta billetera fría superó mis expectativas! Su interfaz fácil de usar y su seguridad de primer nivel la convierten en una herramienta imprescindible para cualquiera que se tome en serio las criptomonedas.",
          Testimonial3:
            "He probado varias billeteras frías antes, pero esta se destaca. Su diseño elegante y sus características avanzadas hacen que gestionar mis activos criptográficos sea muy fácil.",
          "John Doe": "John Doe",
          "Cryptocurrency Enthusiast": "Entusiasta de las criptomonedas",
          "Jane Smith": "Jane Smith",
          "Crypto Security Advocate":
            "Defensora de la seguridad de las criptomonedas",
          "Alex Johnson": "Alex Johnson",
          "Investment Analyst": "Analista de inversiones",
          Testimonials: "Testimonios",
          Testimonial1:
            "He estado usando esta billetera fría durante meses y estoy impresionado por su confiabilidad y características de seguridad. ¡Altamente recomendada!",
          Testimonial2:
            "¡Esta billetera fría superó mis expectativas! Su interfaz fácil de usar y su seguridad de primer nivel la convierten en una herramienta imprescindible para cualquiera que se tome en serio las criptomonedas.",
          Testimonial3:
            "He probado varias billeteras frías antes, pero esta se destaca. Su diseño elegante y sus características avanzadas hacen que gestionar mis activos criptográficos sea muy fácil.",
          "John Doe": "John Doe",
          "Cryptocurrency Enthusiast": "Entusiasta de las criptomonedas",
          "Jane Smith": "Jane Smith",
          "Crypto Security Advocate":
            "Defensora de la seguridad de las criptomonedas",
          "Alex Johnson": "Alex Johnson",
          "Investment Analyst": "Analista de inversiones",
          "Touchscreen Interface": "Interfaz táctil",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "Disfruta de una gestión de criptomonedas sin esfuerzo con nuestra interfaz táctil intuitiva, diseñada para una navegación rápida y fácil a través de tus activos digitales, permitiendo transacciones rápidas y una gestión segura al alcance de tus manos.",
          "Bluetooth Connectivity": "Conectividad Bluetooth",
          "Wireless syncing with your devices for real-time updates.":
            "Sincronización inalámbrica con tus dispositivos para actualizaciones en tiempo real.",
          "Airtag Tracking": "Seguimiento de Airtag",
          "Track your wallet easily with built-in Airtag.":
            "Rastrea tu billetera fácilmente con el Airtag incorporado.",
          "Secure Chip Technology": "Tecnología de chip seguro",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "Equipado con un chip de seguridad avanzado, que garantiza una protección sólida para tus valiosos activos digitales contra accesos no autorizados y amenazas cibernéticas.",
          "Cross-Platform Support": "Soporte multiplataforma",
          "Works with various platforms for easy accessibility.":
            "Funciona con varias plataformas para un fácil acceso.",
          AboutUsDescription1:
            "En LIKKIM, nos dedicamos a proporcionar soluciones de alta calidad para gestionar sus activos en criptomonedas de forma segura.",
          AboutUsDescription2:
            "Nuestra sede está en Zug, Suiza. Con un equipo de profesionales experimentados, nos esforzamos por innovar y adaptarnos al panorama en constante cambio del mercado de monedas digitales.",
          AboutUsDescription3:
            "Apoyamos más de 10,000 criptomonedas, garantizando compatibilidad y flexibilidad para todos los usuarios.",
          BlockchainNetworksSupported: "Redes Blockchain Soportadas",
          CryptocurrenciesSupported: "Soportando más de 10,000 Criptomonedas",
          Years: "Años",
          ExpertiseInSecurity: "de experiencia en seguridad",
          FrequentlyAskedQuestions: "Preguntas frecuentes",
          WhatIsColdWallet: "¿Qué es un Cold Wallet?",
          ColdWalletDescription:
            "Un cold wallet es un tipo de billetera de criptomonedas que almacena tus activos digitales fuera de línea, lo que hace que sea menos vulnerable a los hacks y otras amenazas en línea. A diferencia de las hot wallets, que están conectadas a Internet y son convenientes para transacciones frecuentes, los cold wallets son más adecuados para almacenamiento a largo plazo, ya que ofrecen una seguridad mejorada.",
          HowToSetUpColdWallet: "¿Cómo configurar tu Cold Wallet?",
          ColdWalletSetupDescription:
            "Configurar tu cold wallet generalmente implica inicializar el dispositivo en una máquina segura y fuera de línea. Necesitarás generar y almacenar de manera segura una frase de recuperación, que es esencial para acceder a tus activos en caso de pérdida o fallo del dispositivo. Las instrucciones detalladas se proporcionan con la compra para garantizar un proceso de configuración sin problemas.",
          CryptocurrenciesCompatibleWithColdWallet:
            "¿Qué criptomonedas son compatibles con tus cold wallets?",
          CryptocurrenciesCompatibilityDescription:
            "Nuestros cold wallets son compatibles con una amplia gama de criptomonedas, incluidas las principales como Bitcoin, Ethereum y Litecoin, así como varias altcoins. Para obtener una lista completa de las monedas compatibles, por favor visita nuestro sitio web o consulta la documentación del producto.",
          HowToSecureColdWallet: "¿Cómo asegurar mi Cold Wallet?",
          ColdWalletSecurityDescription:
            "Para asegurar tu cold wallet, siempre guarda la frase de recuperación en un lugar seguro y privado, nunca la compartas con nadie y considera usar medidas de seguridad adicionales como bloqueos biométricos o contenedores seguros. Actualizar regularmente el firmware de la billetera desde el sitio web oficial también ayuda a proteger contra vulnerabilidades.",
          ColdWalletNotWorking:
            "¿Qué debo hacer si mi cold wallet no funciona o si encuentro un error?",
          ColdWalletErrorDescription:
            "Si tienes problemas con tu cold wallet, consulta la sección de solución de problemas de nuestra guía de usuario. Para problemas más específicos, contacta con nuestro equipo de soporte a través de nuestro sitio web. Asegúrate de tener la información de tu dispositivo a mano para agilizar el proceso de soporte.",
          Community: "Comunidad",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "Únete a nuestra comunidad en tu plataforma favorita para mantenerte actualizado, hacer preguntas y conectar con otros usuarios.",
        },
      },
      ar: {
        translation: {
          Product: "المنتج",
          Feature: "الميزة",
          "About Us": "من نحن",
          FAQ: "الأسئلة المتكررة",
          "Privacy Policy": "سياسة الخصوصية",
          "Help Center": "مركز المساعدة",
          brandName: "LIKKIM", // 新增字段
          tagline: "إدارة عملتك الرقمية كما لم يحدث من قبل", // 新增字段
          description:
            "ابدأ رحلتك نحو الحرية المالية مع تطبيقنا. قم بتأمين أصولك باستخدام تكنولوجيا المحفظة الباردة المتقدمة، وقم بإدارة أموالك بذكاء وفعالية أكبر. رفاهك المالي هو أولويتنا.", // 新增字段
          learnMore: "اعرف المزيد",
          "Lock Your Crypto, Securely": "قفل عملاتك الرقمية بأمان",
          "Security Features": "ميزات الأمان",
          "Cross-Platform": "عبر المنصات",
          "Offline storage": "التخزين دون اتصال",
          "Against hacking": "ضد القرصنة",
          "Unbreachable Security": "أمان لا يمكن اختراقه",
          Testimonials: "آراء العملاء",
          Testimonial1:
            "لقد كنت أستخدم هذه المحفظة الباردة لشهور الآن، وأنا معجب بموثوقيتها وميزات الأمان الخاصة بها. أوصي بها بشدة!",
          Testimonial2:
            "تجاوزت هذه المحفظة الباردة توقعاتي! واجهتها السهلة والأمان من الدرجة الأولى تجعلها أداة لا غنى عنها لأي شخص جاد في التعامل مع العملات الرقمية.",
          Testimonial3:
            "لقد جربت العديد من المحافظ الباردة من قبل، ولكن هذه تبرز. تصميمها الأنيق وميزاتها المتقدمة تجعل من إدارة أصولي المشفرة أمرًا سهلاً.",
          "John Doe": "جون دو",
          "Cryptocurrency Enthusiast": "متعصب العملات الرقمية",
          "Jane Smith": "جين سميث",
          "Crypto Security Advocate": "مدافع عن أمان العملات الرقمية",
          "Alex Johnson": "أليكس جونسون",
          "Investment Analyst": "محلل استثماري",
          "Touchscreen Interface": "واجهة شاشة تعمل باللمس",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "اختبر إدارة العملات الرقمية بكل سهولة من خلال واجهتنا التفاعلية التي تعمل باللمس، المصممة للتنقل السريع والسهولة عبر أصولك الرقمية، مما يتيح لك إجراء المعاملات بسرعة وإدارة آمنة في متناول يدك.",
          "Bluetooth Connectivity": "الاتصال عبر البلوتوث",
          "Wireless syncing with your devices for real-time updates.":
            "مزامنة لاسلكية مع أجهزتك للحصول على تحديثات فورية.",
          "Airtag Tracking": "تتبع Airtag",
          "Track your wallet easily with built-in Airtag.":
            "تتبع محفظتك بسهولة باستخدام Airtag المدمج.",
          "Secure Chip Technology": "تقنية الشريحة الآمنة",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "مزود بشريحة أمان متقدمة، مما يضمن حماية قوية لأصولك الرقمية الثمينة ضد الوصول غير المصرح به والتهديدات السيبرانية.",
          "Cross-Platform Support": "دعم عبر الأنظمة الأساسية",
          "Works with various platforms for easy accessibility.":
            "يعمل مع منصات مختلفة للوصول بسهولة.",
          AboutUsDescription1:
            "في LIKKIM، نحن ملتزمون بتقديم حلول عالية الجودة لإدارة أصولك الرقمية بشكل آمن.",
          AboutUsDescription2:
            "مقرنا الرئيسي في زيورخ، سويسرا. مع فريق من المحترفين ذوي الخبرة، نسعى للابتكار والتكيف مع المشهد المتغير باستمرار في سوق العملات الرقمية.",
          AboutUsDescription3:
            "ندعم أكثر من 10,000 عملة رقمية، مما يضمن التوافق والمرونة لجميع المستخدمين.",
          BlockchainNetworksSupported: "شبكات Blockchain المدعومة",
          CryptocurrenciesSupported: "دعم أكثر من 10,000 عملة رقمية",
          Years: "سنوات",
          ExpertiseInSecurity: "من الخبرة في الأمن",
          FrequentlyAskedQuestions: "الأسئلة المتكررة",
          WhatIsColdWallet: "ما هو محفظة باردة؟",
          ColdWalletDescription:
            "محفظة باردة هي نوع من محفظات العملات الرقمية التي تخزن أصولك الرقمية دون اتصال بالإنترنت، مما يجعلها أقل عرضة للاختراقات والتهديدات الأخرى عبر الإنترنت. على عكس المحافظ الساخنة، التي تتصل بالإنترنت وتناسب المعاملات المتكررة، تعد المحافظ الباردة الأنسب للتخزين طويل الأجل لأنها توفر أمانًا معززًا.",
          HowToSetUpColdWallet: "كيف إعداد محفظتك الباردة؟",
          ColdWalletSetupDescription:
            "إعداد محفظتك الباردة يتضمن عادةً تهيئة الجهاز على جهاز آمن غير متصل بالإنترنت. ستحتاج إلى إنشاء وتخزين عبارة استرداد بشكل آمن، والتي تعد أساسية للوصول إلى أصولك في حالة فقدان الجهاز أو تعطلها. سيتم توفير تعليمات مفصلة مع الشراء لضمان عملية إعداد سلسة.",
          CryptocurrenciesCompatibleWithColdWallet:
            "ما هي العملات الرقمية المتوافقة مع محفظتك الباردة؟",
          CryptocurrenciesCompatibilityDescription:
            "تدعم محافظنا الباردة مجموعة واسعة من العملات الرقمية، بما في ذلك العملات الرئيسية مثل Bitcoin وEthereum وLitecoin، وكذلك العديد من العملات البديلة. للحصول على قائمة كاملة من العملات المدعومة، يرجى زيارة موقعنا الإلكتروني أو الرجوع إلى الوثائق الخاصة بالمنتج.",
          HowToSecureColdWallet: "كيف أضمن أمان محفظتي الباردة؟",
          ColdWalletSecurityDescription:
            "لتأمين محفظتك الباردة، احتفظ دائمًا بعبارة الاسترداد في مكان آمن خاص، ولا تشاركها مع أي شخص، واعتبر استخدام تدابير أمان إضافية مثل الأقفال البيومترية أو الحاويات الآمنة. يساعد أيضًا تحديث البرنامج الثابت للمحفظة بانتظام من الموقع الرسمي في حماية محفظتك من الثغرات.",
          ColdWalletNotWorking:
            "ماذا أفعل إذا كانت محفظتي الباردة لا تعمل أو إذا واجهت خطأ؟",
          ColdWalletErrorDescription:
            "إذا واجهت أي مشكلة مع محفظتك الباردة، راجع قسم استكشاف الأخطاء وإصلاحها في دليل المستخدم. في حال كان لديك مشكلة أكثر تحديدًا، اتصل بفريق الدعم لدينا من خلال موقعنا الإلكتروني. تأكد من أن لديك معلومات جهازك في متناول اليد لتسريع عملية الدعم.",
          Community: "المجتمع",
          "Create a New Wallet Guide": "دليل إنشاء محفظة جديدة",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "الخطوة 1: قم بتشغيل محفظة LIKKIM المادية وحدد “إنشاء محفظة”.",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "الخطوة 2: قم بكتابة العبارة الاحتياطية المعروضة على محفظة LIKKIM المادية وقم بالتأكيد.",
          "Import a Wallet Guide": "دليل استيراد محفظة",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "الخطوة 1: قم بتشغيل محفظة LIKKIM المادية وحدد “استيراد محفظة”.",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "الخطوة 2: أدخل العبارة الاحتياطية باستخدام محفظة LIKKIM المادية وقم بالتأكيد.",
          "Receive Cryptocurrency Guide": "دليل استلام العملات المشفرة",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "الخطوة 1: افتح تطبيق LIKKIM واضغط على 'استلام' لعرض العنوان ورمز الاستجابة السريعة.",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "الخطوة 2: تحقق من العنوان ورمز الاستجابة السريعة على محفظة LIKKIM المادية.",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "الخطوة 3: أعط المُرسل العنوان أو رمز الاستجابة السريعة الذي تم التحقق منه من LIKKIM.",
          "Send Cryptocurrency Guide": "دليل إرسال العملات المشفرة",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "الخطوة 1: افتح تطبيق LIKKIM واضغط على “إرسال”، ثم أدخل عنوان المستلم والمبلغ.",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "الخطوة 2: تحقق من تفاصيل المعاملة على محفظة LIKKIM واضغط على “تأكيد” لإتمام العملية.",
          Community: "المجتمع",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "انضم إلى مجتمعنا على منصتك المفضلة لتبقى على اطلاع، تطرح الأسئلة، وتتصل بمستخدمين آخرين.",
        },
      },
      ja: {
        translation: {
          Product: "製品",
          Feature: "特徴",
          "About Us": "私たちについて",
          FAQ: "FAQ",
          "Privacy Policy": "プライバシーポリシー",
          "Help Center": "ヘルプセンター",
          brandName: "LIKKIM", // 新增字段
          tagline: "これまでにない方法で暗号通貨を管理する", // 正确的日语翻译
          description:
            "私たちのアプリで、財務自由への旅を始めましょう。進んだコールドウォレット技術で資産を守り、より賢明かつ効率的に財務を管理します。あなたの財務健康が私たちの最優先事項です。", // 正确的日语翻译
          learnMore: "もっと知る",
          "Lock Your Crypto, Securely": "暗号通貨を安全にロック",
          "Security Features": "セキュリティ機能",
          "Cross-Platform": "クロスプラットフォーム",
          "Offline storage": "オフラインストレージ",
          "Against hacking": "ハッキング防止",
          "Unbreachable Security": "突破不可能なセキュリティ",
          Testimonials: "お客様の声",
          Testimonial1:
            "数ヶ月間このコールドウォレットを使用していますが、その信頼性とセキュリティ機能に感銘を受けています。強くお勧めします！",
          Testimonial2:
            "このコールドウォレットは私の期待を超えました！使いやすいインターフェースと最高レベルのセキュリティが、それを暗号通貨に真剣な人にとって必須のツールにしています。",
          Testimonial3:
            "いくつかのコールドウォレットを試しましたが、これが際立っています。洗練されたデザインと高度な機能で、暗号資産の管理がとても簡単になりました。",
          "John Doe": "ジョン・ドウ",
          "Cryptocurrency Enthusiast": "暗号通貨愛好者",
          "Jane Smith": "ジェーン・スミス",
          "Crypto Security Advocate": "暗号通貨セキュリティ提唱者",
          "Alex Johnson": "アレックス・ジョンソン",
          "Investment Analyst": "投資アナリスト",
          "Touchscreen Interface": "タッチスクリーンインターフェース",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "直感的なタッチスクリーンインターフェースを使って、簡単に暗号通貨を管理できます。デジタル資産を迅速かつ簡単にナビゲートし、迅速な取引と安全な管理を手のひらで実現します。",
          "Bluetooth Connectivity": "Bluetooth 接続",
          "Wireless syncing with your devices for real-time updates.":
            "デバイスとワイヤレスで同期し、リアルタイムで更新します。",
          "Airtag Tracking": "Airtag トラッキング",
          "Track your wallet easily with built-in Airtag.":
            "内蔵のAirtagで財布を簡単に追跡できます。",
          "Secure Chip Technology": "セキュアチップ技術",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "高度なセキュリティチップを搭載し、貴重なデジタル資産を不正アクセスやサイバー脅威から強力に保護します。",
          "Cross-Platform Support": "クロスプラットフォーム対応",
          "Works with various platforms for easy accessibility.":
            "さまざまなプラットフォームに対応しており、簡単にアクセスできます。",
          AboutUsDescription1:
            "LIKKIMでは、暗号資産を安全に管理するための高品質なソリューションを提供することに専念しています。",
          AboutUsDescription2:
            "本社はスイスのツークにあります。経験豊富なプロフェッショナルチームとともに、デジタル通貨市場の変化する風景に適応し、革新を目指しています。",
          AboutUsDescription3:
            "私たちは10,000種類以上の暗号通貨をサポートしており、すべてのユーザーに互換性と柔軟性を提供しています。",
          BlockchainNetworksSupported:
            "サポートされているブロックチェーンネットワーク",
          CryptocurrenciesSupported: "10,000種類以上の暗号通貨に対応",
          Years: "年",
          ExpertiseInSecurity: "セキュリティの専門知識",
          FrequentlyAskedQuestions: "よくある質問",
          WhatIsColdWallet: "コールドウォレットとは何ですか？",
          ColdWalletDescription:
            "コールドウォレットは、デジタル資産をオフラインで保存する暗号通貨ウォレットの一種で、ハッキングやその他のオンライン脅威から守ります。インターネットに接続されており、頻繁な取引に便利なホットウォレットとは異なり、コールドウォレットは長期保管に最適で、強化されたセキュリティを提供します。",
          HowToSetUpColdWallet: "コールドウォレットを設定する方法は？",
          ColdWalletSetupDescription:
            "コールドウォレットの設定は通常、セキュアなオフラインマシンでデバイスを初期化することから始まります。デバイスの紛失や故障時に資産にアクセスするために必要なリカバリーフレーズを生成し、安全に保管する必要があります。購入時に提供される詳細な説明により、スムーズな設定プロセスが保証されます。",
          CryptocurrenciesCompatibleWithColdWallet:
            "どの暗号通貨がコールドウォレットに対応していますか？",
          CryptocurrenciesCompatibilityDescription:
            "当社のコールドウォレットは、Bitcoin、Ethereum、Litecoinなどの主要な暗号通貨をはじめ、さまざまなアルトコインに対応しています。サポートされている通貨の完全なリストについては、当社のウェブサイトをご覧いただくか、製品のドキュメントをご参照ください。",
          HowToSecureColdWallet: "コールドウォレットをどのように保護しますか？",
          ColdWalletSecurityDescription:
            "コールドウォレットのセキュリティを確保するためには、リカバリーフレーズを安全でプライベートな場所に保管し、他の人と共有しないようにし、バイオメトリックロックやセキュアな収納場所などの追加のセキュリティ対策を考慮してください。公式ウェブサイトから定期的にウォレットのファームウェアを更新することも、脆弱性から保護するために役立ちます。",
          ColdWalletNotWorking:
            "コールドウォレットが動作しない、またはエラーが発生した場合はどうすればよいですか？",
          ColdWalletErrorDescription:
            "コールドウォレットに問題が発生した場合は、ユーザーガイドのトラブルシューティングセクションをご参照ください。特定の問題については、ウェブサイトを通じてサポートチームにお問い合わせください。サポートプロセスを迅速に進めるために、デバイス情報を手元に準備しておいてください。",
          Community: "コミュニティ",
          "Create a New Wallet Guide": "新しいウォレット作成ガイド",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "ステップ 1：LIKKIM ハードウェアウォレットをオンにして、「ウォレットを作成」を選択します。",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "ステップ 2：LIKKIM ハードウェアウォレットに表示されるリカバリーフレーズを書き留めて確認します。",
          "Import a Wallet Guide": "ウォレットのインポートガイド",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "ステップ 1：LIKKIM ハードウェアウォレットをオンにして、「ウォレットをインポート」を選択します。",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "ステップ 2：LIKKIM ハードウェアウォレットを使用してリカバリーフレーズを入力し、確認します。",
          "Receive Cryptocurrency Guide": "暗号通貨の受け取りガイド",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "ステップ 1：LIKKIM アプリを開き、「受け取る」をタップしてアドレスと QR コードを表示します。",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "ステップ 2：LIKKIM ハードウェアウォレットでアドレスと QR コードを確認します。",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "ステップ 3：送信者に LIKKIM から検証済みのアドレスまたは QR コードを渡します。",
          "Send Cryptocurrency Guide": "暗号通貨送信ガイド",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "ステップ 1：LIKKIM アプリを開き、「送信」をタップして受信者のアドレスと金額を入力します。",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "ステップ 2：LIKKIM ウォレットで取引の詳細を確認し、「確認」を押して完了します。",
          Community: "コミュニティ",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "お気に入りのプラットフォームでコミュニティに参加して、最新情報を入手し、質問をして、他のユーザーとつながりましょう。",
        },
      },
      ru: {
        translation: {
          Product: "Продукт",
          Feature: "Особенности",
          "About Us": "О нас",
          FAQ: "Часто задаваемые вопросы",
          "Privacy Policy": "Политика конфиденциальности",
          "Help Center": "Центр помощи",
          brandName: "LIKKIM", // 新增字段
          tagline: "Управляйте своими криптовалютами как никогда раньше", // 新增字段
          description:
            "Начните свое путешествие к финансовой свободе с нашим приложением. Защитите свои активы с помощью нашей передовой технологии холодного кошелька и управляйте своими финансами более умно и эффективно. Ваше финансовое благополучие — наш приоритет.", // 新增字段
          learnMore: "Узнать больше",
          "Lock Your Crypto, Securely":
            "Надежно заблокируйте свои криптовалюты",
          "Security Features": "Функции безопасности",
          "Cross-Platform": "Кроссплатформенность",
          "Offline storage": "Офлайн-хранилище",
          "Against hacking": "Защита от взлома",
          "Unbreachable Security": "Непробиваемая безопасность",
          Testimonials: "Отзывы",
          Testimonial1:
            "Я использую этот холодный кошелек уже несколько месяцев, и я впечатлен его надежностью и функциями безопасности. Настоятельно рекомендую!",
          Testimonial2:
            "Этот холодный кошелек превзошел мои ожидания! Его удобный интерфейс и отличная безопасность делают его обязательным инструментом для всех, кто серьезно относится к криптовалютам.",
          Testimonial3:
            "Я пробовал несколько холодных кошельков раньше, но этот выделяется. Его стильный дизайн и передовые функции позволяют легко управлять моими криптоактивами.",
          "John Doe": "Джон Доу",
          "Cryptocurrency Enthusiast": "Энтузиаст криптовалют",
          "Jane Smith": "Джейн Смит",
          "Crypto Security Advocate": "Защитник безопасности криптовалют",
          "Alex Johnson": "Алекс Джонсон",
          "Investment Analyst": "Инвестиционный аналитик",
          "Touchscreen Interface": "Интерфейс с сенсорным экраном",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "Используйте наш интуитивно понятный интерфейс с сенсорным экраном для удобного управления криптовалютой, позволяющий быстро и легко навигировать по вашим цифровым активам, обеспечивая быстрые транзакции и безопасное управление.",
          "Bluetooth Connectivity": "Подключение по Bluetooth",
          "Wireless syncing with your devices for real-time updates.":
            "Беспроводная синхронизация с вашими устройствами для обновлений в реальном времени.",
          "Airtag Tracking": "Отслеживание с помощью Airtag",
          "Track your wallet easily with built-in Airtag.":
            "Легко отслеживайте свой кошелек с помощью встроенного Airtag.",
          "Secure Chip Technology": "Технология защищенного чипа",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "Оснащен передовым чипом безопасности, обеспечивающим надежную защиту ваших ценных цифровых активов от несанкционированного доступа и киберугроз.",
          "Cross-Platform Support": "Поддержка нескольких платформ",
          "Works with various platforms for easy accessibility.":
            "Работает с различными платформами для легкости доступа.",
          AboutUsDescription1:
            "В LIKKIM мы занимаемся предоставлением высококачественных решений для безопасного управления вашими криптовалютными активами.",
          AboutUsDescription2:
            "Наш главный офис находится в Цуге, Швейцария. С командой опытных профессионалов мы стремимся к инновациям и адаптации к постоянно меняющемуся рынку цифровых валют.",
          AboutUsDescription3:
            "Мы поддерживаем более 10 000 криптовалют, обеспечивая совместимость и гибкость для всех пользователей.",
          BlockchainNetworksSupported: "Поддерживаемые блокчейн-сети",
          CryptocurrenciesSupported: "Поддержка более 10 000 криптовалют",
          Years: "Годы",
          ExpertiseInSecurity: "опыт в области безопасности",
          FrequentlyAskedQuestions: "Часто задаваемые вопросы",
          WhatIsColdWallet: "Что такое холодный кошелек?",
          ColdWalletDescription:
            "Холодный кошелек - это тип криптовалютного кошелька, который хранит ваши цифровые активы офлайн, что делает его менее уязвимым для взломов и других онлайн-угроз. В отличие от горячих кошельков, которые подключены к интернету и удобны для частых транзакций, холодные кошельки лучше всего подходят для долгосрочного хранения, так как они обеспечивают усиленную безопасность.",
          HowToSetUpColdWallet: "Как настроить холодный кошелек?",
          ColdWalletSetupDescription:
            "Настройка холодного кошелька обычно включает в себя инициализацию устройства на безопасной оффлайн-машине. Вам нужно будет создать и безопасно хранить фразу восстановления, которая необходима для доступа к вашим активам в случае потери устройства или его поломки. Подробные инструкции предоставляются с покупкой, чтобы гарантировать плавный процесс настройки.",
          CryptocurrenciesCompatibleWithColdWallet:
            "Какие криптовалюты совместимы с вашими холодными кошельками?",
          CryptocurrenciesCompatibilityDescription:
            "Наши холодные кошельки поддерживают широкий спектр криптовалют, включая основные такие как Bitcoin, Ethereum и Litecoin, а также различные альткойны. Для получения полного списка поддерживаемых валют, пожалуйста, посетите наш сайт или обратитесь к документации продукта.",
          HowToSecureColdWallet:
            "Как обеспечить безопасность моего холодного кошелька?",
          ColdWalletSecurityDescription:
            "Чтобы обезопасить свой холодный кошелек, всегда храните фразу восстановления в безопасном и приватном месте, не делитесь ею с кем-либо и подумайте о дополнительных мерах безопасности, таких как биометрические блокировки или защищенные корпуса. Регулярное обновление прошивки кошелька с официального сайта также помогает защититься от уязвимостей.",
          ColdWalletNotWorking:
            "Что делать, если мой холодный кошелек не работает или я сталкиваюсь с ошибкой?",
          ColdWalletErrorDescription:
            "Если у вас возникли проблемы с холодным кошельком, обратитесь к разделу устранения неполадок в руководстве пользователя. Для более конкретных проблем свяжитесь с нашей службой поддержки через наш сайт. Убедитесь, что у вас под рукой есть информация о вашем устройстве, чтобы ускорить процесс поддержки.",
          Community: "Сообщество",
          "Create a New Wallet Guide":
            "Руководство по созданию нового кошелька",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "Шаг 1: Включите аппаратный кошелек LIKKIM и выберите «Создать кошелек».",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "Шаг 2: Запишите фразу для восстановления, отображаемую на аппаратном кошельке LIKKIM, и подтвердите.",
          "Import a Wallet Guide": "Руководство по импорту кошелька",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "Шаг 1: Включите аппаратный кошелек LIKKIM и выберите «Импортировать кошелек».",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "Шаг 2: Введите фразу для восстановления с помощью аппаратного кошелька LIKKIM и подтвердите.",
          "Receive Cryptocurrency Guide": "Руководство по приему криптовалюты",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "Шаг 1: Откройте приложение LIKKIM, нажмите «Получить», чтобы отобразить адрес и QR-код.",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "Шаг 2: Проверьте адрес и QR-код на аппаратном кошельке LIKKIM.",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "Шаг 3: Передайте отправителю проверенный адрес или QR-код с LIKKIM.",
          "Send Cryptocurrency Guide": "Руководство по отправке криптовалюты",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "Шаг 1: Откройте приложение LIKKIM, нажмите «Отправить» и введите адрес получателя и сумму.",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "Шаг 2: Проверьте детали транзакции на кошельке LIKKIM и нажмите «Подтвердить», чтобы завершить.",
          Community: "Сообщество",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "Присоединяйтесь к нашему сообществу на вашей любимой платформе, чтобы оставаться в курсе событий, задавать вопросы и общаться с другими пользователями.",
        },
      },
      ko: {
        translation: {
          Product: "제품",
          Feature: "특징",
          "About Us": "우리에 대해",
          FAQ: "자주 묻는 질문",
          "Privacy Policy": "개인정보 처리방침",
          "Help Center": "고객센터",
          brandName: "LIKKIM", // 新增字段
          tagline: "이전에 없던 방식으로 암호화폐를 관리하세요", // 新增字段
          description:
            "우리의 앱을 통해 재정적 자유의 여정을 시작하세요. 고급 냉지갑 기술로 자산을 보호하고 더 스마트하고 효과적으로 재정을 관리하세요. 당신의 재정 건강은 우리의 최우선입니다.", // 新增字段
          learnMore: "자세히 보기",
          "Lock Your Crypto, Securely": "암호화폐를 안전하게 잠그세요",
          "Security Features": "보안 기능",
          "Cross-Platform": "크로스 플랫폼",
          "Offline storage": "오프라인 스토리지",
          "Against hacking": "해킹 방지",
          "Unbreachable Security": "뚫을 수 없는 보안",
          Testimonials: "고객 리뷰",
          Testimonial1:
            "몇 달 동안 이 콜드 월렛을 사용해왔으며, 그 신뢰성과 보안 기능에 깊은 인상을 받았습니다. 강력히 추천합니다!",
          Testimonial2:
            "이 콜드 월렛은 제 기대를 뛰어넘었습니다! 사용자 친화적인 인터페이스와 최고 수준의 보안 덕분에 암호화폐에 진지한 사람이라면 누구나 필수적으로 가져야 할 도구입니다.",
          Testimonial3:
            "이전에 여러 콜드 월렛을 사용해봤지만, 이 제품은 두각을 나타냅니다. 세련된 디자인과 고급 기능 덕분에 제 암호 자산 관리가 훨씬 쉬워졌습니다.",
          "John Doe": "존 도",
          "Cryptocurrency Enthusiast": "암호화폐 애호가",
          "Jane Smith": "제인 스미스",
          "Crypto Security Advocate": "암호화폐 보안 옹호자",
          "Alex Johnson": "알렉스 존슨",
          "Investment Analyst": "투자 분석가",
          "Touchscreen Interface": "터치스크린 인터페이스",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "직관적인 터치스크린 인터페이스를 통해 암호화폐 관리가 쉬워집니다. 빠르고 간편한 디지털 자산 탐색을 통해 빠른 거래와 안전한 관리가 가능합니다.",
          "Bluetooth Connectivity": "블루투스 연결",
          "Wireless syncing with your devices for real-time updates.":
            "디바이스와 무선으로 동기화되어 실시간으로 업데이트됩니다.",
          "Airtag Tracking": "Airtag 추적",
          "Track your wallet easily with built-in Airtag.":
            "내장된 Airtag로 지갑을 쉽게 추적하세요.",
          "Secure Chip Technology": "보안 칩 기술",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "고급 보안 칩이 탑재되어 있어 귀하의 중요한 디지털 자산을 무단 접근과 사이버 위협으로부터 안전하게 보호합니다.",
          "Cross-Platform Support": "크로스 플랫폼 지원",
          "Works with various platforms for easy accessibility.":
            "여러 플랫폼에서 손쉽게 접근할 수 있습니다.",
          AboutUsDescription1:
            "LIKKIM에서는 암호화폐 자산을 안전하게 관리하는 고품질 솔루션을 제공합니다.",
          AboutUsDescription2:
            "본사는 스위스 추크에 있습니다. 경험이 풍부한 전문가 팀과 함께 디지털 통화 시장의 끊임없이 변화하는 환경에 혁신하고 적응하려고 노력하고 있습니다.",
          AboutUsDescription3:
            "우리는 10,000개 이상의 암호화폐를 지원하여 모든 사용자가 호환성과 유연성을 보장합니다.",
          BlockchainNetworksSupported: "지원되는 블록체인 네트워크",
          CryptocurrenciesSupported: "10,000개 이상의 암호화폐 지원",
          Years: "년",
          ExpertiseInSecurity: "보안 전문 지식",
          FrequentlyAskedQuestions: "자주 묻는 질문",
          WhatIsColdWallet: "콜드 월렛이란 무엇인가요?",
          ColdWalletDescription:
            "콜드 월렛은 디지털 자산을 오프라인으로 저장하는 암호화폐 지갑의 일종으로, 해킹과 다른 온라인 위협에 덜 취약합니다. 인터넷에 연결되어 자주 거래하는 데 편리한 핫 월렛과 달리, 콜드 월렛은 장기 저장에 더 적합하며 보안성이 강화되어 있습니다.",
          HowToSetUpColdWallet: "콜드 월렛을 어떻게 설정하나요?",
          ColdWalletSetupDescription:
            "콜드 월렛을 설정하려면 일반적으로 보안된 오프라인 장치에서 장치를 초기화해야 합니다. 장치가 분실되거나 고장날 경우 자산에 접근할 수 있도록 복구 문구를 생성하고 안전하게 보관해야 합니다. 구매 시 제공되는 자세한 설명을 통해 설정 프로세스를 원활하게 진행할 수 있습니다.",
          CryptocurrenciesCompatibleWithColdWallet:
            "어떤 암호화폐가 콜드 월렛과 호환되나요?",
          CryptocurrenciesCompatibilityDescription:
            "저희 콜드 월렛은 비트코인, 이더리움, 라이트코인 등 주요 암호화폐를 비롯해 다양한 알트코인을 지원합니다. 지원되는 암호화폐의 전체 목록은 저희 웹사이트에서 확인하거나 제품 문서를 참조해 주세요.",
          HowToSecureColdWallet: "콜드 월렛을 어떻게 안전하게 보호하나요?",
          ColdWalletSecurityDescription:
            "콜드 월렛을 안전하게 보호하려면 복구 문구를 안전하고 비공개된 장소에 보관하고, 다른 사람과 공유하지 않으며, 생체 인식 잠금장치나 보안 케이스와 같은 추가 보안 조치를 고려해 보세요. 공식 웹사이트에서 월렛의 펌웨어를 정기적으로 업데이트하는 것도 보안을 강화하는 데 도움이 됩니다.",
          ColdWalletNotWorking:
            "콜드 월렛이 작동하지 않거나 오류가 발생하면 어떻게 해야 하나요?",
          ColdWalletErrorDescription:
            "콜드 월렛에 문제가 발생하면 사용자 가이드의 문제 해결 섹션을 참조하세요. 보다 구체적인 문제가 있을 경우, 저희 웹사이트를 통해 지원 팀에 문의하십시오. 지원 프로세스를 빠르게 진행할 수 있도록 장치 정보를 준비해 주세요.",
          Community: "공동체",
          "Create a New Wallet Guide": "새 지갑 생성 가이드",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "1단계: LIKKIM 하드웨어 지갑을 켜고 “지갑 생성”을 선택합니다.",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "2단계: LIKKIM 하드웨어 지갑에 표시된 복구 구문을 적어 확인합니다.",
          "Import a Wallet Guide": "지갑 가져오기 가이드",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "1단계: LIKKIM 하드웨어 지갑을 켜고 “지갑 가져오기”를 선택합니다.",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "2단계: LIKKIM 하드웨어 지갑을 사용하여 복구 구문을 입력하고 확인합니다.",
          "Receive Cryptocurrency Guide": "암호화폐 수신 가이드",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "1단계: LIKKIM 앱을 열고 ‘수신’을 탭하여 주소와 QR 코드를 표시합니다.",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "2단계: LIKKIM 하드웨어 지갑에서 주소와 QR 코드를 확인합니다.",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "3단계: LIKKIM에서 확인된 주소나 QR 코드를 발신자에게 전달합니다.",
          "Send Cryptocurrency Guide": "암호화폐 전송 가이드",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "1단계: LIKKIM 앱을 열고 “전송”을 탭하여 수신자의 주소와 금액을 입력합니다.",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "2단계: LIKKIM 지갑에서 거래 세부 정보를 확인하고 “확인”을 눌러 완료합니다.",
          Community: "커뮤니티",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "좋아하는 플랫폼에서 우리 커뮤니티에 가입하여 최신 정보를 받고, 질문을 하고, 다른 사용자와 연결하세요.",
        },
      },
      pt: {
        translation: {
          Product: "Produto",
          Feature: "Recurso",
          "About Us": "Sobre nós",
          FAQ: "Perguntas frequentes",
          "Privacy Policy": "Política de privacidade",
          "Help Center": "Centro de ajuda",
          brandName: "LIKKIM", // 新增字段
          tagline: "Gerencie sua criptomoeda como nunca antes", // 新增字段
          description:
            "Comece sua jornada para a liberdade financeira com nosso aplicativo. Proteja seus ativos com nossa tecnologia avançada de carteira fria e gerencie suas finanças de maneira mais inteligente e eficaz. Seu bem-estar financeiro é nossa prioridade.", // 新增字段
          learnMore: "Saiba mais",
          "Lock Your Crypto, Securely":
            "Bloqueie sua criptomoeda com segurança",
          "Security Features": "Recursos de segurança",
          "Cross-Platform": "Multiplataforma",
          "Offline storage": "Armazenamento offline",
          "Against hacking": "Contra hackers",
          "Unbreachable Security": "Segurança inquebrável",
          Testimonials: "Depoimentos",
          Testimonial1:
            "Estou usando esta carteira fria há meses e estou impressionado com sua confiabilidade e recursos de segurança. Altamente recomendada!",
          Testimonial2:
            "Esta carteira fria superou minhas expectativas! Sua interface fácil de usar e segurança de ponta a tornam uma ferramenta indispensável para qualquer pessoa séria sobre criptomoedas.",
          Testimonial3:
            "Já tentei várias carteiras frias antes, mas esta se destaca. Seu design elegante e recursos avançados tornam a gestão dos meus ativos criptográficos muito fácil.",
          "John Doe": "John Doe",
          "Cryptocurrency Enthusiast": "Entusiasta de criptomoedas",
          "Jane Smith": "Jane Smith",
          "Crypto Security Advocate": "Advogado de Segurança de Criptomoedas",
          "Alex Johnson": "Alex Johnson",
          "Investment Analyst": "Analista de Investimentos",
          "Touchscreen Interface": "Interface de tela sensível ao toque",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "Experimente o gerenciamento de criptomoedas sem esforço com nossa interface intuitiva de tela sensível ao toque, projetada para navegação rápida e fácil pelos seus ativos digitais, possibilitando transações rápidas e gerenciamento seguro ao seu alcance.",
          "Bluetooth Connectivity": "Conectividade Bluetooth",
          "Wireless syncing with your devices for real-time updates.":
            "Sincronização sem fio com seus dispositivos para atualizações em tempo real.",
          "Airtag Tracking": "Rastreamento com Airtag",
          "Track your wallet easily with built-in Airtag.":
            "Rastreie sua carteira facilmente com o Airtag embutido.",
          "Secure Chip Technology": "Tecnologia de chip seguro",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "Equipado com um chip de segurança avançado, garantindo proteção robusta para seus valiosos ativos digitais contra acesso não autorizado e ameaças cibernéticas.",
          "Cross-Platform Support": "Suporte multiplataforma",
          "Works with various platforms for easy accessibility.":
            "Funciona com várias plataformas para fácil acessibilidade.",
          AboutUsDescription1:
            "Na LIKKIM, estamos dedicados a fornecer soluções de alta qualidade para gerenciar seus ativos em criptomoedas de forma segura.",
          AboutUsDescription2:
            "Nossa sede está em Zug, Suíça. Com uma equipe de profissionais experientes, nos esforçamos para inovar e nos adaptar ao mercado em constante mudança das moedas digitais.",
          AboutUsDescription3:
            "Suportamos mais de 10.000 criptomoedas, garantindo compatibilidade e flexibilidade para todos os usuários.",
          BlockchainNetworksSupported: "Redes Blockchain Suportadas",
          CryptocurrenciesSupported: "Suportando Mais de 10.000 Criptomoedas",
          Years: "Anos",
          ExpertiseInSecurity: "de experiência em segurança",
          FrequentlyAskedQuestions: "Perguntas frequentes",
          WhatIsColdWallet: "O que é uma Cold Wallet?",
          ColdWalletDescription:
            "Uma cold wallet é um tipo de carteira de criptomoeda que armazena seus ativos digitais offline, tornando-os menos vulneráveis a hacks e outras ameaças online. Diferente das hot wallets, que estão conectadas à internet e são convenientes para transações frequentes, as cold wallets são mais adequadas para armazenamento a longo prazo, pois oferecem segurança aprimorada.",
          HowToSetUpColdWallet: "Como configurar sua Cold Wallet?",
          ColdWalletSetupDescription:
            "Configurar sua cold wallet normalmente envolve inicializar o dispositivo em uma máquina segura e offline. Você precisará gerar e armazenar com segurança uma frase de recuperação, que é essencial para acessar seus ativos em caso de perda ou falha do dispositivo. Instruções detalhadas são fornecidas com a compra, garantindo um processo de configuração tranquilo.",
          CryptocurrenciesCompatibleWithColdWallet:
            "Quais criptomoedas são compatíveis com suas cold wallets?",
          CryptocurrenciesCompatibilityDescription:
            "Nossas cold wallets suportam uma ampla gama de criptomoedas, incluindo as principais como Bitcoin, Ethereum e Litecoin, bem como várias altcoins. Para uma lista completa de moedas compatíveis, visite nosso site ou consulte a documentação do produto.",
          HowToSecureColdWallet:
            "Como garantir que minha cold wallet esteja segura?",
          ColdWalletSecurityDescription:
            "Para garantir a segurança da sua cold wallet, mantenha sempre a frase de recuperação em um local seguro e privado, nunca compartilhe com ninguém e considere usar medidas de segurança adicionais, como bloqueios biométricos ou caixas seguras. Atualizar regularmente o firmware da carteira a partir do site oficial também ajuda a proteger contra vulnerabilidades.",
          ColdWalletNotWorking:
            "O que devo fazer se minha cold wallet não estiver funcionando ou se eu encontrar um erro?",
          ColdWalletErrorDescription:
            "Se você tiver problemas com sua cold wallet, consulte a seção de solução de problemas no guia do usuário. Para problemas mais específicos, entre em contato com nossa equipe de suporte por meio do nosso site. Certifique-se de ter as informações do seu dispositivo à mão para agilizar o processo de suporte.",
          Community: "Comunidade",
          "Create a New Wallet Guide": "Guia para Criar uma Nova Carteira",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "Passo 1: Ligue a carteira de hardware LIKKIM e selecione “Criar Carteira”.",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "Passo 2: Anote a frase de recuperação exibida na carteira de hardware LIKKIM e confirme.",
          "Import a Wallet Guide": "Guia para Importar uma Carteira",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "Passo 1: Ligue a carteira de hardware LIKKIM e selecione “Importar Carteira”.",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "Passo 2: Insira a frase de recuperação usando a carteira de hardware LIKKIM e confirme.",
          "Receive Cryptocurrency Guide": "Guia para Receber Criptomoedas",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "Passo 1: Abra o aplicativo LIKKIM, toque em 'Receber' para exibir o endereço e o código QR.",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "Passo 2: Verifique o endereço e o código QR na carteira de hardware LIKKIM.",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "Passo 3: Forneça ao remetente o endereço ou código QR verificado do LIKKIM.",
          "Send Cryptocurrency Guide": "Guia para Enviar Criptomoedas",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "Passo 1: Abra o aplicativo LIKKIM, toque em “Enviar” e insira o endereço do destinatário e o valor.",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "Passo 2: Verifique os detalhes da transação na carteira LIKKIM e pressione “Confirmar” para concluí-la.",
          Community: "Comunidade",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "Junte-se à nossa comunidade na sua plataforma favorita para se manter atualizado, fazer perguntas e conectar-se com outros usuários.",
        },
      },
      "pt-BR": {
        translation: {
          Product: "Produto",
          Feature: "Funcionalidade",
          "About Us": "Sobre nós",
          FAQ: "Perguntas frequentes",
          "Privacy Policy": "Política de privacidade",
          "Help Center": "Centro de ajuda",
          brandName: "LIKKIM", // 新增字段
          tagline: "Gerencie sua criptomoeda como nunca antes", // 新增字段
          description:
            "Comece sua jornada para a liberdade financeira com nosso aplicativo. Proteja seus ativos com nossa tecnologia avançada de carteira fria e gerencie suas finanças de maneira mais inteligente e eficaz. Seu bem-estar financeiro é nossa prioridade.", // 新增字段
          learnMore: "Saiba mais",
          "Lock Your Crypto, Securely":
            "Bloqueie sua criptomoeda com segurança",
          "Security Features": "Recursos de segurança",
          "Cross-Platform": "Multiplataforma",
          "Offline storage": "Armazenamento offline",
          "Against hacking": "Contra hackers",
          "Unbreachable Security": "Segurança inquebrável",
          Testimonials: "Depoimentos",
          Testimonial1:
            "Estou usando esta carteira fria há meses e estou impressionado com sua confiabilidade e recursos de segurança. Altamente recomendada!",
          Testimonial2:
            "Esta carteira fria superou minhas expectativas! Sua interface fácil de usar e segurança de ponta a tornam uma ferramenta indispensável para qualquer pessoa séria sobre criptomoedas.",
          Testimonial3:
            "Já tentei várias carteiras frias antes, mas esta se destaca. Seu design elegante e recursos avançados tornam a gestão dos meus ativos criptográficos muito fácil.",
          "John Doe": "John Doe",
          "Cryptocurrency Enthusiast": "Entusiasta de criptomoedas",
          "Jane Smith": "Jane Smith",
          "Crypto Security Advocate": "Advogado de Segurança de Criptomoedas",
          "Alex Johnson": "Alex Johnson",
          "Investment Analyst": "Analista de Investimentos",
          "Touchscreen Interface": "Interface de tela sensível ao toque",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "Experimente o gerenciamento de criptomoedas sem esforço com nossa interface intuitiva de tela sensível ao toque, projetada para navegação rápida e fácil pelos seus ativos digitais, possibilitando transações rápidas e gerenciamento seguro ao seu alcance.",
          "Bluetooth Connectivity": "Conectividade Bluetooth",
          "Wireless syncing with your devices for real-time updates.":
            "Sincronização sem fio com seus dispositivos para atualizações em tempo real.",
          "Airtag Tracking": "Rastreamento com Airtag",
          "Track your wallet easily with built-in Airtag.":
            "Rastreie sua carteira facilmente com o Airtag embutido.",
          "Secure Chip Technology": "Tecnologia de chip seguro",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "Equipado com um chip de segurança avançado, garantindo proteção robusta para seus valiosos ativos digitais contra acesso não autorizado e ameaças cibernéticas.",
          "Cross-Platform Support": "Suporte multiplataforma",
          "Works with various platforms for easy accessibility.":
            "Funciona com várias plataformas para fácil acessibilidade.",
          AboutUsDescription1:
            "Na LIKKIM, estamos dedicados a fornecer soluções de alta qualidade para gerenciar seus ativos em criptomoedas de forma segura.",
          AboutUsDescription2:
            "Nossa sede está em Zug, Suíça. Com uma equipe de profissionais experientes, nos esforçamos para inovar e nos adaptar ao mercado em constante mudança das moedas digitais.",
          AboutUsDescription3:
            "Suportamos mais de 10.000 criptomoedas, garantindo compatibilidade e flexibilidade para todos os usuários.",
          BlockchainNetworksSupported: "Redes Blockchain Suportadas",
          CryptocurrenciesSupported: "Suportando Mais de 10.000 Criptomoedas",
          Years: "Anos",
          ExpertiseInSecurity: "de experiência em segurança",
          FrequentlyAskedQuestions: "Perguntas frequentes",
          WhatIsColdWallet: "O que é uma Cold Wallet?",
          ColdWalletDescription:
            "Uma cold wallet é um tipo de carteira de criptomoeda que armazena seus ativos digitais offline, tornando-os menos vulneráveis a hacks e outras ameaças online. Diferente das hot wallets, que estão conectadas à internet e são convenientes para transações frequentes, as cold wallets são mais adequadas para armazenamento a longo prazo, pois oferecem segurança aprimorada.",
          HowToSetUpColdWallet: "Como configurar sua Cold Wallet?",
          ColdWalletSetupDescription:
            "Configurar sua cold wallet normalmente envolve inicializar o dispositivo em uma máquina segura e offline. Você precisará gerar e armazenar com segurança uma frase de recuperação, que é essencial para acessar seus ativos em caso de perda ou falha do dispositivo. Instruções detalhadas são fornecidas com a compra, garantindo um processo de configuração tranquilo.",
          CryptocurrenciesCompatibleWithColdWallet:
            "Quais criptomoedas são compatíveis com suas cold wallets?",
          CryptocurrenciesCompatibilityDescription:
            "Nossas cold wallets suportam uma ampla gama de criptomoedas, incluindo as principais como Bitcoin, Ethereum e Litecoin, bem como várias altcoins. Para uma lista completa de moedas compatíveis, visite nosso site ou consulte a documentação do produto.",
          HowToSecureColdWallet:
            "Como garantir que minha cold wallet esteja segura?",
          ColdWalletSecurityDescription:
            "Para garantir a segurança da sua cold wallet, mantenha sempre a frase de recuperação em um local seguro e privado, nunca compartilhe com ninguém e considere usar medidas de segurança adicionais, como bloqueios biométricos ou caixas seguras. Atualizar regularmente o firmware da carteira a partir do site oficial também ajuda a proteger contra vulnerabilidades.",
          ColdWalletNotWorking:
            "O que devo fazer se minha cold wallet não estiver funcionando ou se eu encontrar um erro?",
          ColdWalletErrorDescription:
            "Se você tiver problemas com sua cold wallet, consulte a seção de solução de problemas no guia do usuário. Para problemas mais específicos, entre em contato com nossa equipe de suporte por meio do nosso site. Certifique-se de ter as informações do seu dispositivo à mão para agilizar o processo de suporte.",
          Community: "Comunidade",
          "Create a New Wallet Guide": "Guia para Criar uma Nova Carteira",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "Passo 1: Ligue a carteira de hardware LIKKIM e selecione “Criar Carteira”.",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "Passo 2: Anote a frase de recuperação exibida na carteira de hardware LIKKIM e confirme.",
          "Import a Wallet Guide": "Guia para Importar uma Carteira",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "Passo 1: Ligue a carteira de hardware LIKKIM e selecione “Importar Carteira”.",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "Passo 2: Insira a frase de recuperação usando a carteira de hardware LIKKIM e confirme.",
          "Receive Cryptocurrency Guide": "Guia para Receber Criptomoedas",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "Passo 1: Abra o aplicativo LIKKIM, toque em 'Receber' para exibir o endereço e o código QR.",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "Passo 2: Verifique o endereço e o código QR na carteira de hardware LIKKIM.",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "Passo 3: Forneça ao remetente o endereço ou código QR verificado do LIKKIM.",
          "Send Cryptocurrency Guide": "Guia para Enviar Criptomoedas",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "Passo 1: Abra o aplicativo LIKKIM, toque em “Enviar” e insira o endereço do destinatário e o valor.",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "Passo 2: Verifique os detalhes da transação na carteira LIKKIM e pressione “Confirmar” para concluí-la.",
          Community: "Comunidade",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "Junte-se à nossa comunidade na sua plataforma favorita para ficar atualizado, fazer perguntas e conectar-se com outros usuários.",
        },
      },
      it: {
        translation: {
          Product: "Prodotto",
          Feature: "Caratteristica",
          "About Us": "Chi siamo",
          FAQ: "FAQ",
          "Privacy Policy": "Politica sulla privacy",
          "Help Center": "Centro assistenza",
          brandName: "LIKKIM", // 新增字段
          tagline: "Gestisci la tua criptovaluta come mai prima d'ora", // 新增字段
          description:
            "Inizia il tuo viaggio verso la libertà finanziaria con la nostra app. Proteggi i tuoi asset con la nostra tecnologia avanzata di portafoglio freddo e gestisci le tue finanze in modo più intelligente ed efficace. Il tuo benessere finanziario è la nostra priorità.", // 新增字段
          learnMore: "Scopri di più",
          "Lock Your Crypto, Securely":
            "Blocca la tua criptovaluta in modo sicuro",
          "Security Features": "Funzionalità di sicurezza",
          "Cross-Platform": "Multipiattaforma",
          "Offline storage": "Archiviazione offline",
          "Against hacking": "Contro gli hacker",
          "Unbreachable Security": "Sicurezza inespugnabile",
          Testimonials: "Testimonianze",
          Testimonial1:
            "Sto usando questo portafoglio freddo da mesi e sono impressionato dalla sua affidabilità e dalle funzionalità di sicurezza. Altamente consigliato!",
          Testimonial2:
            "Questo portafoglio freddo ha superato le mie aspettative! La sua interfaccia user-friendly e la sicurezza di alto livello lo rendono uno strumento imprescindibile per chiunque prenda sul serio le criptovalute.",
          Testimonial3:
            "Ho provato diversi portafogli freddi prima, ma questo si distingue. Il suo design elegante e le funzionalità avanzate rendono facile la gestione dei miei asset crypto.",
          "John Doe": "John Doe",
          "Cryptocurrency Enthusiast": "Appassionato di criptovalute",
          "Jane Smith": "Jane Smith",
          "Crypto Security Advocate":
            "Difensore della sicurezza delle criptovalute",
          "Alex Johnson": "Alex Johnson",
          "Investment Analyst": "Analista finanziario",
          "Touchscreen Interface": "Interfaccia a schermo tattile",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "Sperimenta la gestione delle criptovalute senza sforzo con la nostra interfaccia a schermo tattile intuitiva, progettata per una navigazione rapida e semplice dei tuoi asset digitali, consentendo transazioni rapide e una gestione sicura a portata di mano.",
          "Bluetooth Connectivity": "Connettività Bluetooth",
          "Wireless syncing with your devices for real-time updates.":
            "Sincronizzazione wireless con i tuoi dispositivi per aggiornamenti in tempo reale.",
          "Airtag Tracking": "Tracciamento Airtag",
          "Track your wallet easily with built-in Airtag.":
            "Traccia facilmente il tuo portafoglio con l'Airtag integrato.",
          "Secure Chip Technology": "Tecnologia del chip sicuro",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "Dotato di un chip di sicurezza avanzato, garantendo una protezione robusta per i tuoi preziosi asset digitali contro accessi non autorizzati e minacce informatiche.",
          "Cross-Platform Support": "Supporto multipiattaforma",
          "Works with various platforms for easy accessibility.":
            "Funziona con diverse piattaforme per una facile accessibilità.",
          AboutUsDescription1:
            "In LIKKIM, siamo dedicati a fornire soluzioni di alta qualità per la gestione sicura dei tuoi asset in criptovalute.",
          AboutUsDescription2:
            "La nostra sede si trova a Zug, in Svizzera. Con un team di professionisti esperti, ci impegniamo a innovare e ad adattarci al mercato in continua evoluzione delle valute digitali.",
          AboutUsDescription3:
            "Supportiamo oltre 10.000 criptovalute, garantendo compatibilità e flessibilità per tutti gli utenti.",
          BlockchainNetworksSupported: "Reti Blockchain supportate",
          CryptocurrenciesSupported: "Supporto per oltre 10.000 criptovalute",
          Years: "Anni",
          ExpertiseInSecurity: "di esperienza nella sicurezza",
          FrequentlyAskedQuestions: "Domande frequenti",
          WhatIsColdWallet: "Che cos'è un Cold Wallet?",
          ColdWalletDescription:
            "Un cold wallet è un tipo di portafoglio di criptovalute che memorizza i tuoi asset digitali offline, rendendoli meno vulnerabili agli attacchi e ad altre minacce online. A differenza dei portafogli caldi, che sono connessi a Internet e comodi per le transazioni frequenti, i cold wallet sono più adatti per lo stoccaggio a lungo termine poiché offrono una sicurezza maggiore.",
          HowToSetUpColdWallet: "Come configurare il tuo Cold Wallet?",
          ColdWalletSetupDescription:
            "Impostare il tuo cold wallet normalmente implica l'inizializzazione del dispositivo su una macchina sicura e offline. Dovrai generare e memorizzare in modo sicuro una frase di recupero, che è essenziale per accedere ai tuoi asset in caso di perdita o guasto del dispositivo. Le istruzioni dettagliate vengono fornite con l'acquisto, assicurando un processo di configurazione senza problemi.",
          CryptocurrenciesCompatibleWithColdWallet:
            "Quali criptovalute sono compatibili con i tuoi cold wallet?",
          CryptocurrenciesCompatibilityDescription:
            "I nostri cold wallet supportano una vasta gamma di criptovalute, comprese le principali come Bitcoin, Ethereum e Litecoin, oltre a numerose altcoin. Per un elenco completo delle criptovalute supportate, visita il nostro sito web o consulta la documentazione del prodotto.",
          HowToSecureColdWallet:
            "Come garantire che il mio cold wallet sia sicuro?",
          ColdWalletSecurityDescription:
            "Per proteggere il tuo cold wallet, conserva sempre la frase di recupero in un luogo sicuro e privato, non condividerla mai con nessuno e considera l'uso di misure di sicurezza aggiuntive come blocchi biometrici o contenitori sicuri. Aggiornare regolarmente il firmware del portafoglio dal sito ufficiale aiuta anche a proteggere contro le vulnerabilità.",
          ColdWalletNotWorking:
            "Cosa fare se il mio cold wallet non funziona o se incontro un errore?",
          ColdWalletErrorDescription:
            "Se riscontri problemi con il tuo cold wallet, consulta la sezione di risoluzione dei problemi nel nostro manuale utente. Per problemi più specifici, contatta il nostro team di supporto tramite il nostro sito web. Assicurati di avere a portata di mano le informazioni sul dispositivo per accelerare il processo di supporto.",
          Community: "Comunità",
          "Create a New Wallet Guide": "Guida per creare un nuovo portafoglio",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "Passo 1: Accendi il portafoglio hardware LIKKIM e seleziona “Crea portafoglio”.",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "Passo 2: Scrivi la frase di recupero mostrata sul portafoglio hardware LIKKIM e conferma.",
          "Import a Wallet Guide": "Guida per importare un portafoglio",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "Passo 1: Accendi il portafoglio hardware LIKKIM e seleziona “Importa portafoglio”.",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "Passo 2: Inserisci la frase di recupero utilizzando il portafoglio hardware LIKKIM e conferma.",
          "Receive Cryptocurrency Guide": "Guida per ricevere criptovalute",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "Passo 1: Apri l'app LIKKIM, tocca 'Ricevi' per visualizzare l'indirizzo e il codice QR.",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "Passo 2: Verifica l'indirizzo e il codice QR sul portafoglio hardware LIKKIM.",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "Passo 3: Fornisci al mittente l'indirizzo o il codice QR verificato dal LIKKIM.",
          "Send Cryptocurrency Guide": "Guida per inviare criptovalute",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "Passo 1: Apri l'app LIKKIM, tocca “Invia” e inserisci l'indirizzo del destinatario e l'importo.",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "Passo 2: Verifica i dettagli della transazione sul portafoglio LIKKIM e premi “Conferma” per completarla.",
          Community: "Comunità",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "Unisciti alla nostra comunità sulla tua piattaforma preferita per rimanere aggiornato, fare domande e connetterti con altri utenti.",
        },
      },
      de: {
        translation: {
          Product: "Produkt",
          Feature: "Merkmal",
          "About Us": "Über uns",
          FAQ: "Häufig gestellte Fragen",
          "Privacy Policy": "Datenschutzrichtlinie",
          "Help Center": "Hilfezentrum",
          brandName: "LIKKIM", // 新增字段
          tagline: "Verwalte deine Krypto-Währung wie nie zuvor", // 新增字段
          description:
            "Beginne deine Reise zur finanziellen Freiheit mit unserer App. Schütze deine Vermögenswerte mit unserer fortschrittlichen Cold-Wallet-Technologie und verwalte deine Finanzen intelligenter und effektiver. Dein finanzielles Wohlbefinden hat für uns oberste Priorität.", // 新增字段
          learnMore: "Mehr erfahren",
          "Lock Your Crypto, Securely":
            "Sperren Sie Ihre Krypto-Währung sicher",
          "Security Features": "Sicherheitsfunktionen",
          "Cross-Platform": "Plattformübergreifend",
          "Offline storage": "Offline-Speicher",
          "Against hacking": "Gegen Hacking",
          "Unbreachable Security": "Unüberwindbare Sicherheit",
          Testimonials: "Testimonials",
          Testimonial1:
            "Ich benutze diese Cold Wallet seit Monaten und bin von ihrer Zuverlässigkeit und den Sicherheitsfunktionen beeindruckt. Sehr zu empfehlen!",
          Testimonial2:
            "Dieses Cold Wallet hat meine Erwartungen übertroffen! Die benutzerfreundliche Oberfläche und die erstklassige Sicherheit machen es zu einem Muss für alle, die es mit Krypto ernst meinen.",
          Testimonial3:
            "Ich habe schon mehrere Cold Wallets ausprobiert, aber dieses sticht hervor. Das schlanke Design und die fortschrittlichen Funktionen machen die Verwaltung meiner Krypto-Vermögenswerte zu einem Kinderspiel.",
          "John Doe": "John Doe",
          "Cryptocurrency Enthusiast": "Kryptowährungs-Enthusiast",
          "Jane Smith": "Jane Smith",
          "Crypto Security Advocate": "Krypto-Sicherheitsbefürworter",
          "Alex Johnson": "Alex Johnson",
          "Investment Analyst": "Investment Analyst",
          "Touchscreen Interface": "Touchscreen-Oberfläche",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "Erleben Sie eine mühelose Verwaltung von Kryptowährungen mit unserer intuitiven Touchscreen-Oberfläche, die für eine schnelle und einfache Navigation durch Ihre digitalen Assets entwickelt wurde, was schnelle Transaktionen und sichere Verwaltung ermöglicht.",
          "Bluetooth Connectivity": "Bluetooth-Verbindung",
          "Wireless syncing with your devices for real-time updates.":
            "Drahtlose Synchronisierung mit Ihren Geräten für Echtzeit-Updates.",
          "Airtag Tracking": "Airtag-Tracking",
          "Track your wallet easily with built-in Airtag.":
            "Verfolgen Sie Ihre Brieftasche einfach mit dem eingebauten Airtag.",
          "Secure Chip Technology": "Sichere Chip-Technologie",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "Ausgestattet mit einem fortschrittlichen Sicherheitschip, der einen robusten Schutz für Ihre wertvollen digitalen Assets gegen unbefugten Zugriff und Cyber-Bedrohungen gewährleistet.",
          "Cross-Platform Support": "Plattformübergreifende Unterstützung",
          "Works with various platforms for easy accessibility.":
            "Funktioniert mit verschiedenen Plattformen für einfachen Zugriff.",
          AboutUsDescription1:
            "Bei LIKKIM widmen wir uns der Bereitstellung von hochwertigen Lösungen für die sichere Verwaltung Ihrer Kryptowährungswerte.",
          AboutUsDescription2:
            "Unser Hauptsitz befindet sich in Zug, Schweiz. Mit einem Team von erfahrenen Fachleuten bemühen wir uns, zu innovieren und uns an die sich ständig verändernde Landschaft des Marktes für digitale Währungen anzupassen.",
          AboutUsDescription3:
            "Wir unterstützen mehr als 10.000 Kryptowährungen und gewährleisten so Kompatibilität und Flexibilität für alle Benutzer.",
          BlockchainNetworksSupported: "Unterstützte Blockchain-Netzwerke",
          CryptocurrenciesSupported:
            "Unterstützung von über 10.000 Kryptowährungen",
          Years: "Jahre",
          ExpertiseInSecurity: "Jahre an Sicherheitsexpertise",
          FrequentlyAskedQuestions: "Häufig gestellte Fragen",
          WhatIsColdWallet: "Was ist ein Cold Wallet?",
          ColdWalletDescription:
            "Ein Cold Wallet ist eine Art von Kryptowährungs-Wallet, das deine digitalen Assets offline speichert, was es weniger anfällig für Hacks und andere Online-Bedrohungen macht. Im Gegensatz zu Hot Wallets, die mit dem Internet verbunden sind und für häufige Transaktionen praktisch sind, sind Cold Wallets besser für die langfristige Aufbewahrung geeignet, da sie eine verbesserte Sicherheit bieten.",
          HowToSetUpColdWallet: "Wie richte ich mein Cold Wallet ein?",
          ColdWalletSetupDescription:
            "Die Einrichtung deines Cold Wallets beinhaltet normalerweise das Initialisieren des Geräts auf einer sicheren, offline Maschine. Du musst eine Wiederherstellungsphrase generieren und sicher aufbewahren, die erforderlich ist, um auf deine Assets zuzugreifen, falls das Gerät verloren geht oder fehlschlägt. Detaillierte Anweisungen werden mit deinem Kauf bereitgestellt, um einen reibungslosen Einrichtungsprozess zu gewährleisten.",
          CryptocurrenciesCompatibleWithColdWallet:
            "Welche Kryptowährungen sind mit deinen Cold Wallets kompatibel?",
          CryptocurrenciesCompatibilityDescription:
            "Unsere Cold Wallets unterstützen eine Vielzahl von Kryptowährungen, darunter die großen wie Bitcoin, Ethereum und Litecoin sowie verschiedene Altcoins. Für eine vollständige Liste der unterstützten Währungen besuche bitte unsere Website oder konsultiere die Produktdokumentation.",
          HowToSecureColdWallet: "Wie kann ich mein Cold Wallet sichern?",
          ColdWalletSecurityDescription:
            "Um dein Cold Wallet zu sichern, bewahre die Wiederherstellungsphrase immer an einem sicheren und privaten Ort auf, teile sie niemals mit jemandem und ziehe zusätzliche Sicherheitsmaßnahmen wie biometrische Sperren oder sichere Gehäuse in Betracht. Das regelmäßige Aktualisieren der Wallet-Firmware von der offiziellen Website hilft auch, Schwachstellen zu schützen.",
          ColdWalletNotWorking:
            "Was soll ich tun, wenn mein Cold Wallet nicht funktioniert oder wenn ich einen Fehler habe?",
          ColdWalletErrorDescription:
            "Wenn du Probleme mit deinem Cold Wallet hast, schaue im Abschnitt zur Fehlerbehebung in unserem Benutzerhandbuch nach. Bei spezifischeren Problemen kontaktiere unser Support-Team über unsere Website. Stelle sicher, dass du deine Gerätedaten griffbereit hast, um den Support-Prozess zu beschleunigen.",
          Community: "Gemeinschaft",
          "Create a New Wallet Guide":
            "Anleitung zur Erstellung eines neuen Wallets",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "Schritt 1: Schalten Sie das LIKKIM-Hardware-Wallet ein und wählen Sie „Wallet erstellen“.",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "Schritt 2: Notieren Sie sich die Wiederherstellungsphrase, die auf dem LIKKIM-Hardware-Wallet angezeigt wird, und bestätigen Sie sie.",
          "Import a Wallet Guide": "Anleitung zum Importieren eines Wallets",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "Schritt 1: Schalten Sie das LIKKIM-Hardware-Wallet ein und wählen Sie „Wallet importieren“.",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "Schritt 2: Geben Sie die Wiederherstellungsphrase mit dem LIKKIM-Hardware-Wallet ein und bestätigen Sie.",
          "Receive Cryptocurrency Guide":
            "Anleitung zum Empfangen von Kryptowährungen",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "Schritt 1: Öffnen Sie die LIKKIM-App, tippen Sie auf „Empfangen“, um die Adresse und den QR-Code anzuzeigen.",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "Schritt 2: Überprüfen Sie die Adresse und den QR-Code auf dem LIKKIM-Hardware-Wallet.",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "Schritt 3: Geben Sie dem Absender die überprüfte Adresse oder den QR-Code vom LIKKIM.",
          "Send Cryptocurrency Guide":
            "Anleitung zum Senden von Kryptowährungen",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "Schritt 1: Öffnen Sie die LIKKIM-App, tippen Sie auf „Senden“ und geben Sie die Adresse und den Betrag des Empfängers ein.",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "Schritt 2: Überprüfen Sie die Transaktionsdetails auf dem LIKKIM-Wallet und drücken Sie „Bestätigen“, um sie abzuschließen.",
          Community: "Gemeinschaft",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "Treten Sie unserer Gemeinschaft auf Ihrer Lieblingsplattform bei, um auf dem Laufenden zu bleiben, Fragen zu stellen und sich mit anderen Benutzern zu verbinden.",
        },
      },
      hi: {
        translation: {
          Product: "उत्पाद",
          Feature: "विशेषता",
          "About Us": "हमारे बारे में",
          FAQ: "सामान्य प्रश्न",
          "Privacy Policy": "गोपनीयता नीति",
          "Help Center": "सहायता केंद्र",
          brandName: "LIKKIM", // 新增字段
          tagline:
            "अपनी क्रिप्टोकरेंसी को पहले से कहीं अधिक स्मार्ट तरीके से प्रबंधित करें", // 新增字段
          description:
            "हमारे ऐप के साथ वित्तीय स्वतंत्रता की ओर अपना सफर शुरू करें। हमारी उन्नत कोल्ड वॉलेट तकनीक के साथ अपने संपत्तियों को सुरक्षित रखें और अपनी वित्तीय स्थिति को और अधिक स्मार्ट और प्रभावी तरीके से प्रबंधित करें। आपकी वित्तीय भलाई हमारी प्राथमिकता है।", // 新增字段
          learnMore: "अधिक जानें",
          "Lock Your Crypto, Securely":
            "अपने क्रिप्टो को सुरक्षित रूप से लॉक करें",
          "Security Features": "सुरक्षा विशेषताएँ",
          "Cross-Platform": "क्रॉस-प्लेटफ़ॉर्म",
          "Offline storage": "ऑफ़लाइन संग्रहण",
          "Against hacking": "हैकिंग के खिलाफ",
          "Unbreachable Security": "अभेद्य सुरक्षा",
          Testimonials: "प्रमाणपत्र",
          Testimonial1:
            "मैं इस कोल्ड वॉलेट का महीनों से उपयोग कर रहा हूं, और इसकी विश्वसनीयता और सुरक्षा विशेषताओं से प्रभावित हूं। अत्यधिक सिफारिश की जाती है!",
          Testimonial2:
            "इस कोल्ड वॉलेट ने मेरी उम्मीदों से अधिक किया! इसका उपयोगकर्ता-मित्रवत इंटरफ़ेस और शीर्ष-स्तरीय सुरक्षा इसे किसी भी व्यक्ति के लिए एक अनिवार्य उपकरण बनाता है जो क्रिप्टो में गंभीर है।",
          Testimonial3:
            "मैंने पहले कई कोल्ड वॉलेट आज़माए हैं, लेकिन यह सबसे अलग है। इसकी सुंदर डिज़ाइन और उन्नत सुविधाएँ मेरे क्रिप्टो संपत्तियों को आसानी से प्रबंधित करने में मदद करती हैं।",
          "John Doe": "जॉन डो",
          "Cryptocurrency Enthusiast": "क्रिप्टोकरेंसी उत्साही",
          "Jane Smith": "जेन स्मिथ",
          "Crypto Security Advocate": "क्रिप्टो सुरक्षा समर्थक",
          "Alex Johnson": "एलेक्स जॉनसन",
          "Investment Analyst": "निवेश विश्लेषक",
          "Touchscreen Interface": "टचस्क्रीन इंटरफ़ेस",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "हमारे सहज टचस्क्रीन इंटरफ़ेस के साथ आसानी से क्रिप्टो प्रबंधन का अनुभव करें, जिसे आपके डिजिटल संपत्तियों के माध्यम से त्वरित और आसान नेविगेशन के लिए डिज़ाइन किया गया है, जो त्वरित लेन-देन और सुरक्षित प्रबंधन को सक्षम बनाता है।",
          "Bluetooth Connectivity": "ब्लूटूथ कनेक्टिविटी",
          "Wireless syncing with your devices for real-time updates.":
            "अपने उपकरणों के साथ वायरलेस सिंकिंग के लिए वास्तविक समय अपडेट।",
          "Airtag Tracking": "Airtag ट्रैकिंग",
          "Track your wallet easily with built-in Airtag.":
            "निर्मित Airtag के साथ अपने बटुए को आसानी से ट्रैक करें।",
          "Secure Chip Technology": "सुरक्षित चिप प्रौद्योगिकी",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "एक उन्नत सुरक्षा चिप से लैस, जो आपके मूल्यवान डिजिटल संपत्तियों को अनधिकृत पहुंच और साइबर खतरों से मजबूत सुरक्षा सुनिश्चित करता है।",
          "Cross-Platform Support": "क्रॉस-प्लेटफॉर्म समर्थन",
          "Works with various platforms for easy accessibility.":
            "विभिन्न प्लेटफ़ॉर्म्स के साथ काम करता है ताकि आसानी से पहुँचा जा सके।",
          AboutUsDescription1:
            "LIKKIM में, हम आपकी क्रिप्टोकरेंसी संपत्तियों को सुरक्षित रूप से प्रबंधित करने के लिए उच्च गुणवत्ता वाले समाधान प्रदान करने के लिए समर्पित हैं।",
          AboutUsDescription2:
            "हमारा मुख्यालय स्विट्जरलैंड के जुग में है। अनुभवी पेशेवरों की एक टीम के साथ, हम डिजिटल मुद्रा बाजार के लगातार बदलते परिदृश्य के अनुकूल होने और नवाचार करने की कोशिश करते हैं।",
          AboutUsDescription3:
            "हम 10,000 से अधिक क्रिप्टोकरेंसी का समर्थन करते हैं, जो सभी उपयोगकर्ताओं के लिए संगतता और लचीलापन सुनिश्चित करता है।",
          BlockchainNetworksSupported: "समर्थित ब्लॉकचेन नेटवर्क",
          CryptocurrenciesSupported: "10,000 से अधिक क्रिप्टोकरेंसी का समर्थन",
          Years: "साल",
          ExpertiseInSecurity: "सुरक्षा में विशेषज्ञता",
          FrequentlyAskedQuestions: "सामान्य प्रश्न",
          WhatIsColdWallet: "कोल्ड वॉलेट क्या है?",
          ColdWalletDescription:
            "कोल्ड वॉलेट एक प्रकार का क्रिप्टोकरेंसी वॉलेट है जो आपके डिजिटल एसेट्स को ऑफलाइन स्टोर करता है, जिससे यह हैकिंग और अन्य ऑनलाइन खतरों से कम संवेदनशील होता है। हॉट वॉलेट के विपरीत, जो इंटरनेट से जुड़े होते हैं और बार-बार लेन-देन के लिए सुविधाजनक होते हैं, कोल्ड वॉलेट लंबी अवधि के लिए स्टोर करने के लिए बेहतर होते हैं क्योंकि ये सुरक्षा को बढ़ाते हैं।",
          HowToSetUpColdWallet: "अपना कोल्ड वॉलेट कैसे सेट करें?",
          ColdWalletSetupDescription:
            "अपने कोल्ड वॉलेट को सेट करने में आमतौर पर एक सुरक्षित और ऑफलाइन मशीन पर डिवाइस को इनिशियलाइज़ करना शामिल होता है। आपको एक रिकवरी फ़्रेज़ जनरेट करने और उसे सुरक्षित रूप से स्टोर करने की आवश्यकता होगी, जो आपके डिवाइस के खो जाने या असफल होने की स्थिति में आपके एसेट्स तक पहुँचने के लिए आवश्यक है। विस्तृत निर्देश आपके खरीदारी के साथ प्रदान किए जाते हैं, ताकि सेटअप प्रक्रिया सहज हो।",
          CryptocurrenciesCompatibleWithColdWallet:
            "आपके कोल्ड वॉलेट के साथ कौन सी क्रिप्टोकरेंसी संगत हैं?",
          CryptocurrenciesCompatibilityDescription:
            "हमारे कोल्ड वॉलेट एक विस्तृत श्रृंखला की क्रिप्टोकरेंसी का समर्थन करते हैं, जिसमें प्रमुख जैसे Bitcoin, Ethereum और Litecoin, साथ ही विभिन्न अल्टकॉइन शामिल हैं। समर्थित मुद्राओं की पूरी सूची के लिए कृपया हमारी वेबसाइट पर जाएं या उत्पाद दस्तावेज़ों को देखें।",
          HowToSecureColdWallet:
            "मैं अपने कोल्ड वॉलेट को सुरक्षित कैसे रख सकता हूं?",
          ColdWalletSecurityDescription:
            "अपने कोल्ड वॉलेट को सुरक्षित करने के लिए, हमेशा रिकवरी फ़्रेज़ को एक सुरक्षित और निजी स्थान पर रखें, इसे किसी के साथ साझा न करें और अतिरिक्त सुरक्षा उपायों जैसे बायोमेट्रिक लॉक या सुरक्षित कैबिनेट का उपयोग करने पर विचार करें। आधिकारिक वेबसाइट से नियमित रूप से वॉलेट का फ़र्मवेयर अपडेट करना भी कमजोरियों से सुरक्षा में मदद करता है।",
          ColdWalletNotWorking:
            "अगर मेरा कोल्ड वॉलेट काम नहीं कर रहा है या अगर मुझे कोई गलती हो रही है तो क्या करना चाहिए?",
          ColdWalletErrorDescription:
            "यदि आपके कोल्ड वॉलेट में कोई समस्या है, तो हमारे उपयोगकर्ता गाइड के समस्या निवारण अनुभाग को देखें। अधिक विशिष्ट समस्याओं के लिए, हमारी वेबसाइट के माध्यम से हमारे समर्थन टीम से संपर्क करें। समर्थन प्रक्रिया को तेज करने के लिए सुनिश्चित करें कि आपके पास आपके डिवाइस की जानकारी उपलब्ध हो।",
          Community: "समुदाय",
          "Create a New Wallet Guide": "नया वॉलेट बनाने की गाइड",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "चरण 1: LIKKIM हार्डवेयर वॉलेट चालू करें और “Create Wallet” चुनें।",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "चरण 2: LIKKIM हार्डवेयर वॉलेट पर दिखाई गई रिकवरी फ्रेज लिखें और पुष्टि करें।",
          "Import a Wallet Guide": "वॉलेट आयात करने की गाइड",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "चरण 1: LIKKIM हार्डवेयर वॉलेट चालू करें और “Import Wallet” चुनें।",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "चरण 2: LIKKIM हार्डवेयर वॉलेट का उपयोग करके रिकवरी फ्रेज दर्ज करें और पुष्टि करें।",
          "Receive Cryptocurrency Guide": "क्रिप्टोकरेंसी प्राप्त करने की गाइड",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "चरण 1: LIKKIM ऐप खोलें, 'Receive' टैप करें ताकि पता और QR कोड दिख सके।",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "चरण 2: LIKKIM हार्डवेयर वॉलेट पर पता और QR कोड की पुष्टि करें।",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "चरण 3: LIKKIM से सत्यापित पता या QR कोड प्रेषक को दें।",
          "Send Cryptocurrency Guide": "क्रिप्टोकरेंसी भेजने की गाइड",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "चरण 1: LIKKIM ऐप खोलें, “Send” टैप करें, और प्राप्तकर्ता का पता और राशि दर्ज करें।",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "चरण 2: LIKKIM वॉलेट पर लेन-देन का विवरण सत्यापित करें और इसे पूरा करने के लिए “Confirm” दबाएं।",
          Community: "समुदाय",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "अपडेट रहने, प्रश्न पूछने और अन्य उपयोगकर्ताओं से जुड़ने के लिए अपने पसंदीदा प्लेटफॉर्म पर हमारे समुदाय में शामिल हों।",
        },
      },
      mn: {
        translation: {
          Product: "Бүтээгдэхүүн",
          Feature: "Онцлог",
          "About Us": "Бидний тухай",
          FAQ: "Түгээмэл асуултууд",
          "Privacy Policy": "Нууцлалын бодлого",
          "Help Center": "Тусламжийн төв",
          brandName: "LIKKIM", // 新增字段
          tagline: "Өмнө нь хэзээ ч байгаагүй криптовалютыг удирдах", // 新增字段
          description:
            "Манай апп-тай санхүүгийн эрх чөлөөнд хөтлөх аялалаа эхлүүлээрэй. Манай дэвшилтэт хөлдөгчний технологиор хөрөнгөө хамгаалаарай, мөн санхүүгээ илүү ухаалаг, үр ашигтайгаар удирдаарай. Таны санхүүгийн сайн сайхан байдал бидний тэргүүлэх priority юм.", // 新增字段
          learnMore: "Дэлгэрэнгүй",
          "Lock Your Crypto, Securely": "Таны криптог аюулгүй байдлаар хаах",
          "Security Features": "Аюулгүй байдлын онцлог",
          "Cross-Platform": "Хөндлөн платформ",
          "Offline storage": "Офлайн хадгалалт",
          "Against hacking": "Хакеруудаас хамгаалах",
          "Unbreachable Security": "Эвдрэшгүй аюулгүй байдал",
          Testimonials: "Тэмдэглэл",
          Testimonial1:
            "Би энэ хөлдөгчийг хэдэн сар хэрэглэж байна, түүний найдвартай байдал болон аюулгүй байдлын функцууд надад ихээхэн сэтгэгдэл төрүүлсэн. Маш өндөр санал болгож байна!",
          Testimonial2:
            "Энэ хөлдөгч миний хүлээлтээс давсан! Хэрэглэхэд энгийн интерфейс болон шилдэг аюулгүй байдал нь крипто валютыг жинхэнэ сонирхогчдын хувьд зайлшгүй хэрэгсэл болгодог.",
          Testimonial3:
            "Би өмнө нь хэд хэдэн хөлдөгчийг ашиглаж үзсэн боловч энэ нь ялгардаг. Түүний гоёмсог дизайн болон дэвшилтэт функцууд нь крипто хөрөнгөө удирдахыг хялбар болгосон.",
          "John Doe": "Жон Доу",
          "Cryptocurrency Enthusiast": "Криптографийн сонирхогч",
          "Jane Smith": "Жейн Смит",
          "Crypto Security Advocate": "Крипто аюулгүй байдлын өмгөөлөгч",
          "Alex Johnson": "Алекс Жонсон",
          "Investment Analyst": "Хөрөнгө оруулалтын шинжээч",
          "Touchscreen Interface": "Тусгай дэлгэцтэй интерфейс",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "Манай интуитив интерфейсийн тусламжтайгаар крипто хөрөнгийг удирдах нь амархан бөгөөд таны дижитал активуудаар хурдан, хялбар навигаци хийх боломжийг олгодог. Энэхүү интерфейс нь хурдан гүйлгээ болон аюулгүй менежментийг боломжтой болгодог.",
          "Bluetooth Connectivity": "Bluetooth холболт",
          "Wireless syncing with your devices for real-time updates.":
            "Таны төхөөрөмжүүдтэй утасгүй синхрончлол хийж, бодит цагийн шинэчлэлтийг авах.",
          "Airtag Tracking": "Airtag байршил тогтоох",
          "Track your wallet easily with built-in Airtag.":
            "Хялбархан Airtag ашиглан таны түрийвчийг хайж олоорой.",
          "Secure Chip Technology": "Аюулгүй чип технологи",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "Дэвшилтэт аюулгүй байдлын чипээр тоноглогдсон бөгөөд таны үнэ цэнэтэй дижитал активуудыг зөвшөөрөлгүй нэвтрэх болон кибер аюулуудын эсрэг хүчтэй хамгаалалттай.",
          "Cross-Platform Support": "Хөндлөн платформ дэмжлэг",
          "Works with various platforms for easy accessibility.":
            "Төрөл бүрийн платформтой ажиллахад хялбар хандах боломжийг олгодог.",
          AboutUsDescription1:
            "LIKKIM-д бид таны криптовалютын активуудыг аюулгүйгээр удирдах өндөр чанартай шийдлүүдийг санал болгож байна.",
          AboutUsDescription2:
            "Манай төв оффис Швейцарийн Цуг хотод байрладаг. Туршлагатай мэргэжилтнүүдийн багтайгаар бид дижитал валютын зах зээлийн байнга өөрчлөгдөж буй орчинд инноваци хийх, зохицохыг зорьдог.",
          AboutUsDescription3:
            "Бид 10,000 гаруй криптовалютыг дэмжиж, бүх хэрэглэгчдэд нийцтэй, уян хатан байдлыг хангадаг.",
          BlockchainNetworksSupported: "Дэмжигдсэн блокчэйн сүлжээнүүд",
          CryptocurrenciesSupported: "10,000 гаруй криптовалютыг дэмжих",
          Years: "Жил",
          ExpertiseInSecurity: "Аюулгүй байдлын мэргэжилтнүүд",
          FrequentlyAskedQuestions: "Түгээмэл асуултууд",
          WhatIsColdWallet: "Холын халаас гэж юу вэ?",
          ColdWalletDescription:
            "Холын халаас нь таны криптовалютын активуудыг интернет холболтоос гадуур хадгалдаг криптовалютын халаас бөгөөд энэ нь хакердах болон бусад онлайн аюулуудыг багасгадаг. Интернетэд холбогдсон, байнга гүйлгээ хийхэд тохиромжтой халуун халааснуудаас ялгаатай нь, холын халаас нь урт хугацааны хадгалалтанд илүү тохиромжтой бөгөөд өндөр хамгаалалттай байдаг.",
          HowToSetUpColdWallet: "Холын халаасыг хэрхэн тохируулах вэ?",
          ColdWalletSetupDescription:
            "Холын халаасыг тохируулах нь ерөнхийдөө аюулгүй, интернетгүй төхөөрөмж дээр угсралтыг хийх явцтай байдаг. Танд засварлагдсан төхөөрөмжийг алдаж эсвэл эвдрэх үед таны активуудыг олоход шаардлагатай сэргээх үг үүсгэж, аюулгүй хадгалах хэрэгтэй. Дэлгэрэнгүй зааврыг худалдан авалттайгаа хамт өгдөг бөгөөд тохиргооны процессыг гүйцэтгэхэд амар байдаг.",
          CryptocurrenciesCompatibleWithColdWallet:
            "Таны холын халаасуудтай нийцэх криптовалютууд ямар вэ?",
          CryptocurrenciesCompatibilityDescription:
            "Манай холын халаасууд нь Bitcoin, Ethereum, Litecoin зэрэг гол криптовалютуудыг болон олон төрлийн альткойныг дэмждэг. Дэмжигдсэн криптовалютуудын бүрэн жагсаалтыг үзэхийн тулд манай вэбсайтыг үзээрэй эсвэл бүтээгдэхүүний гарын авлагад хандана уу.",
          HowToSecureColdWallet: "Холын халаасыг хэрхэн аюулгүй байлгах вэ?",
          ColdWalletSecurityDescription:
            "Холын халаасыг аюулгүй байлгахын тулд сэргээх үгсийг аюулгүй, хувийн байршилд хадгалах, хэзээ ч хэнд ч хуваалцахгүй байх, биометрийн хаалга эсвэл аюулгүй хадгаламжийн хайрцаг зэргийг ашиглах талаар бодож үзэх хэрэгтэй. Албан ёсны вэбсайтаас халаасны програм хангамжийг тогтмол шинэчлэх нь ч аюулгүй байдлыг нэмэгдүүлэхэд тусална.",
          ColdWalletNotWorking:
            "Хэрэв миний холын халаас ажиллахгүй байгаа эсвэл алдаа гарч байгаа бол юу хийх вэ?",
          ColdWalletErrorDescription:
            "Хэрэв таны холын халаасанд асуудал гарах бол хэрэглэгчийн гарын авлагын асуудал шийдвэрлэх хэсгийг шалгана уу. Илүү нарийн асуудал гарвал манай дэмжлэгийн багтай холбоо барина уу. Дэмжлэгийн үйл явцыг хурдсгахын тулд таны төхөөрөмжийн мэдээллийг бэлэн байлгах хэрэгтэй.",
          Community: "Олон нийтийн",
          "Create a New Wallet Guide": "Шинэ түрийвч үүсгэх заавар",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "Алхам 1: LIKKIM техник хангамжийн түрийвчийг асаагаад “Түрийвч үүсгэх”-ийг сонгоно уу.",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "Алхам 2: LIKKIM техник хангамжийн түрийвчин дээр гарч ирсэн сэргээх өгүүлбэрийг тэмдэглэж, баталгаажуулна уу.",
          "Import a Wallet Guide": "Түрийвч импортлох заавар",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "Алхам 1: LIKKIM техник хангамжийн түрийвчийг асаагаад “Түрийвч импортлох”-ыг сонгоно уу.",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "Алхам 2: LIKKIM техник хангамжийн түрийвчийг ашиглан сэргээх өгүүлбэрийг оруулаад баталгаажуулна уу.",
          "Receive Cryptocurrency Guide": "Криптовалют хүлээн авах заавар",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "Алхам 1: LIKKIM апп-аа нээж, “Хүлээн авах”-ыг товшоод хаяг болон QR кодыг үзүүлнэ үү.",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "Алхам 2: LIKKIM техник хангамжийн түрийвчин дээр хаяг болон QR кодыг баталгаажуулна уу.",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "Алхам 3: LIKKIM-аас баталгаажуулсан хаяг эсвэл QR кодыг илгээгчид өгнө үү.",
          "Send Cryptocurrency Guide": "Криптовалют илгээх заавар",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "Алхам 1: LIKKIM апп-аа нээж, “Илгээх”-ийг товшоод, хүлээн авагчийн хаяг болон дүнг оруулна уу.",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "Алхам 2: LIKKIM түрийвч дээрх гүйлгээний дэлгэрэнгүйг баталгаажуулж, “Баталгаажуулах”-ыг дарна уу.",
          Community: "Хамт олон",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "Шинэ мэдээллүүдийг авах, асуулт асуух болон бусад хэрэглэгчидтэй холбогдохын тулд дуртай платформ дээрээ бидэнтэй нэгдээрэй.",
        },
      },
      th: {
        translation: {
          Product: "ผลิตภัณฑ์",
          Feature: "คุณสมบัติ",
          "About Us": "เกี่ยวกับเรา",
          FAQ: "คำถามที่พบบ่อย",
          "Privacy Policy": "นโยบายความเป็นส่วนตัว",
          "Help Center": "ศูนย์ช่วยเหลือ",
          brandName: "LIKKIM", // 新增字段
          tagline: "จัดการคริปโตของคุณในแบบที่ไม่เคยมีมาก่อน", // 新增字段
          description:
            "เริ่มต้นการเดินทางสู่เสรีภาพทางการเงินของคุณด้วยแอพของเรา ปกป้องสินทรัพย์ของคุณด้วยเทคโนโลยีกระเป๋าเย็นขั้นสูงและจัดการการเงินของคุณอย่างชาญฉลาดและมีประสิทธิภาพ ความเป็นอยู่ทางการเงินของคุณคือสิ่งที่เราคำนึงถึงมากที่สุด", // 新增字段
          learnMore: "เรียนรู้เพิ่มเติม",
          "Lock Your Crypto, Securely": "ล็อคคริปโตของคุณอย่างปลอดภัย",
          "Security Features": "คุณสมบัติด้านความปลอดภัย",
          "Cross-Platform": "ข้ามแพลตฟอร์ม",
          "Offline storage": "การจัดเก็บออฟไลน์",
          "Against hacking": "ป้องกันการแฮ็ก",
          "Unbreachable Security": "ความปลอดภัยที่ไม่สามารถเจาะได้",
          Testimonials: "คำรับรอง",
          Testimonial1:
            "ฉันใช้กระเป๋าเย็นนี้มาหลายเดือนแล้ว และประทับใจกับความน่าเชื่อถือและคุณสมบัติด้านความปลอดภัยของมัน แนะนำเป็นอย่างยิ่ง!",
          Testimonial2:
            "กระเป๋าเย็นนี้เกินความคาดหมายของฉัน! อินเทอร์เฟซที่ใช้งานง่ายและความปลอดภัยระดับสูงทำให้มันเป็นเครื่องมือที่ขาดไม่ได้สำหรับทุกคนที่จริงจังกับการลงทุนในคริปโต",
          Testimonial3:
            "ฉันเคยลองใช้กระเป๋าเย็นหลายใบ แต่ใบนี้โดดเด่น การออกแบบที่ดูดีและฟีเจอร์ที่ทันสมัยทำให้การจัดการสินทรัพย์คริปโตของฉันเป็นเรื่องง่าย",
          "John Doe": "จอห์น โด",
          "Cryptocurrency Enthusiast": "ผู้หลงใหลในคริปโต",
          "Jane Smith": "เจน สมิธ",
          "Crypto Security Advocate": "ผู้สนับสนุนความปลอดภัยในคริปโต",
          "Alex Johnson": "อเล็กซ์ จอห์นสัน",
          "Investment Analyst": "นักวิเคราะห์การลงทุน",
          "Touchscreen Interface": "อินเตอร์เฟซหน้าจอสัมผัส",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "สัมผัสประสบการณ์การจัดการคริปโตที่ไร้รอยต่อด้วยอินเตอร์เฟซหน้าจอสัมผัสที่ใช้งานง่าย ออกแบบมาเพื่อการนำทางอย่างรวดเร็วและง่ายดายผ่านสินทรัพย์ดิจิทัลของคุณ ทำให้สามารถทำธุรกรรมได้รวดเร็วและจัดการได้อย่างปลอดภัยด้วยปลายนิ้วของคุณ",
          "Bluetooth Connectivity": "การเชื่อมต่อ Bluetooth",
          "Wireless syncing with your devices for real-time updates.":
            "การซิงค์แบบไร้สายกับอุปกรณ์ของคุณเพื่อการอัปเดตแบบเรียลไทม์",
          "Airtag Tracking": "การติดตาม Airtag",
          "Track your wallet easily with built-in Airtag.":
            "ติดตามกระเป๋าของคุณได้ง่ายๆ ด้วย Airtag ที่ติดตั้งมาแล้ว",
          "Secure Chip Technology": "เทคโนโลยีชิปที่ปลอดภัย",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "มาพร้อมกับชิปความปลอดภัยขั้นสูงที่รับประกันการปกป้องที่แข็งแกร่งสำหรับสินทรัพย์ดิจิทัลที่มีค่า จากการเข้าถึงโดยไม่ได้รับอนุญาตและภัยคุกคามทางไซเบอร์",
          "Cross-Platform Support": "รองรับหลายแพลตฟอร์ม",
          "Works with various platforms for easy accessibility.":
            "ทำงานร่วมกับแพลตฟอร์มต่างๆ เพื่อให้เข้าถึงได้ง่าย",
          AboutUsDescription1:
            "ที่ LIKKIM เราทุ่มเทในการให้บริการโซลูชันคุณภาพสูงสำหรับการจัดการสินทรัพย์คริปโตของคุณอย่างปลอดภัย",
          AboutUsDescription2:
            "สำนักงานใหญ่ของเราตั้งอยู่ที่เมืองซุก ประเทศสวิตเซอร์แลนด์ ด้วยทีมงานมืออาชีพที่มีประสบการณ์ เรามุ่งมั่นที่จะสร้างนวัตกรรมและปรับตัวให้เข้ากับตลาดสกุลเงินดิจิทัลที่มีการเปลี่ยนแปลงอย่างต่อเนื่อง",
          AboutUsDescription3:
            "เรารองรับมากกว่า 10,000 สกุลเงินดิจิทัล เพื่อให้แน่ใจว่ามีความเข้ากันได้และความยืดหยุ่นสำหรับผู้ใช้ทุกคน",
          BlockchainNetworksSupported: "เครือข่ายบล็อกเชนที่รองรับ",
          CryptocurrenciesSupported: "รองรับมากกว่า 10,000 สกุลเงินดิจิทัล",
          Years: "ปี",
          ExpertiseInSecurity: "ประสบการณ์ด้านความปลอดภัย",
          FrequentlyAskedQuestions: "คำถามที่พบบ่อย",
          WhatIsColdWallet: "Cold Wallet คืออะไร?",
          ColdWalletDescription:
            "Cold Wallet คือกระเป๋าเงินคริปโตที่เก็บสินทรัพย์ดิจิทัลของคุณแบบออฟไลน์ ทำให้มีความเสี่ยงต่อการถูกแฮ็คและภัยคุกคามออนไลน์อื่นๆ น้อยลง ต่างจาก Hot Wallet ที่เชื่อมต่อกับอินเทอร์เน็ตและสะดวกในการทำธุรกรรมบ่อยๆ Cold Wallet เหมาะสำหรับการเก็บรักษาระยะยาวเพราะให้ความปลอดภัยที่ดีกว่า",
          HowToSetUpColdWallet: "การตั้งค่า Cold Wallet ของคุณทำอย่างไร?",
          ColdWalletSetupDescription:
            "การตั้งค่า Cold Wallet ของคุณโดยปกติจะเกี่ยวข้องกับการเริ่มต้นใช้งานอุปกรณ์บนเครื่องที่ปลอดภัยและไม่เชื่อมต่อกับอินเทอร์เน็ต คุณจะต้องสร้างและเก็บรักษาคำฟื้นฟู (recovery phrase) ไว้อย่างปลอดภัย ซึ่งเป็นสิ่งสำคัญในการเข้าถึงสินทรัพย์ของคุณในกรณีที่อุปกรณ์สูญหายหรือเสียหาย คำแนะนำโดยละเอียดจะมีให้เมื่อคุณทำการซื้อเพื่อให้กระบวนการตั้งค่าราบรื่น",
          CryptocurrenciesCompatibleWithColdWallet:
            "สกุลเงินดิจิทัลใดที่รองรับกับ Cold Wallet ของคุณ?",
          CryptocurrenciesCompatibilityDescription:
            "Cold Wallet ของเรารองรับสกุลเงินดิจิทัลหลายประเภท รวมถึง Bitcoin, Ethereum, Litecoin และเหรียญอื่นๆ สำหรับรายการสกุลเงินทั้งหมดที่รองรับ กรุณาเยี่ยมชมเว็บไซต์ของเราหรืออ้างอิงจากเอกสารผลิตภัณฑ์",
          HowToSecureColdWallet:
            "ฉันจะทำให้ Cold Wallet ของฉันปลอดภัยได้อย่างไร?",
          ColdWalletSecurityDescription:
            "เพื่อให้ Cold Wallet ของคุณปลอดภัย ให้เก็บคำฟื้นฟูในที่ปลอดภัยและส่วนตัว อย่าแบ่งปันกับใคร และพิจารณาใช้มาตรการรักษาความปลอดภัยเพิ่มเติม เช่น การล็อกด้วยลายนิ้วมือหรือการใช้กล่องปลอดภัย การอัปเดตเฟิร์มแวร์ของกระเป๋าเงินจากเว็บไซต์ทางการอย่างสม่ำเสมอจะช่วยป้องกันช่องโหว่ได้เช่นกัน",
          ColdWalletNotWorking:
            "หาก Cold Wallet ของฉันไม่ทำงานหรือพบข้อผิดพลาดควรทำอย่างไร?",
          ColdWalletErrorDescription:
            "หากคุณประสบปัญหากับ Cold Wallet ของคุณ กรุณาดูส่วนแก้ไขปัญหาในคู่มือผู้ใช้ สำหรับปัญหาที่เฉพาะเจาะจงมากขึ้น ติดต่อทีมสนับสนุนของเราผ่านเว็บไซต์ของเรา อย่าลืมเตรียมข้อมูลอุปกรณ์ของคุณเพื่อให้กระบวนการสนับสนุนรวดเร็วยิ่งขึ้น",
          Community: "ชุมชน",
          "Create a New Wallet Guide": "คู่มือการสร้างกระเป๋าสตางค์ใหม่",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "ขั้นตอนที่ 1: เปิดกระเป๋าเงินฮาร์ดแวร์ LIKKIM และเลือก “สร้างกระเป๋าเงิน”",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "ขั้นตอนที่ 2: จดวลีการกู้คืนที่แสดงบนกระเป๋าเงินฮาร์ดแวร์ LIKKIM และยืนยัน",
          "Import a Wallet Guide": "คู่มือการนำเข้ากระเป๋าสตางค์",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "ขั้นตอนที่ 1: เปิดกระเป๋าเงินฮาร์ดแวร์ LIKKIM และเลือก “นำเข้ากระเป๋าเงิน”",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "ขั้นตอนที่ 2: ป้อนวลีการกู้คืนโดยใช้กระเป๋าเงินฮาร์ดแวร์ LIKKIM และยืนยัน",
          "Receive Cryptocurrency Guide": "คู่มือการรับสกุลเงินดิจิทัล",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "ขั้นตอนที่ 1: เปิดแอป LIKKIM แล้วแตะ 'รับ' เพื่อแสดงที่อยู่และรหัส QR",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "ขั้นตอนที่ 2: ยืนยันที่อยู่และรหัส QR บนกระเป๋าเงินฮาร์ดแวร์ LIKKIM",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "ขั้นตอนที่ 3: ส่งที่อยู่หรือรหัส QR ที่ยืนยันแล้วจาก LIKKIM ให้กับผู้ส่ง",
          "Send Cryptocurrency Guide": "คู่มือการส่งสกุลเงินดิจิทัล",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "ขั้นตอนที่ 1: เปิดแอป LIKKIM แตะ “ส่ง” และป้อนที่อยู่ของผู้รับและจำนวน",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "ขั้นตอนที่ 2: ยืนยันรายละเอียดธุรกรรมบนกระเป๋าเงิน LIKKIM และกด “ยืนยัน” เพื่อเสร็จสิ้น",
          Community: "ชุมชน",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "เข้าร่วมชุมชนของเราบนแพลตฟอร์มที่คุณชื่นชอบเพื่ออัปเดตข้อมูล, ถามคำถาม และเชื่อมต่อกับผู้ใช้รายอื่น.",
        },
      },
      uk: {
        translation: {
          Product: "Продукт",
          Feature: "Особливості",
          "About Us": "Про нас",
          FAQ: "Часто задавані питання",
          "Privacy Policy": "Політика конфіденційності",
          "Help Center": "Центр допомоги",
          brandName: "LIKKIM", // 新增字段
          tagline: "Управляйте своїми криптовалютами як ніколи раніше", // 新增字段
          description:
            "Розпочніть свою подорож до фінансової свободи з нашим додатком. Захищайте свої активи за допомогою нашої передової технології холодних гаманців і керуйте своїми фінансами розумно та ефективно. Ваше фінансове благополуччя є нашою головною метою.", // 新增字段
          learnMore: "Дізнатися більше",
          "Lock Your Crypto, Securely": "Надійно заблокуйте вашу криптовалюту",
          "Security Features": "Функції безпеки",
          "Cross-Platform": "Кросплатформеність",
          "Offline storage": "Офлайн зберігання",
          "Against hacking": "Проти злому",
          "Unbreachable Security": "Непроникна безпека",
          Testimonials: "Відгуки",
          Testimonial1:
            "Я використовую цей холодний гаманець уже кілька місяців, і я вражений його надійністю та функціями безпеки. Щиро рекомендую!",
          Testimonial2:
            "Цей холодний гаманець перевершив мої очікування! Його зручний інтерфейс та найвища безпека роблять його необхідним інструментом для всіх, хто серйозно ставиться до криптовалют.",
          Testimonial3:
            "Я пробував кілька холодних гаманців, але цей виділяється. Його елегантний дизайн і передові функції роблять управління моїми криптоактивами легким.",
          "John Doe": "Джон Доу",
          "Cryptocurrency Enthusiast": "Ентузіаст криптовалют",
          "Jane Smith": "Джейн Сміт",
          "Crypto Security Advocate": "Адвокат безпеки криптовалют",
          "Alex Johnson": "Алекс Джонсон",
          "Investment Analyst": "Інвестиційний аналітик",
          "Touchscreen Interface": "Інтерфейс з сенсорним екраном",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "Відчуйте легкість управління криптовалютами з нашим інтуїтивно зрозумілим сенсорним екраном, розробленим для швидкої та зручної навігації через ваші цифрові активи, що дозволяє здійснювати швидкі транзакції та безпечне управління.",
          "Bluetooth Connectivity": "Підключення Bluetooth",
          "Wireless syncing with your devices for real-time updates.":
            "Бездротова синхронізація з вашими пристроями для оновлень в реальному часі.",
          "Airtag Tracking": "Відстеження Airtag",
          "Track your wallet easily with built-in Airtag.":
            "Легко відстежуйте свій гаманець завдяки вбудованому Airtag.",
          "Secure Chip Technology": "Технологія захищеного чіпа",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "Оснащений передовим чіпом безпеки, що забезпечує надійний захист ваших цінних цифрових активів від несанкціонованого доступу та кіберзагроз.",
          "Cross-Platform Support": "Підтримка кількох платформ",
          "Works with various platforms for easy accessibility.":
            "Працює з різними платформами для зручного доступу.",
          AboutUsDescription1:
            "У LIKKIM ми прагнемо надавати високоякісні рішення для безпечного керування вашими криптовалютними активами.",
          AboutUsDescription2:
            "Наш головний офіс знаходиться в Цузі, Швейцарія. З командою досвідчених фахівців ми прагнемо до інновацій і адаптації до постійно змінюваного ринку цифрових валют.",
          AboutUsDescription3:
            "Ми підтримуємо понад 10 000 криптовалют, гарантуючи сумісність і гнучкість для всіх користувачів.",
          BlockchainNetworksSupported: "Підтримувані блокчейн-мережі",
          CryptocurrenciesSupported: "Підтримка понад 10 000 криптовалют",
          Years: "Роки",
          ExpertiseInSecurity: "експертиза в безпеці",
          FrequentlyAskedQuestions: "Часто задавані питання",
          WhatIsColdWallet: "Що таке холодний гаманець?",
          ColdWalletDescription:
            "Холодний гаманець - це тип криптовалютного гаманця, який зберігає ваші цифрові активи офлайн, що робить їх менш вразливими до зломів та інших онлайн-загроз. На відміну від гарячих гаманців, які підключені до Інтернету і зручні для частих транзакцій, холодні гаманці найкраще підходять для довгострокового зберігання, оскільки вони забезпечують посилене безпеки.",
          HowToSetUpColdWallet: "Як налаштувати свій холодний гаманець?",
          ColdWalletSetupDescription:
            "Налаштування вашого холодного гаманця зазвичай передбачає ініціалізацію пристрою на безпечному офлайн-комп'ютері. Вам потрібно буде створити та безпечно зберігати фразу для відновлення, яка необхідна для доступу до ваших активів у разі втрати пристрою чи його поломки. Детальні інструкції надаються з вашою покупкою для забезпечення безперебійного процесу налаштування.",
          CryptocurrenciesCompatibleWithColdWallet:
            "Які криптовалюти сумісні з вашими холодними гаманцями?",
          CryptocurrenciesCompatibilityDescription:
            "Наші холодні гаманці підтримують широкий спектр криптовалют, включаючи основні, такі як Bitcoin, Ethereum та Litecoin, а також різні альткоїни. Для повного списку підтримуваних валют, будь ласка, відвідайте наш вебсайт або зверніться до документації продукту.",
          HowToSecureColdWallet:
            "Як забезпечити безпеку мого холодного гаманця?",
          ColdWalletSecurityDescription:
            "Щоб захистити свій холодний гаманець, завжди зберігайте фразу для відновлення в безпечному та приватному місці, ніколи не діліться нею з іншими людьми і розгляньте додаткові заходи безпеки, такі як біометричні замки або захищені контейнери. Регулярне оновлення прошивки гаманця з офіційного вебсайту також допомагає захистити від вразливостей.",
          ColdWalletNotWorking:
            "Що робити, якщо мій холодний гаманець не працює або виникла помилка?",
          ColdWalletErrorDescription:
            "Якщо ви зіткнулися з проблемами з холодним гаманцем, зверніться до розділу усунення неполадок у нашому посібнику користувача. Для більш специфічних проблем зверніться до нашої команди підтримки через наш вебсайт. Переконайтеся, що у вас є інформація про пристрій, щоб прискорити процес підтримки.",
          Community: "Спільнота",
          "Create a New Wallet Guide":
            "Керівництво зі створення нового гаманця",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "Крок 1: Увімкніть апаратний гаманець LIKKIM і виберіть «Створити гаманець».",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "Крок 2: Запишіть фразу для відновлення, яка відображається на апаратному гаманці LIKKIM, і підтвердьте.",
          "Import a Wallet Guide": "Керівництво з імпорту гаманця",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "Крок 1: Увімкніть апаратний гаманець LIKKIM і виберіть «Імпортувати гаманець».",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "Крок 2: Введіть фразу для відновлення за допомогою апаратного гаманця LIKKIM і підтвердьте.",
          "Receive Cryptocurrency Guide":
            "Керівництво з отримання криптовалюти",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "Крок 1: Відкрийте додаток LIKKIM, натисніть «Отримати», щоб відобразити адресу та QR-код.",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "Крок 2: Перевірте адресу та QR-код на апаратному гаманці LIKKIM.",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "Крок 3: Передайте відправнику підтверджену адресу або QR-код від LIKKIM.",
          "Send Cryptocurrency Guide":
            "Керівництво з відправлення криптовалюти",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "Крок 1: Відкрийте додаток LIKKIM, натисніть «Відправити» та введіть адресу отримувача та суму.",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "Крок 2: Перевірте деталі транзакції на гаманці LIKKIM і натисніть «Підтвердити», щоб завершити.",
          Community: "Спільнота",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "Приєднуйтесь до нашої спільноти на вашій улюбленій платформі, щоб бути в курсі подій, ставити питання та зв'язуватися з іншими користувачами.",
        },
      },
      vi: {
        translation: {
          Product: "Sản phẩm",
          Feature: "Tính năng",
          "About Us": "Về chúng tôi",
          FAQ: "Câu hỏi thường gặp",
          "Privacy Policy": "Chính sách bảo mật",
          "Help Center": "Trung tâm trợ giúp",
          brandName: "LIKKIM", // 新增字段
          tagline: "Quản lý tiền mã hóa của bạn theo cách chưa từng có", // 新增字段
          description:
            "Bắt đầu hành trình tự do tài chính của bạn với ứng dụng của chúng tôi. Bảo vệ tài sản của bạn với công nghệ ví lạnh tiên tiến của chúng tôi và quản lý tài chính một cách thông minh và hiệu quả. Sức khỏe tài chính của bạn là ưu tiên hàng đầu của chúng tôi.", // 新增字段
          learnMore: "Tìm hiểu thêm",
          "Lock Your Crypto, Securely":
            "Khóa tiền điện tử của bạn một cách an toàn",
          "Security Features": "Các tính năng bảo mật",
          "Cross-Platform": "Đa nền tảng",
          "Offline storage": "Lưu trữ ngoại tuyến",
          "Against hacking": "Chống tin tặc",
          "Unbreachable Security": "Bảo mật không thể phá vỡ",
          Testimonials: "Lời chứng thực",
          Testimonial1:
            "Tôi đã sử dụng ví lạnh này trong vài tháng và rất ấn tượng với độ tin cậy và các tính năng bảo mật của nó. Rất đáng để thử!",
          Testimonial2:
            "Ví lạnh này đã vượt quá mong đợi của tôi! Giao diện dễ sử dụng và bảo mật hàng đầu khiến nó trở thành công cụ cần thiết cho bất kỳ ai nghiêm túc với crypto.",
          Testimonial3:
            "Tôi đã thử một số ví lạnh trước đây, nhưng cái này nổi bật hơn. Thiết kế mượt mà và các tính năng tiên tiến giúp tôi quản lý tài sản crypto dễ dàng.",
          "John Doe": "John Doe",
          "Cryptocurrency Enthusiast": "Người yêu thích tiền điện tử",
          "Jane Smith": "Jane Smith",
          "Crypto Security Advocate": "Người ủng hộ bảo mật tiền điện tử",
          "Alex Johnson": "Alex Johnson",
          "Investment Analyst": "Chuyên gia phân tích đầu tư",
          "Touchscreen Interface": "Giao diện màn hình cảm ứng",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "Trải nghiệm việc quản lý tiền điện tử dễ dàng với giao diện màn hình cảm ứng trực quan của chúng tôi, được thiết kế để điều hướng nhanh chóng và dễ dàng qua các tài sản kỹ thuật số của bạn, cho phép giao dịch nhanh chóng và quản lý an toàn chỉ với một cái chạm.",
          "Bluetooth Connectivity": "Kết nối Bluetooth",
          "Wireless syncing with your devices for real-time updates.":
            "Đồng bộ hóa không dây với các thiết bị của bạn để nhận cập nhật theo thời gian thực.",
          "Airtag Tracking": "Theo dõi Airtag",
          "Track your wallet easily with built-in Airtag.":
            "Dễ dàng theo dõi ví của bạn với Airtag tích hợp sẵn.",
          "Secure Chip Technology": "Công nghệ chip bảo mật",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "Được trang bị chip bảo mật tiên tiến, đảm bảo bảo vệ mạnh mẽ cho các tài sản kỹ thuật số quý giá của bạn khỏi truy cập trái phép và các mối đe dọa mạng.",
          "Cross-Platform Support": "Hỗ trợ đa nền tảng",
          "Works with various platforms for easy accessibility.":
            "Hoạt động với nhiều nền tảng để dễ dàng truy cập.",
          AboutUsDescription1:
            "Tại LIKKIM, chúng tôi cam kết cung cấp các giải pháp chất lượng cao để quản lý tài sản tiền điện tử của bạn một cách an toàn.",
          AboutUsDescription2:
            "Trụ sở chính của chúng tôi đặt tại Zug, Thụy Sĩ. Với một đội ngũ chuyên gia giàu kinh nghiệm, chúng tôi nỗ lực đổi mới và thích ứng với thị trường tiền kỹ thuật số luôn thay đổi.",
          AboutUsDescription3:
            "Chúng tôi hỗ trợ hơn 10.000 loại tiền điện tử, đảm bảo tính tương thích và linh hoạt cho tất cả người dùng.",
          BlockchainNetworksSupported: "Hỗ trợ các mạng Blockchain",
          CryptocurrenciesSupported: "Hỗ trợ hơn 10.000 loại tiền điện tử",
          Years: "Năm",
          ExpertiseInSecurity: "kinh nghiệm trong bảo mật",
          FrequentlyAskedQuestions: "Câu hỏi thường gặp",
          WhatIsColdWallet: "Cold Wallet là gì?",
          ColdWalletDescription:
            "Cold Wallet là một loại ví tiền điện tử lưu trữ tài sản của bạn ngoại tuyến, làm cho nó ít dễ bị tấn công và các mối đe dọa trực tuyến khác. Khác với ví nóng, ví nóng kết nối với internet và thuận tiện cho giao dịch thường xuyên, ví lạnh thích hợp cho việc lưu trữ lâu dài vì nó cung cấp mức độ bảo mật cao hơn.",
          HowToSetUpColdWallet: "Làm thế nào để thiết lập Cold Wallet?",
          ColdWalletSetupDescription:
            "Việc thiết lập Cold Wallet của bạn thường liên quan đến việc khởi tạo thiết bị trên một máy tính an toàn và không kết nối internet. Bạn sẽ cần tạo và lưu trữ một cụm từ phục hồi một cách an toàn, cụm từ này cần thiết để truy cập tài sản của bạn trong trường hợp mất thiết bị hoặc thiết bị bị hỏng. Hướng dẫn chi tiết được cung cấp khi bạn mua hàng để đảm bảo quá trình thiết lập suôn sẻ.",
          CryptocurrenciesCompatibleWithColdWallet:
            "Các loại tiền điện tử nào tương thích với Cold Wallet của bạn?",
          CryptocurrenciesCompatibilityDescription:
            "Cold Wallet của chúng tôi hỗ trợ một loạt các loại tiền điện tử, bao gồm các loại phổ biến như Bitcoin, Ethereum và Litecoin, cũng như nhiều altcoin khác. Để có danh sách đầy đủ các đồng tiền hỗ trợ, vui lòng truy cập trang web của chúng tôi hoặc tham khảo tài liệu sản phẩm.",
          HowToSecureColdWallet:
            "Làm thế nào để đảm bảo Cold Wallet của tôi an toàn?",
          ColdWalletSecurityDescription:
            "Để bảo vệ Cold Wallet của bạn, luôn giữ cụm từ phục hồi ở một nơi an toàn và riêng tư, không chia sẻ nó với ai và cân nhắc sử dụng các biện pháp bảo mật bổ sung như khóa sinh trắc học hoặc hộp bảo vệ. Cập nhật phần mềm ví thường xuyên từ trang web chính thức cũng giúp bảo vệ khỏi các lỗ hổng.",
          ColdWalletNotWorking:
            "Tôi nên làm gì nếu Cold Wallet của tôi không hoạt động hoặc nếu tôi gặp lỗi?",
          ColdWalletErrorDescription:
            "Nếu bạn gặp sự cố với Cold Wallet của mình, hãy tham khảo phần khắc phục sự cố trong hướng dẫn sử dụng của chúng tôi. Đối với các vấn đề cụ thể hơn, liên hệ với đội hỗ trợ của chúng tôi qua trang web của chúng tôi. Hãy chắc chắn có thông tin thiết bị của bạn để làm nhanh chóng quá trình hỗ trợ.",
          Community: "Cộng đồng",
          "Create a New Wallet Guide": "Hướng dẫn tạo ví mới",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "Bước 1: Bật ví phần cứng LIKKIM và chọn “Tạo Ví”.",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "Bước 2: Ghi lại cụm từ khôi phục hiển thị trên ví phần cứng LIKKIM và xác nhận.",
          "Import a Wallet Guide": "Hướng dẫn nhập ví",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "Bước 1: Bật ví phần cứng LIKKIM và chọn “Nhập Ví”.",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "Bước 2: Nhập cụm từ khôi phục bằng ví phần cứng LIKKIM và xác nhận.",
          "Receive Cryptocurrency Guide": "Hướng dẫn nhận tiền mã hóa",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "Bước 1: Mở ứng dụng LIKKIM, nhấn 'Nhận' để hiển thị địa chỉ và mã QR.",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "Bước 2: Xác minh địa chỉ và mã QR trên ví phần cứng LIKKIM.",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "Bước 3: Cung cấp cho người gửi địa chỉ hoặc mã QR đã xác minh từ LIKKIM.",
          "Send Cryptocurrency Guide": "Hướng dẫn gửi tiền mã hóa",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "Bước 1: Mở ứng dụng LIKKIM, nhấn “Gửi” và nhập địa chỉ người nhận và số tiền.",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "Bước 2: Xác minh chi tiết giao dịch trên ví LIKKIM và nhấn “Xác nhận” để hoàn tất.",
          Community: "Cộng đồng",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "Tham gia cộng đồng của chúng tôi trên nền tảng yêu thích của bạn để cập nhật thông tin, đặt câu hỏi và kết nối với người dùng khác.",
        },
      },
      id: {
        translation: {
          Product: "Produk",
          Feature: "Fitur",
          "About Us": "Tentang Kami",
          FAQ: "FAQ",
          "Privacy Policy": "Kebijakan Privasi",
          "Help Center": "Pusat Bantuan",
          brandName: "LIKKIM", // 新增字段
          tagline: "Kelola Crypto Anda Seperti Tidak Pernah Sebelumnya", // 新增字段
          description:
            "Mulai perjalanan Anda menuju kebebasan finansial dengan aplikasi kami. Amankan aset Anda dengan teknologi dompet dingin canggih kami, dan kelola keuangan Anda dengan lebih cerdas dan efektif. Kesejahteraan finansial Anda adalah prioritas utama kami.", // 新增字段
          learnMore: "Pelajari lebih lanjut",
          "Lock Your Crypto, Securely": "Kunci Crypto Anda dengan Aman",
          "Security Features": "Fitur Keamanan",
          "Cross-Platform": "Lintas Platform",
          "Offline storage": "Penyimpanan Offline",
          "Against hacking": "Melawan Peretasan",
          "Unbreachable Security": "Keamanan yang Tidak Bisa Ditembus",
          Testimonials: "Testimoni",
          Testimonial1:
            "Saya telah menggunakan dompet dingin ini selama berbulan-bulan dan saya terkesan dengan keandalan dan fitur keamanannya. Sangat direkomendasikan!",
          Testimonial2:
            "Dompet dingin ini melebihi ekspektasi saya! Antarmuka yang mudah digunakan dan keamanan tingkat tinggi membuatnya menjadi alat yang wajib dimiliki bagi siapa saja yang serius dengan crypto.",
          Testimonial3:
            "Saya telah mencoba beberapa dompet dingin sebelumnya, tetapi yang ini menonjol. Desain ramping dan fitur-fitur canggih membuat mengelola aset crypto saya sangat mudah.",
          "John Doe": "John Doe",
          "Cryptocurrency Enthusiast": "Pecinta cryptocurrency",
          "Jane Smith": "Jane Smith",
          "Crypto Security Advocate": "Advokat Keamanan Crypto",
          "Alex Johnson": "Alex Johnson",
          "Investment Analyst": "Analis Investasi",
          "Touchscreen Interface": "Antarmuka layar sentuh",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "Rasakan kemudahan manajemen kripto dengan antarmuka layar sentuh intuitif kami, yang dirancang untuk navigasi cepat dan mudah melalui aset digital Anda, memungkinkan transaksi cepat dan manajemen yang aman di ujung jari Anda.",
          "Bluetooth Connectivity": "Konektivitas Bluetooth",
          "Wireless syncing with your devices for real-time updates.":
            "Sinkronisasi nirkabel dengan perangkat Anda untuk pembaruan waktu nyata.",
          "Airtag Tracking": "Pelacakan Airtag",
          "Track your wallet easily with built-in Airtag.":
            "Lacak dompet Anda dengan mudah menggunakan Airtag bawaan.",
          "Secure Chip Technology": "Teknologi chip aman",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "Dilengkapi dengan chip keamanan canggih, memastikan perlindungan kuat untuk aset digital Anda yang berharga dari akses tidak sah dan ancaman siber.",
          "Cross-Platform Support": "Dukungan lintas platform",
          "Works with various platforms for easy accessibility.":
            "Bekerja dengan berbagai platform untuk kemudahan akses.",
          AboutUsDescription1:
            "Di LIKKIM, kami berkomitmen untuk memberikan solusi berkualitas tinggi untuk mengelola aset kripto Anda dengan aman.",
          AboutUsDescription2:
            "Kantor pusat kami berada di Zug, Swiss. Dengan tim profesional berpengalaman, kami berusaha untuk berinovasi dan beradaptasi dengan pasar mata uang digital yang terus berkembang.",
          AboutUsDescription3:
            "Kami mendukung lebih dari 10.000 cryptocurrency, memastikan kompatibilitas dan fleksibilitas untuk semua pengguna.",
          BlockchainNetworksSupported: "Jaringan Blockchain yang Didukung",
          CryptocurrenciesSupported:
            "Mendukung Lebih dari 10.000 Cryptocurrency",
          Years: "Tahun",
          ExpertiseInSecurity: "pengalaman dalam Keamanan",
          FrequentlyAskedQuestions: "Pertanyaan yang sering diajukan",
          WhatIsColdWallet: "Apa itu Cold Wallet?",
          ColdWalletDescription:
            "Cold Wallet adalah jenis dompet cryptocurrency yang menyimpan aset digital Anda secara offline, membuatnya lebih aman dari peretasan dan ancaman online lainnya. Berbeda dengan hot wallet, yang terhubung ke internet dan nyaman untuk transaksi sering, cold wallet lebih cocok untuk penyimpanan jangka panjang karena memberikan keamanan yang lebih tinggi.",
          HowToSetUpColdWallet: "Bagaimana cara mengatur Cold Wallet Anda?",
          ColdWalletSetupDescription:
            "Mengatur Cold Wallet Anda biasanya melibatkan menginisialisasi perangkat di mesin yang aman dan offline. Anda perlu menghasilkan dan menyimpan frase pemulihan dengan aman, yang penting untuk mengakses aset Anda jika perangkat hilang atau rusak. Petunjuk rinci disediakan dengan pembelian Anda untuk memastikan proses pengaturan berjalan lancar.",
          CryptocurrenciesCompatibleWithColdWallet:
            "Cryptocurrency apa saja yang kompatibel dengan cold wallet Anda?",
          CryptocurrenciesCompatibilityDescription:
            "Cold Wallet kami mendukung berbagai cryptocurrency, termasuk yang utama seperti Bitcoin, Ethereum, dan Litecoin, serta berbagai altcoin. Untuk daftar lengkap mata uang yang didukung, silakan kunjungi situs web kami atau lihat dokumentasi produk.",
          HowToSecureColdWallet:
            "Bagaimana cara memastikan Cold Wallet saya aman?",
          ColdWalletSecurityDescription:
            "Untuk mengamankan Cold Wallet Anda, selalu simpan frase pemulihan di tempat yang aman dan pribadi, jangan membagikannya dengan siapa pun, dan pertimbangkan untuk menggunakan langkah-langkah keamanan tambahan seperti kunci biometrik atau wadah aman. Memperbarui firmware wallet secara teratur dari situs web resmi juga membantu melindungi dari kerentanannya.",
          ColdWalletNotWorking:
            "Apa yang harus saya lakukan jika Cold Wallet saya tidak berfungsi atau jika saya mengalami kesalahan?",
          ColdWalletErrorDescription:
            "Jika Anda mengalami masalah dengan Cold Wallet Anda, rujuk ke bagian pemecahan masalah di panduan pengguna kami. Untuk masalah yang lebih spesifik, hubungi tim dukungan kami melalui situs web kami. Pastikan Anda memiliki informasi perangkat Anda untuk mempercepat proses dukungan.",
          Community: "Komunitas",
          "Create a New Wallet Guide": "Panduan Membuat Dompet Baru",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "Langkah 1: Nyalakan dompet perangkat keras LIKKIM dan pilih “Buat Dompet”.",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "Langkah 2: Tuliskan frasa pemulihan yang ditampilkan di dompet perangkat keras LIKKIM dan konfirmasi.",
          "Import a Wallet Guide": "Panduan Mengimpor Dompet",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "Langkah 1: Nyalakan dompet perangkat keras LIKKIM dan pilih “Impor Dompet”.",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "Langkah 2: Masukkan frasa pemulihan menggunakan dompet perangkat keras LIKKIM dan konfirmasi.",
          "Receive Cryptocurrency Guide": "Panduan Menerima Cryptocurrency",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "Langkah 1: Buka aplikasi LIKKIM, ketuk 'Terima' untuk menampilkan alamat dan kode QR.",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "Langkah 2: Verifikasi alamat dan kode QR di dompet perangkat keras LIKKIM.",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "Langkah 3: Berikan alamat atau kode QR yang telah diverifikasi dari LIKKIM kepada pengirim.",
          "Send Cryptocurrency Guide": "Panduan Mengirim Cryptocurrency",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "Langkah 1: Buka aplikasi LIKKIM, ketuk “Kirim,” dan masukkan alamat penerima serta jumlahnya.",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "Langkah 2: Verifikasi detail transaksi di dompet LIKKIM dan tekan “Konfirmasi” untuk menyelesaikannya.",
          Community: "Komunitas",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "Bergabunglah dengan komunitas kami di platform favorit Anda untuk tetap terkini, bertanya, dan terhubung dengan pengguna lain.",
        },
      },
      tl: {
        translation: {
          Product: "Produkto",
          Feature: "Tampok",
          "About Us": "Tungkol sa Amin",
          FAQ: "Mga Madalas Itanong",
          "Privacy Policy": "Patakaran sa Privacy",
          "Help Center": "Sentro ng Tulong",
          brandName: "LIKKIM", // 新增字段
          tagline: "Pamahalaan ang Iyong Crypto ng Higit Pa Kaysa Kailanman", // 新增字段
          description:
            "Simulan ang iyong paglalakbay patungo sa kalayaan sa pinansyal gamit ang aming app. Siguraduhin ang iyong mga ari-arian gamit ang aming advanced na teknolohiya ng cold wallet, at pamahalaan ang iyong mga pananalapi nang mas matalino at epektibo. Ang iyong pinansyal na kagalingan ang aming pangunahing layunin.", // 新增字段
          learnMore: "Alamin Pa",
          "Lock Your Crypto, Securely": "I-lock ang iyong Crypto nang Secure",
          "Security Features": "Mga Tampok sa Seguridad",
          "Cross-Platform": "Cross-Platform",
          "Offline storage": "Offline na Imbakan",
          "Against hacking": "Laban sa Pag-hack",
          "Unbreachable Security": "Hindi Mababagsak na Seguridad",
          Testimonials: "Mga Patotoo",
          Testimonial1:
            "Ginagamit ko na ang cold wallet na ito sa loob ng ilang buwan at humanga ako sa pagiging maaasahan at mga tampok ng seguridad nito. Lubos na inirerekomenda!",
          Testimonial2:
            "Ang cold wallet na ito ay lumampas sa aking mga inaasahan! Ang madaling gamitin na interface at nangungunang seguridad ay ginagawang isang kinakailangang kasangkapan para sa sinumang seryoso sa crypto.",
          Testimonial3:
            "Sinubukan ko na ang ilang cold wallets dati, ngunit ito ay namumukod. Ang sleek na disenyo at mga advanced na tampok nito ay ginagawang madali ang pamamahala ng aking mga crypto asset.",
          "John Doe": "John Doe",
          "Cryptocurrency Enthusiast": "Tagahanga ng Cryptocurrencies",
          "Jane Smith": "Jane Smith",
          "Crypto Security Advocate": "Tagapagtanggol ng Seguridad ng Crypto",
          "Alex Johnson": "Alex Johnson",
          "Investment Analyst": "Analista ng Pamumuhunan",
          "Touchscreen Interface": "Interface ng Touchscreen",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "Danasan ang walang kahirap-hirap na pamamahala ng crypto gamit ang aming intuitive na touchscreen interface, na dinisenyo para sa mabilis at madaling pag-navigate sa iyong mga digital na asset, na nagbibigay-daan sa mabilis na transaksyon at secure na pamamahala sa iyong mga daliri.",
          "Bluetooth Connectivity": "Koneksyon ng Bluetooth",
          "Wireless syncing with your devices for real-time updates.":
            "Walang wire na pagsi-sync sa iyong mga device para sa real-time na mga update.",
          "Airtag Tracking": "Pagsubaybay ng Airtag",
          "Track your wallet easily with built-in Airtag.":
            "Madaling subaybayan ang iyong wallet gamit ang naka-built in na Airtag.",
          "Secure Chip Technology": "Secure Chip Technology",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "Nilagyan ng advanced security chip, na tinitiyak ang malakas na proteksyon para sa iyong mga mahalagang digital na asset laban sa hindi awtorisadong pag-access at mga banta sa cyber.",
          "Cross-Platform Support": "Suporta sa Maraming Platform",
          "Works with various platforms for easy accessibility.":
            "Gumagana sa iba't ibang platform para sa madaling accessibility.",
          AboutUsDescription1:
            "Sa LIKKIM, nakatutok kami sa pagbibigay ng mga de-kalidad na solusyon para sa ligtas na pamamahala ng iyong mga cryptocurrency assets.",
          AboutUsDescription2:
            "Ang aming punong-tanggapan ay nasa Zug, Switzerland. Kasama ang isang koponan ng mga eksperyensadong propesyonal, nagsusumikap kaming mag-innovate at mag-adapt sa patuloy na nagbabagong tanawin ng merkado ng digital na pera.",
          AboutUsDescription3:
            "Sumusuporta kami sa higit sa 10,000 cryptocurrencies, tinitiyak ang compatibility at flexibility para sa lahat ng gumagamit.",
          BlockchainNetworksSupported: "Mga Suportadong Blockchain Networks",
          CryptocurrenciesSupported:
            "Sumusuporta sa Higit sa 10,000 Cryptocurrencies",
          Years: "Taon",
          ExpertiseInSecurity: "ng Ekspertisya sa Seguridad",
          FrequentlyAskedQuestions: "Mga Madalas Itanong",
          WhatIsColdWallet: "Ano ang Cold Wallet?",
          ColdWalletDescription:
            "Ang Cold Wallet ay isang uri ng cryptocurrency wallet na nag-iimbak ng iyong digital assets offline, kaya't mas ligtas ito mula sa mga hack at iba pang mga online na banta. Kumpara sa hot wallets, na konektado sa internet at maginhawa para sa mga madalas na transaksyon, ang cold wallets ay mas angkop para sa pangmatagalang pag-iimbak dahil nag-aalok ito ng pinahusay na seguridad.",
          HowToSetUpColdWallet: "Paano I-Set Up ang Iyong Cold Wallet?",
          ColdWalletSetupDescription:
            "Ang pag-setup ng iyong cold wallet ay karaniwang nagsasangkot ng pag-initialize ng device sa isang secure, offline na makina. Kailangan mong gumawa at ligtas na itago ang recovery phrase, na mahalaga para ma-access ang iyong mga assets sa oras ng pagkawala ng device o pagkasira nito. Ang mga detalyadong tagubilin ay ibinibigay sa iyong pagbili upang matiyak ang isang maayos na proseso ng pag-setup.",
          CryptocurrenciesCompatibleWithColdWallet:
            "Aling mga cryptocurrencies ang katugma ng iyong cold wallet?",
          CryptocurrenciesCompatibilityDescription:
            "Ang aming mga cold wallet ay sumusuporta sa isang malawak na hanay ng mga cryptocurrencies, kabilang ang mga pangunahing tulad ng Bitcoin, Ethereum, at Litecoin, pati na rin ang iba't ibang altcoin. Para sa isang kumpletong listahan ng mga suportadong pera, mangyaring bisitahin ang aming website o tingnan ang dokumentasyon ng produkto.",
          HowToSecureColdWallet:
            "Paano ko matitiyak na ang aking cold wallet ay ligtas?",
          ColdWalletSecurityDescription:
            "Upang matiyak ang seguridad ng iyong cold wallet, palaging itago ang recovery phrase sa isang ligtas at pribadong lokasyon, huwag itong ibahagi kaninuman, at isaalang-alang ang paggamit ng karagdagang mga hakbang sa seguridad tulad ng biometric locks o secure enclosures. Ang regular na pag-update ng firmware ng wallet mula sa opisyal na website ay nakakatulong din upang maprotektahan laban sa mga kahinaan.",
          ColdWalletNotWorking:
            "Ano ang dapat kong gawin kung hindi gumagana ang aking cold wallet o kung nakatagpo ako ng isang error?",
          ColdWalletErrorDescription:
            "Kung nakakaranas ka ng mga problema sa iyong cold wallet, mangyaring sumangguni sa seksyon ng pag-troubleshoot sa aming gabay ng gumagamit. Para sa mas partikular na mga isyu, makipag-ugnayan sa aming support team sa pamamagitan ng aming website. Siguraduhing mayroon kang impormasyon ng iyong device upang mapabilis ang proseso ng suporta.",
          Community: "Komunidad",
          "Create a New Wallet Guide": "Gabay sa Paglikha ng Bagong Wallet",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "Hakbang 1: Buksan ang LIKKIM hardware wallet at piliin ang “Create Wallet”.",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "Hakbang 2: Isulat ang recovery phrase na ipinapakita sa LIKKIM hardware wallet at kumpirmahin.",
          "Import a Wallet Guide": "Gabay sa Pag-import ng Wallet",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "Hakbang 1: Buksan ang LIKKIM hardware wallet at piliin ang “Import Wallet”.",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "Hakbang 2: Ipasok ang recovery phrase gamit ang LIKKIM hardware wallet at kumpirmahin.",
          "Receive Cryptocurrency Guide":
            "Gabay sa Pagtanggap ng Cryptocurrency",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "Hakbang 1: Buksan ang LIKKIM App, i-tap ang 'Receive' upang ipakita ang address at QR code.",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "Hakbang 2: I-verify ang address at QR code sa LIKKIM hardware wallet.",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "Hakbang 3: Ibigay sa nagpadala ang na-verify na address o QR code mula sa LIKKIM.",
          "Send Cryptocurrency Guide": "Gabay sa Pagpapadala ng Cryptocurrency",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "Hakbang 1: Buksan ang LIKKIM App, i-tap ang “Send,” at ipasok ang address ng tatanggap at ang halaga.",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "Hakbang 2: I-verify ang mga detalye ng transaksyon sa LIKKIM wallet at pindutin ang “Confirm” upang tapusin ito.",
          Community: "Komunidad",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "Sumali sa aming komunidad sa iyong paboritong platform upang manatiling napapanahon, magtanong, at makakonekta sa iba pang mga user.",
        },
      },
      bn: {
        translation: {
          Product: "পণ্য",
          Feature: "বৈশিষ্ট্য",
          "About Us": "আমাদের সম্পর্কে",
          FAQ: "প্রায়শই জিজ্ঞাসিত প্রশ্ন",
          "Privacy Policy": "গোপনীয়তা নীতি",
          "Help Center": "সহায়তা কেন্দ্র",
          brandName: "LIKKIM", // 新增字段
          tagline: "আপনার ক্রিপ্টোকারেন্সি এর মতো কখনোই পরিচালনা করুন", // 新增字段
          description:
            "আমাদের অ্যাপ দিয়ে আর্থিক স্বাধীনতার পথে আপনার যাত্রা শুরু করুন। আমাদের উন্নত কোল্ড ওয়ালেট প্রযুক্তি দিয়ে আপনার সম্পদ সুরক্ষিত করুন এবং আরও স্মার্ট এবং কার্যকরভাবে আপনার আর্থিক অবস্থা পরিচালনা করুন। আপনার আর্থিক সুস্থতা আমাদের প্রধান অগ্রাধিকার।", // 新增字段
          learnMore: "আরও জানুন",
          "Lock Your Crypto, Securely":
            "আপনার ক্রিপ্টোকারেন্সি নিরাপদে লক করুন",
          "Security Features": "নিরাপত্তা বৈশিষ্ট্য",
          "Cross-Platform": "ক্রস-প্ল্যাটফর্ম",
          "Offline storage": "অফলাইন স্টোরেজ",
          "Against hacking": "হ্যাকিং এর বিরুদ্ধে",
          "Unbreachable Security": "অভেদ্য নিরাপত্তা",
          Testimonials: "প্রত্যয়পত্র",
          Testimonial1:
            "আমি এই কোল্ড ওয়ালেটটি কয়েক মাস ধরে ব্যবহার করছি এবং এর নির্ভরযোগ্যতা এবং সুরক্ষা বৈশিষ্ট্যগুলি নিয়ে আমি মুগ্ধ। অত্যন্ত সুপারিশ করা হয়!",
          Testimonial2:
            "এই কোল্ড ওয়ালেটটি আমার প্রত্যাশা ছাড়িয়ে গেছে! এর ব্যবহারকারী-বান্ধব ইন্টারফেস এবং শীর্ষস্থানীয় নিরাপত্তা এটিকে যে কেউ যারা ক্রিপ্টো সম্পর্কে সিরিয়াস তাদের জন্য একটি অপরিহার্য টুল করে তোলে।",
          Testimonial3:
            "আমি আগে কয়েকটি কোল্ড ওয়ালেট চেষ্টা করেছি, তবে এটি আলাদা। এর স্লিক ডিজাইন এবং উন্নত বৈশিষ্ট্যগুলি আমার ক্রিপ্টো অ্যাসেটগুলি পরিচালনা করা অনেক সহজ করে তোলে।",
          "John Doe": "জন ডো",
          "Cryptocurrency Enthusiast": "ক্রিপ্টোকারেন্সির প্রেমিক",
          "Jane Smith": "জেন স্মিথ",
          "Crypto Security Advocate": "ক্রিপ্টো নিরাপত্তার সমর্থক",
          "Alex Johnson": "অ্যালেক্স জনসন",
          "Investment Analyst": "বিনিয়োগ বিশ্লেষক",
          "Touchscreen Interface": "টাচস্ক্রিন ইন্টারফেস",
          "Experience effortless crypto management with our intuitive touchscreen interface, designed for quick and easy navigation through your digital assets, enabling swift transactions and secure management at your fingertips.":
            "আমাদের সহজবোধ্য টাচস্ক্রিন ইন্টারফেস দিয়ে সহজেই ক্রিপ্টো পরিচালনা করুন, ডিজিটাল সম্পদগুলির মাধ্যমে দ্রুত এবং সহজে নেভিগেট করার জন্য ডিজাইন করা হয়েছে, দ্রুত লেনদেন এবং আপনার আঙুলের ছোঁয়ায় নিরাপদ ব্যবস্থাপনা সক্ষম করছে।",
          "Bluetooth Connectivity": "ব্লুটুথ সংযোগ",
          "Wireless syncing with your devices for real-time updates.":
            "আপনার ডিভাইসগুলির সাথে ওয়্যারলেস সিঙ্কিংয়ের মাধ্যমে রিয়েল-টাইম আপডেট পান।",
          "Airtag Tracking": "Airtag ট্র্যাকিং",
          "Track your wallet easily with built-in Airtag.":
            "নির্মিত Airtag দিয়ে আপনার মানিব্যাগ সহজেই ট্র্যাক করুন।",
          "Secure Chip Technology": "নিরাপদ চিপ প্রযুক্তি",
          "Equipped with an advanced security chip, ensuring robust protection for your valuable digital assets against unauthorized access and cyber threats.":
            "এটি একটি উন্নত নিরাপত্তা চিপ দ্বারা সজ্জিত, যা আপনার মূল্যবান ডিজিটাল সম্পদের জন্য শক্তিশালী সুরক্ষা নিশ্চিত করে, অবৈধ অ্যাক্সেস এবং সাইবার হুমকির বিরুদ্ধে।",
          "Cross-Platform Support": "ক্রস-প্ল্যাটফর্ম সাপোর্ট",
          "Works with various platforms for easy accessibility.":
            "সহজ অ্যাক্সেসের জন্য বিভিন্ন প্ল্যাটফর্মের সাথে কাজ করে।",
          AboutUsDescription1:
            "LIKKIM-এ, আমরা আপনার ক্রিপ্টোকারেন্সি অ্যাসেটগুলি নিরাপদভাবে পরিচালনা করার জন্য উচ্চমানের সমাধান প্রদান করতে প্রতিশ্রুতিবদ্ধ।",
          AboutUsDescription2:
            "আমাদের সদর দপ্তর সুইজারল্যান্ডের জুগে অবস্থিত। অভিজ্ঞ পেশাদারদের একটি দল নিয়ে, আমরা ডিজিটাল মুদ্রা বাজারের অবিচ্ছিন্ন পরিবর্তনশীল প্রেক্ষাপটে উদ্ভাবন এবং অভিযোজন করার জন্য কাজ করি।",
          AboutUsDescription3:
            "আমরা 10,000টিরও বেশি ক্রিপ্টোকারেন্সি সমর্থন করি, যা সকল ব্যবহারকারীর জন্য সামঞ্জস্য এবং নমনীয়তা নিশ্চিত করে।",
          BlockchainNetworksSupported: "সমর্থিত ব্লকচেইন নেটওয়ার্ক",
          CryptocurrenciesSupported: "১০,০০০টিরও বেশি ক্রিপ্টোকারেন্সি সমর্থন",
          Years: "বছর",
          ExpertiseInSecurity: "সুরক্ষায় বিশেষজ্ঞতা",
          FrequentlyAskedQuestions: "ঘন ঘন জিজ্ঞাসিত প্রশ্ন",
          WhatIsColdWallet: "কোল্ড ওয়ালেট কী?",
          ColdWalletDescription:
            "কোল্ড ওয়ালেট হল একটি ধরনের ক্রিপ্টোকারেন্সি ওয়ালেট যা আপনার ডিজিটাল সম্পদ অফলাইন সঞ্চয় করে, ফলে এটি হ্যাক এবং অন্যান্য অনলাইন হুমকির বিরুদ্ধে কম সংবেদনশীল। হট ওয়ালেটের বিপরীতে, যা ইন্টারনেটের সাথে সংযুক্ত এবং ঘন ঘন লেনদেনের জন্য সুবিধাজনক, কোল্ড ওয়ালেট দীর্ঘমেয়াদী স্টোরেজের জন্য সবচেয়ে উপযুক্ত কারণ এটি উন্নত নিরাপত্তা প্রদান করে।",
          HowToSetUpColdWallet: "আপনার কোল্ড ওয়ালেট কীভাবে সেট আপ করবেন?",
          ColdWalletSetupDescription:
            "আপনার কোল্ড ওয়ালেট সেট আপ করার জন্য সাধারণত একটি নিরাপদ, অফলাইন মেশিনে ডিভাইসটি ইনিশিয়ালাইজ করা প্রয়োজন। আপনাকে একটি রিকভারি ফ্রেজ তৈরি করতে হবে এবং নিরাপদে সংরক্ষণ করতে হবে, যা ডিভাইস হারিয়ে বা ব্যর্থ হলে আপনার অ্যাসেটসে অ্যাক্সেস পেতে প্রয়োজনীয়। আপনার কেনাকাটার সাথে বিস্তারিত নির্দেশিকা প্রদান করা হয় যাতে সেটআপ প্রক্রিয়া মসৃণ হয়।",
          CryptocurrenciesCompatibleWithColdWallet:
            "কোন কোন ক্রিপ্টোকারেন্সি আপনার কোল্ড ওয়ালেটের সাথে সামঞ্জস্যপূর্ণ?",
          CryptocurrenciesCompatibilityDescription:
            "আমাদের কোল্ড ওয়ালেটগুলি বিটকয়েন, ইথেরিয়াম, লাইটকয়েন সহ প্রধান ক্রিপ্টোকারেন্সি এবং বিভিন্ন অল্টকয়েন সহ অনেকগুলি ক্রিপ্টোকারেন্সি সমর্থন করে। সমর্থিত মুদ্রার সম্পূর্ণ তালিকা জানার জন্য, আমাদের ওয়েবসাইট পরিদর্শন করুন অথবা পণ্য ডকুমেন্টেশনটি দেখুন।",
          HowToSecureColdWallet: "কিভাবে আমার কোল্ড ওয়ালেট সুরক্ষিত রাখব?",
          ColdWalletSecurityDescription:
            "আপনার কোল্ড ওয়ালেট সুরক্ষিত রাখতে, সর্বদা রিকভারি ফ্রেজটি একটি নিরাপদ এবং গোপন স্থানে রাখুন, কখনও এটি কাউকে শেয়ার করবেন না এবং অতিরিক্ত নিরাপত্তা ব্যবস্থা যেমন বায়োমেট্রিক লক বা সুরক্ষিত কনটেইনার ব্যবহার করার কথা বিবেচনা করুন। অফিসিয়াল ওয়েবসাইট থেকে নিয়মিতভাবে ওয়ালেটের ফার্মওয়্যার আপডেট করাও দুর্বলতা থেকে সুরক্ষিত রাখতে সাহায্য করবে।",
          ColdWalletNotWorking:
            "যদি আমার কোল্ড ওয়ালেট কাজ না করে বা আমি যদি কোন ত্রুটি পাই, তবে আমি কি করব?",
          ColdWalletErrorDescription:
            "যদি আপনার কোল্ড ওয়ালেটে কোন সমস্যা হয়, তবে আমাদের ব্যবহারকারী গাইডে ট্রাবলশুটিং সেকশনটি দেখুন। আরও নির্দিষ্ট সমস্যা হলে, আমাদের সাপোর্ট টিমের সাথে আমাদের ওয়েবসাইটের মাধ্যমে যোগাযোগ করুন। সাপোর্ট প্রক্রিয়া দ্রুত করতে আপনার ডিভাইসের তথ্য প্রস্তুত রাখুন।",
          Community: "সম্প্রদায়",
          "Create a New Wallet Guide": "নতুন ওয়ালেট তৈরির গাইড",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”":
            "পর্যায় ১: LIKKIM হার্ডওয়্যার ওয়ালেট চালু করুন এবং “Create Wallet” নির্বাচন করুন।",
          "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm.":
            "পর্যায় ২: LIKKIM হার্ডওয়্যার ওয়ালেটে প্রদর্শিত পুনরুদ্ধার ফ্রেজ লিখুন এবং নিশ্চিত করুন।",
          "Import a Wallet Guide": "ওয়ালেট ইমপোর্ট করার গাইড",
          "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”":
            "পর্যায় ১: LIKKIM হার্ডওয়্যার ওয়ালেট চালু করুন এবং “Import Wallet” নির্বাচন করুন।",
          "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm.":
            "পর্যায় ২: LIKKIM হার্ডওয়্যার ওয়ালেট ব্যবহার করে পুনরুদ্ধার ফ্রেজ প্রবেশ করান এবং নিশ্চিত করুন।",
          "Receive Cryptocurrency Guide": "ক্রিপ্টোকারেন্সি গ্রহণের গাইড",
          "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code.":
            "পর্যায় ১: LIKKIM অ্যাপ খুলুন, 'Receive' ট্যাপ করুন এবং ঠিকানা ও QR কোড প্রদর্শন করুন।",
          "Step 2: Verify the address and QR code on the LIKKIM hardware wallet.":
            "পর্যায় ২: LIKKIM হার্ডওয়্যার ওয়ালেটে ঠিকানা এবং QR কোড যাচাই করুন।",
          "Step 3: Give the sender the verified address or QR code from the LIKKIM.":
            "পর্যায় ৩: LIKKIM থেকে যাচাইকৃত ঠিকানা বা QR কোড প্রেরককে দিন।",
          "Send Cryptocurrency Guide": "ক্রিপ্টোকারেন্সি পাঠানোর গাইড",
          "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount.":
            "পর্যায় ১: LIKKIM অ্যাপ খুলুন, “Send” ট্যাপ করুন এবং প্রাপকের ঠিকানা ও পরিমাণ লিখুন।",
          "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it.":
            "পর্যায় ২: LIKKIM ওয়ালেটে লেনদেনের বিবরণ যাচাই করুন এবং “Confirm” চাপ দিন।",
          Community: "সম্প্রদায়",
          "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users.":
            "আপ-টু-ডেট থাকতে, প্রশ্ন জিজ্ঞাসা করতে এবং অন্যান্য ব্যবহারকারীদের সাথে যুক্ত হতে আপনার প্রিয় প্ল্যাটফর্মে আমাদের সম্প্রদায়ে যোগ দিন।",
        },
      },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
