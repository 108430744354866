import React, { useState, useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";
import emailjs from "emailjs-com";

function WarrantySupport() {
  // 创建 ref 对象
  const productRef = useRef(null);
  const featureRef = useRef(null);
  const aboutUsRef = useRef(null);
  const faqRef = useRef(null);

  // 创建一个 scrollToRef 函数
  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // 设置表单状态
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    issueTitle: "",
    warrantyType: "",
    feedback: "",
  });

  // 文件上传状态
  const [selectedFiles, setSelectedFiles] = useState([]);

  // 更新表单字段
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // 更新文件列表并显示图片预览
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  // 删除文件
  const handleFileDelete = (fileToDelete) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToDelete)
    );
  };

  // 处理表单提交
  const handleSubmit = (e) => {
    e.preventDefault();

    // 使用 EmailJS 发送邮件
    emailjs
      .sendForm(
        "service_19a1c82", // 使用您的 Service ID
        "template_3chgqt7", // 使用您的 Template ID
        e.target, // 通过 e.target 获取表单数据
        "3W14JyOAenrHu5yXd" // 使用您的 Public Key (User ID)
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Your warranty claim has been submitted!");
        },
        (error) => {
          console.log(error.text);
          alert("There was an error submitting your claim.");
        }
      );
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Header
        style={{ position: "fixed", top: 0, width: "100%", zIndex: 1000 }}
        scrollToRef={scrollToRef}
        productRef={productRef}
        featureRef={featureRef}
        aboutUsRef={aboutUsRef}
        faqRef={faqRef}
      />

      <div className="pt-20 px-6 md:px-10 lg:px-20 py-10 mx-auto max-w-screen-lg flex-grow">
        <h1 className="text-3xl font-semibold mb-6 text-neutral-100">
          Warranty Support
        </h1>
        <p className="mb-6 text-neutral-300">
          Please fill in the information below to submit your warranty claim.
        </p>

        {/* 保修申请表单 */}
        <div className="mt-10">
          <h2 className="text-2xl font-semibold mb-4 text-neutral-100">
            Warranty Claim Form
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-lg text-neutral-300">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-4 py-2 mt-2 bg-neutral-800 text-neutral-100 rounded-lg focus:bg-neutral-600"
                placeholder="Enter your name"
                required
              />
            </div>

            <div>
              <label className="block text-lg text-neutral-300">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-2 mt-2 bg-neutral-800 text-neutral-100 rounded-lg focus:bg-neutral-600"
                placeholder="Enter your email"
                required
              />
            </div>

            <div>
              <label className="block text-lg text-neutral-300">
                Issue Title
              </label>
              <input
                type="text"
                name="issueTitle"
                value={formData.issueTitle}
                onChange={handleChange}
                className="w-full px-4 py-2 mt-2 bg-neutral-800 text-neutral-100 rounded-lg focus:bg-neutral-600"
                placeholder="Enter the title of the issue"
                required
              />
            </div>

            <div>
              <label className="block text-lg text-neutral-300">
                Warranty Type
              </label>
              <select
                name="warrantyType"
                value={formData.warrantyType}
                onChange={handleChange}
                className="w-full px-4 py-2 mt-2 bg-neutral-800 text-neutral-100 rounded-lg focus:bg-neutral-600"
                required
              >
                <option value="">Select Warranty Type</option>
                <option value="Product Warranty">Product Warranty</option>
                <option value="Service Warranty">Service Warranty</option>
                <option value="Replacement Warranty">
                  Replacement Warranty
                </option>
              </select>
            </div>

            <div>
              <label className="block text-lg text-neutral-300">Feedback</label>
              <textarea
                name="feedback"
                value={formData.feedback}
                onChange={handleChange}
                className="w-full px-4 py-2 mt-2 bg-neutral-800 text-neutral-100 rounded-lg focus:bg-neutral-600"
                placeholder="Describe the issue in detail"
                rows="4"
              ></textarea>
            </div>

            {/* 文件上传 */}
            {/*         <div>
              <label className="block text-lg text-neutral-300 mb-2">
                Upload Purchase Proof and Images
              </label>
              <label
                htmlFor="fileInput"
                className="w-full flex justify-center items-center px-4 py-2 mt-2 bg-neutral-800 text-neutral-100 rounded-lg cursor-pointer hover:bg-neutral-700 active:bg-neutral-600 transition-all duration-300"
              >
                <span>Choose Files</span>
              </label>
              <input
                id="fileInput"
                type="file"
                name="images"
                multiple
                onChange={handleFileChange}
                className="hidden"
              />
            </div> */}

            {/* 显示已选择的文件并允许删除 */}
            <div className="mt-4">
              {selectedFiles.length > 0 && (
                <div className="space-y-2">
                  {selectedFiles.map((file, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      {file.type.startsWith("image/") ? (
                        <img
                          src={URL.createObjectURL(file)}
                          alt={file.name}
                          className="h-10 w-10 object-cover rounded-md"
                        />
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 text-neutral-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M3 7l2-2m0 0L9 3m-4 2l2 2m0 0L9 9m3-3l2-2m0 0l2 2m-2 2l2-2m0 0L15 9"
                          />
                        </svg>
                      )}
                      <span className="text-neutral-300">{file.name}</span>
                      <button
                        type="button"
                        className="text-white ml-2"
                        onClick={() => handleFileDelete(file)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <button
              type="submit"
              className="w-full py-3 bg-neutral-600 hover:bg-neutral-500 text-neutral-100 font-bold text-lg rounded-lg shadow-xl transition duration-300 ease-in-out"
            >
              Submit Warranty Claim
            </button>
          </form>
        </div>
      </div>

      <Footer
        style={{ position: "fixed", bottom: 0, width: "100%", zIndex: 1000 }}
      />
    </div>
  );
}

export default WarrantySupport;
