import React from "react";
import { Link, useNavigate } from "react-router-dom"; // 引入 useNavigate
import { useTranslation } from "react-i18next"; // 如果你在使用 i18n
import {
  FaTwitter,
  FaTelegram,
  FaDiscord,
  FaReddit,
  FaInstagram,
  FaFacebook,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
const Footer = ({ productRef, featureRef, aboutUsRef, faqRef }) => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // 获取 navigate 函数

  const handleScrollAndNavigate = (ref, sectionId) => {
    navigate(`/#${sectionId}`); // 导航到对应的页面锚点
    setTimeout(() => {
      // 设置延时以等待页面渲染完成
      if (ref && ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" }); // 滚动到对应的 ref
      }
    }, 100); // 延时100毫秒以确保滚动正确
  };

  return (
    <div
      id="footer"
      className="w-full sm:px-[5vw] bg-[#121212] flex flex-col justify-center items-center min-h-[250px] py-6 relative"
    >
      {/* Footer navigation links */}
      <div className="flex sm:flex-col lg:flex-row justify-center gap-[40px] font-[400] text-[#827D7D] sm:text-center lg:text-center">
        <p onClick={() => handleScrollAndNavigate(productRef, "product")}>
          {t("Product")}
        </p>
        <p onClick={() => handleScrollAndNavigate(featureRef, "feature")}>
          {t("Feature")}
        </p>
        <p onClick={() => handleScrollAndNavigate(aboutUsRef, "aboutUs")}>
          {t("About us")}
        </p>
        <p onClick={() => handleScrollAndNavigate(faqRef, "faq")}>{t("FAQ")}</p>
        <Link to="/privacy-policy">{t("Privacy Policy")}</Link>
        {/* 添加 Help Center 链接 */}
        <Link to="/help-center">{t("Help Center")}</Link>
      </div>
      <div className="flex flex-wrap justify-center mt-[20px] gap-4">
        <a
          href="https://x.com/LIKKIMwallet"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter className="text-[#B6B6B6] hover:text-white" />
        </a>
        <a
          href="https://t.me/+q9j351SAY8hlMDJl"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTelegram className="text-[#B6B6B6] hover:text-white" />
        </a>
        <a
          href="https://discord.gg/59hKBX2daq"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaDiscord className="text-[#B6B6B6] hover:text-white" />
        </a>
        <a
          href="https://www.reddit.com/user/Ok_Bass_6829/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaReddit className="text-[#B6B6B6] hover:text-white" />
        </a>
        <a
          href="https://www.instagram.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram className="text-[#B6B6B6] hover:text-white" />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61570753106156"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook className="text-[#B6B6B6] hover:text-white" />
        </a>
        <a
          href="https://www.linkedin.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin className="text-[#B6B6B6] hover:text-white" />
        </a>
        <a
          href="https://www.youtube.com/@LukkeyAG"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaYoutube className="text-[#B6B6B6] hover:text-white" />
        </a>
      </div>

      {/* 增加更大的间距 */}
      <div className="mt-12 sm:mt-12 lg:mt-16 flex justify-center sm:mt-12 lg:mt-16">
        <p className="text-[#827D7D] sm:w-[90vw] sm:text-center lg:w-full lg:text-center">
          {t("All rights reserved. © Copyright LUKKEY AG 2024")}
        </p>
      </div>
    </div>
  );
};

export default Footer;
