import React, { useState, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import Header from "./Header";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import ios from "./asset/ios.png";
import hoverIos from "./asset/hoverios.png";
import apk from "./asset/apk.png";
import hoverApk from "./asset/hoverapk.png";
import googlePlay from "./asset/googleplay.png";
import hoverGooglePlay from "./asset/hovergoogleplay.png";
import createWalletImage1 from "./asset/step1-create-wallet-image.png";
import createWalletImage2 from "./asset/step2-create-wallet-image.png";
import importWalletImage1 from "./asset/step1-import-wallet-image.png";
import importWalletImage2 from "./asset/step2-import-wallet-image.png";
import receiveCryptoImage1 from "./asset/step1-receive-crypto-image.png";

import sendCryptoImage1 from "./asset/step1-send-crypto-image.png";

const HardwareWalletGuide = () => {
  const { t } = useTranslation();
  const productRef = useRef(null);
  const featureRef = useRef(null);
  const aboutUsRef = useRef(null);
  const faqRef = useRef(null);

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [faqStates, setFaqStates] = useState({
    faq1: false,
    faq2: false,
    faq3: false,
    faq4: false,
  });

  const toggleFAQ = (key) => {
    setFaqStates((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const faqs = [
    {
      key: "faq1",
      question: t("Create a New Wallet Guide"),
      steps: [
        {
          step: t(
            "Step 1: Turn on the LIKKIM hardware wallet and select “Create Wallet.”"
          ),
          image: createWalletImage1,
        },
        {
          step: t(
            "Step 2: Write down the recovery phrase displayed on the LIKKIM hardware wallet and confirm."
          ),
          image: createWalletImage2,
        },
      ],
    },
    {
      key: "faq2",
      question: t("Import a Wallet Guide"),
      steps: [
        {
          step: t(
            "Step 1: Turn on the LIKKIM hardware wallet and select “Import Wallet.”"
          ),
          image: importWalletImage1,
        },
        {
          step: t(
            "Step 2: Enter the recovery phrase using the LIKKIM hardware wallet and confirm."
          ),
          image: importWalletImage2,
        },
      ],
    },
    {
      key: "faq3",
      question: t("Receive Cryptocurrency Guide"),
      steps: [
        {
          step: t(
            "Step 1: Open the LIKKIM App, tap 'Receive' to display the address and QR code."
          ),
          image: receiveCryptoImage1,
        },
        {
          step: t(
            "Step 2: Verify the address and QR code on the LIKKIM hardware wallet."
          ),
          image: "",
        },
        {
          step: t(
            "Step 3: Give the sender the verified address or QR code from the LIKKIM."
          ),
          image: "",
        },
      ],
    },
    {
      key: "faq4",
      question: t("Send Cryptocurrency Guide"),
      steps: [
        {
          step: t(
            "Step 1: Open the LIKKIM App, tap “Send,” and enter the recipient's address and amount."
          ),
          image: sendCryptoImage1,
        },
        {
          step: t(
            "Step 2: Verify transaction details on the LIKKIM wallet and press “Confirm” to complete it."
          ),
          image: "",
        },
      ],
    },
  ];

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Header
        style={{ position: "fixed", top: 0, width: "100%", zIndex: 1000 }}
        scrollToRef={scrollToRef}
        productRef={productRef}
        featureRef={featureRef}
        aboutUsRef={aboutUsRef}
        faqRef={faqRef}
      />

      <div
        className="mt-[60px]"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "60px",
        }}
      >
        <div className="flex justify-center items-center mb-20">
          <div className="gap-[10vw] lg:gap-[4vw]  sm:gap-[6vw] flex items-center">
            {/* iOS Section */}
            <div className="group cursor-pointer flex flex-col items-center text-center">
              <a
                href="https://testflight.apple.com/join/CF4ABvtu"
                target="_blank"
                rel="noopener noreferrer"
                className="text-center"
              >
                <img
                  src={ios}
                  alt={t("App Store Icon")}
                  className="block group-hover:hidden w-16 h-16 object-contain mx-auto"
                />
                <img
                  src={hoverIos}
                  alt={t("Hover App Store Icon")}
                  className="hidden group-hover:block w-16 h-16 object-contain mx-auto"
                />
                <p className="mt-2 text-neutral-500 text-sm font-bold">
                  {t("Download on the")}
                  <br />
                  <span className="text-white">{t("App Store")}</span>
                </p>
              </a>
            </div>

            {/* Google Play Section */}
            <div className="group cursor-pointer flex flex-col items-center text-center">
              <a
                href="https://play.google.com/store/apps/details?id=com.app.likkim"
                target="_blank"
                rel="noopener noreferrer"
                className="text-center"
              >
                <img
                  src={googlePlay}
                  alt={t("Google Play Icon")}
                  className="block group-hover:hidden w-16 h-16 object-contain mx-auto"
                />
                <img
                  src={hoverGooglePlay}
                  alt={t("Hover Google Play Icon")}
                  className="hidden group-hover:block w-16 h-16 object-contain mx-auto"
                />
                <p className="mt-2 text-neutral-500 text-sm font-bold">
                  {t("Get it on")}
                  <br />
                  <span className="text-white">{t("Google Play")}</span>
                </p>
              </a>
            </div>

            <div className="group cursor-pointer flex flex-col items-center text-center">
              {/* Update the href attribute to point to the APK file in your public/app/ folder */}
              <a
                href="/app/LIKKIM.apk" // Replace 'your-app-file-name.apk' with your actual file name
                download
                className="text-center"
              >
                <img
                  src={apk}
                  alt="APK Icon"
                  className="block group-hover:hidden w-16 h-16 object-contain mx-auto"
                />
                <img
                  src={hoverApk}
                  alt="Hover APK Icon"
                  className="hidden group-hover:block w-16 h-16 object-contain mx-auto"
                />
                <p className="mt-2 text-neutral-500 text-sm font-bold">
                  {t("Download the")}
                  <br />
                  <span className="text-white">{t("APK")}</span>
                </p>
              </a>
            </div>
          </div>
        </div>

        {faqs.map((faq) => (
          <div
            className=" sm:w-[90vw]    w-[60vw]"
            key={faq.key}
            style={{
              marginBottom: "16px",
              backgroundColor: "#111",
              borderRadius: "10px",
              color: "white",
              padding: "30px 20px",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => toggleFAQ(faq.key)}
            >
              <FaPlus
                size="12px"
                color="white"
                style={{
                  marginRight: "10px",
                  transform: faqStates[faq.key] ? "rotate(45deg)" : "none",
                }}
              />
              <h2>{faq.question}</h2>
            </div>
            <AnimatePresence>
              {faqStates[faq.key] && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.2 }}
                  style={{ marginTop: "4px" }}
                >
                  {faq.steps.map((step, index) => (
                    <div key={index}>
                      <p>{step.step}</p>
                      <div className="my-10">
                        {step.image && (
                          <img
                            className="w-[50%] sm:w-[80%] lg:w-[30%]"
                            src={step.image}
                            alt={`Step ${index + 1} illustration`}
                            style={{
                              display: "block", // 设置 display 为 block

                              height: "auto",
                              margin: "10px auto", // 设置上下边距为 10px，左右自动（居中）
                            }}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>
      <Footer
        style={{ position: "fixed", bottom: 0, width: "100%", zIndex: 1000 }}
      />
    </div>
  );
};

export default HardwareWalletGuide;
