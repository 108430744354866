import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import {
  FaTwitter,
  FaTelegram,
  FaDiscord,
  FaReddit,
  FaFacebook,
  FaYoutube,
} from "react-icons/fa";

const Community = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen flex flex-col bg-[#1D1D1D] text-white">
      <Header />
      <div className="flex-grow container mx-auto px-4 md:px-6 lg:px-8 py-10 pt-20">
        <h1 className="text-3xl font-bold text-center mb-6">
          {t("Community")}
        </h1>
        <p className="text-lg text-center mb-4">
          {t(
            "Join our community on your favorite platform to stay up-to-date, ask questions, and connect with other users."
          )}
        </p>
        <div className="border border-[#3C3C3C] rounded shadow-lg w-full max-w-[500px] mx-auto p-4 flex flex-col items-center">
          <SocialLink
            icon={<FaTwitter />}
            name="Twitter"
            url="https://x.com/LIKKIMwallet"
          />
          <SocialLink
            icon={<FaTelegram />}
            name="Telegram"
            url="https://t.me/+q9j351SAY8hlMDJl"
          />
          <SocialLink
            icon={<FaDiscord />}
            name="Discord"
            url="https://discord.gg/59hKBX2daq"
          />
          <SocialLink
            icon={<FaReddit />}
            name="Reddit"
            url="https://www.reddit.com/user/Ok_Bass_6829/"
          />
          <SocialLink
            icon={<FaFacebook />}
            name="Facebook"
            url="https://www.facebook.com/profile.php?id=61570753106156"
          />
          <SocialLink
            icon={<FaYoutube />}
            name="YouTube"
            url="https://www.youtube.com/@LukkeyAG"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

const SocialLink = ({ icon, name, url }) => (
  <div
    className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-[#3C3C3C] w-full justify-center"
    onClick={() => window.open(url, "_blank")}
  >
    {icon}
    <span className="hover:text-white">{name}</span>
  </div>
);

export default Community;
