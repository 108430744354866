import React, { useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom"; // 添加导入
import { BookOpen, Headset } from "lucide-react";

const HelpCenter = () => {
  // 创建 ref 对象
  const productRef = useRef(null);
  const featureRef = useRef(null);
  const aboutUsRef = useRef(null);
  const faqRef = useRef(null);

  // 创建一个 scrollToRef 函数
  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      className="min-h-screen flex flex-col"
      style={{ color: "#fff", backgroundColor: "#1D1D1D" }}
    >
      {/* 保留原有的 Header 组件 */}
      <Header
        scrollToRef={scrollToRef}
        productRef={productRef}
        featureRef={featureRef}
        aboutUsRef={aboutUsRef}
        faqRef={faqRef}
      />
      {/* Help Center 内容 */}
      <div className="pt-20 px-6 md:px-10 lg:px-20 py-10 mx-auto max-w-screen-lg flex-grow">
        <h1 className="text-3xl font-semibold mb-6">Help Center</h1>
        <p className="mb-6 text-gray-300">
          Welcome to the LIKKIM Help Center! If you're new to cryptocurrency or
          need help with your cold wallet, you're in the right place. Our cold
          wallet solution provides the most secure way to store and manage your
          digital assets. Below, you will find answers to some of the most
          common questions and troubleshooting tips.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-2">
          1. What is a Cold Wallet?
        </h2>
        <p className="mb-4 text-gray-300">
          A cold wallet is a type of cryptocurrency wallet that is not connected
          to the internet. It provides an extra layer of security by storing
          private keys offline, making it much less vulnerable to hacking or
          other online threats. Cold wallets are ideal for long-term storage of
          digital assets like Bitcoin, Ethereum, and other cryptocurrencies.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-2">
          2. How to Set Up Your Cold Wallet
        </h2>
        <p className="mb-4 text-gray-300">
          Follow these simple steps to get started with your LIKKIM cold wallet:
        </p>
        <ul className="list-disc ml-8 mb-4 text-gray-300">
          <li>Create an account on the LIKKIM platform.</li>
          <li>Download the cold wallet software on your device.</li>
          <li>Connect your cold wallet hardware (if applicable).</li>
          <li>
            Generate a new wallet or import an existing one using your private
            key.
          </li>
          <li>Secure your wallet by backing up your recovery phrase.</li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-2">3. Troubleshooting</h2>
        <p className="mb-4 text-gray-300">
          If you're experiencing issues with your cold wallet, here are some
          common problems and solutions:
        </p>
        <ul className="list-disc ml-8 mb-4 text-gray-300">
          <li>
            <strong>Can't connect to the wallet:</strong> Ensure your device is
            properly connected and the wallet software is up to date.
          </li>
          <li>
            <strong>Forgot my password:</strong> Use your recovery phrase to
            restore your wallet. If you didn't back up your recovery phrase, you
            may not be able to recover your funds.
          </li>
          <li>
            <strong>Transaction not showing:</strong> Make sure your wallet is
            synced with the blockchain. Check for network issues or try
            resyncing the wallet.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-2">4. Contact Support</h2>
        <p className="mb-4 text-gray-300">
          If you need further assistance, please reach out to our support team:
        </p>
        <p className="text-gray-300">
          <strong>Email:</strong> support@LIKKIM.com
          <br />
          <strong>Phone:</strong> +1 (800) 123-4567
        </p>
      </div>
      <div className="mb-[100px] w-[60vw] sm:w-[80vw] mx-auto">
        {/* Hardware Wallet Guide 按钮 */}
        <div className="h-[90px] flex items-center justify-center">
          <Link
            to="/hardware-wallet-guide"
            className="w-full px-6 py-3 bg-neutral-800 hover:bg-neutral-700 text-white font-bold text-lg rounded-lg shadow-xl transition duration-300 ease-in-out text-center flex items-center justify-center gap-3"
          >
            <BookOpen className="text-white text-xl" /> {/* 使用指南图标 */}
            Learn more about LIKKIM Hardware Wallet
          </Link>
        </div>

        {/* Warranty Support 按钮 */}
        <div className="h-[90px] flex items-center justify-center">
          <Link
            to="/warrantysupport"
            className="w-full px-6 py-3 bg-neutral-800 hover:bg-neutral-700 text-white font-bold text-lg rounded-lg shadow-xl transition duration-300 ease-in-out text-center flex items-center justify-center gap-3"
          >
            <Headset className="text-white text-xl" /> {/* 使用支持图标 */}
            Learn more about Warranty Support
          </Link>
        </div>
      </div>

      <Footer
        productRef={productRef}
        featureRef={featureRef}
        aboutUsRef={aboutUsRef}
        faqRef={faqRef}
      />
    </div>
  );
};

export default HelpCenter;
