// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import HelpCenter from "./HelpCenter"; // 导入 HelpCenter 组件
import HardwareWalletGuide from "./HardwareWalletGuide";
import WarrantySupport from "./WarrantySupport"; // 导入新的 WarrantySupport 组件
import Community from "./Community"; // 导入 Community 组件

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/help-center" element={<HelpCenter />} />
        <Route
          path="/hardware-wallet-guide"
          element={<HardwareWalletGuide />}
        />
        <Route path="/warrantysupport" element={<WarrantySupport />} />

        <Route path="/community" element={<Community />} />
      </Routes>
    </Router>
  );
}

export default App;
