import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import globalIcon from "./asset/global.png";

import {
  FaTwitter,
  FaTelegram,
  FaDiscord,
  FaReddit,
  FaInstagram,
  FaFacebook,
  FaLinkedin,
  FaYoutube,
  FaCaretDown,
} from "react-icons/fa";

function Header({ scrollToRef, productRef, featureRef, aboutUsRef, faqRef }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isCommunityOpen, setCommunityOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const supportedLanguages = [
    { code: "en", label: "English" },
    { code: "zh", label: "中文" },
    { code: "zh-TW", label: "繁體中文" },
    { code: "fr", label: "Français" },
    { code: "es", label: "Español" },
    { code: "ar", label: "عربى" },
    { code: "ja", label: "日本語" },
    { code: "ru", label: "Русский" },
    { code: "ko", label: "한국어" },
    { code: "pt", label: "Português" },
    { code: "pt-BR", label: "Português (Brasil)" },
    { code: "it", label: "Italiano" },
    { code: "de", label: "Deutsch" },
    { code: "hi", label: "हिन्दी" },
    { code: "mn", label: "Монгол" },
    { code: "th", label: "ไทย" },
    { code: "uk", label: "Українська" },
    { code: "vi", label: "Tiếng Việt" },
    { code: "id", label: "Bahasa Indonesia" },
    { code: "tl", label: "Tagalog" },
    { code: "bn", label: "বাংলা" },
  ];

  const handleScrollAndNavigate = (ref, sectionId) => {
    navigate(`/#${sectionId}`);
    setTimeout(() => {
      scrollToRef(ref);
    }, 500);
  };

  const handleLanguageChange = (lang) => {
    if (i18n && i18n.changeLanguage) {
      i18n.changeLanguage(lang);
    } else {
      console.error("i18n.changeLanguage is not available");
    }
    setDropdownOpen(false);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const handlePresaleClick = () => {
    setShowTooltip(true);
  };

  const handleCloseModal = () => {
    setShowTooltip(false);
  };

  const currentLanguage = supportedLanguages.find(
    (language) => language.code === i18n.language
  )?.label;

  return (
    <div className="w-full h-[64px] top-[0px] bg-[#1D1D1D] text-[#B6B6B6] px-[30px] py-[20px] font-bold flex fixed z-[10] items-center justify-between">
      <div onClick={handleLogoClick} className="cursor-pointer">
        LIKKIM
      </div>
      <div className="sm:hidden flex w-[80%] justify-center gap-[4%] font-[400] items-center">
        <p
          onClick={() => handleScrollAndNavigate(productRef, "product")}
          className="cursor-pointer"
        >
          {t("Product")}
        </p>
        <p
          onClick={() => handleScrollAndNavigate(featureRef, "feature")}
          className="cursor-pointer"
        >
          {t("Feature")}
        </p>
        <p
          onClick={() => handleScrollAndNavigate(aboutUsRef, "aboutUs")}
          className="cursor-pointer"
        >
          {t("About Us")}
        </p>
        <p
          onClick={() => handleScrollAndNavigate(faqRef, "faq")}
          className="cursor-pointer"
        >
          {t("FAQ")}
        </p>
        <Link to="/privacy-policy" className="cursor-pointer">
          {t("Privacy Policy")}
        </Link>
        <Link to="/help-center" className="cursor-pointer">
          {t("Help Center")}
        </Link>

        <div className="relative">
          <button
            onClick={() => setCommunityOpen((prev) => !prev)}
            className="text-[#B6B6B6] hover:text-white rounded w-full text-left flex items-center gap-2"
          >
            {t("Community")}
            <FaCaretDown
              className={`transform transition-transform duration-300 ${
                isCommunityOpen ? "rotate-180" : ""
              }`}
            />
          </button>

          {isCommunityOpen && (
            <div className="absolute mt-2 bg-[#1D1D1D] border border-[#3C3C3C] rounded shadow-lg w-auto max-w-[250px] z-50">
              <div
                className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-[#3C3C3C]"
                onClick={() =>
                  window.open("https://x.com/LIKKIMwallet", "_blank")
                }
              >
                <FaTwitter className="text-[#B6B6B6]" />
                <span className="text-[#B6B6B6] hover:text-white">Twitter</span>
              </div>
              <div
                className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-[#3C3C3C]"
                onClick={() =>
                  window.open("https://t.me/+q9j351SAY8hlMDJl", "_blank")
                }
              >
                <FaTelegram className="text-[#B6B6B6]" />
                <span className="text-[#B6B6B6] hover:text-white">
                  Telegram
                </span>
              </div>
              <div
                className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-[#3C3C3C]"
                onClick={() =>
                  window.open("https://discord.gg/59hKBX2daq", "_blank")
                }
              >
                <FaDiscord className="text-[#B6B6B6]" />
                <span className="text-[#B6B6B6] hover:text-white">Discord</span>
              </div>
              <div
                className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-[#3C3C3C]"
                onClick={() =>
                  window.open(
                    "https://www.reddit.com/user/Ok_Bass_6829/",
                    "_blank"
                  )
                }
              >
                <FaReddit className="text-[#B6B6B6]" />
                <span className="text-[#B6B6B6] hover:text-white">Reddit</span>
              </div>
              <div
                className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-[#3C3C3C]"
                onClick={() =>
                  window.open("https://www.instagram.com", "_blank")
                }
              >
                <FaInstagram className="text-[#B6B6B6]" />
                <span className="text-[#B6B6B6] hover:text-white">
                  Instagram
                </span>
              </div>
              <div
                className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-[#3C3C3C]"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/profile.php?id=61570753106156",
                    "_blank"
                  )
                }
              >
                <FaFacebook className="text-[#B6B6B6]" />
                <span className="text-[#B6B6B6] hover:text-white">
                  Facebook
                </span>
              </div>
              <div
                className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-[#3C3C3C]"
                onClick={() =>
                  window.open("https://www.linkedin.com", "_blank")
                }
              >
                <FaLinkedin className="text-[#B6B6B6]" />
                <span className="text-[#B6B6B6] hover:text-white">
                  Linkedin
                </span>
              </div>
              <div
                className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-[#3C3C3C]"
                onClick={() =>
                  window.open("https://www.youtube.com/@LukkeyAG", "_blank")
                }
              >
                <FaYoutube className="text-[#B6B6B6]" />
                <span className="text-[#B6B6B6] hover:text-white">YouTube</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* 右侧按钮组 */}
      <div className="flex items-center gap-4">
        {/* 预售按钮 */}
        <button
          onClick={handlePresaleClick}
          className=" bg-gradient-to-r from-[#2563eb] to-[#7c3aed] text-white 
                   px-6 py-2 rounded-lg font-semibold
                   shadow-lg hover:shadow-blue-500/50 
                   transition-all duration-300 ease-in-out
                   border border-transparent hover:border-blue-400
                   hover:scale-105 relative overflow-hidden
                   before:absolute before:inset-0 
                   before:bg-gradient-to-r before:from-transparent before:via-white/20 before:to-transparent
                   before:translate-x-[-200%] hover:before:translate-x-[200%]
                   before:transition-transform before:duration-700"
        >
          {t("Presale")}
        </button>
        {/* Modal for Presale Information */}
        {showTooltip && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-neutral-800 bg-opacity-75 flex items-center justify-center z-50">
              <div className="relative h-[280px] bg-neutral-900 p-6 rounded-lg shadow-xl max-w-lg  w-[90%] z-[70]">
                <button
                  onClick={handleCloseModal}
                  className="absolute top-4 right-5 text-2xl font-bold text-gray-400 hover:text-neutral-500"
                >
                  &times;
                </button>

                <div className="flex flex-col items-center justify-center h-full">
                  <h2 className="text-xl font-bold text-white mb-6">
                    Presale Information
                  </h2>
                  <p className="text-gray-300">
                    The presale will start on April 1, 2025. Stay tuned for more
                    details about our exciting product features and options!
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* 语言切换器 */}
        <div className="relative">
          <div
            onClick={() => setDropdownOpen((prev) => !prev)}
            className="bg-[#3C3C3C] text-white px-4 py-2 rounded whitespace-nowrap flex items-center justify-between cursor-pointer"
          >
            <img
              src={globalIcon}
              alt="global"
              className="w-5 h-5 mr-[6px] sm:mr-[0px]"
            />
            <span className="sm:hidden">{currentLanguage || "English"}</span>
          </div>

          {isDropdownOpen && (
            <div className="z-[1000] absolute right-0 mt-2 w-[160px] bg-[#1D1D1D] border border-[#3C3C3C] rounded shadow-lg">
              {supportedLanguages.map((language) => (
                <div
                  key={language.code}
                  onClick={() => handleLanguageChange(language.code)}
                  className={`px-4 py-2 cursor-pointer hover:bg-[#3C3C3C] ${
                    i18n.language === language.code ? "font-bold" : ""
                  } text-center`}
                >
                  {language.label}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
